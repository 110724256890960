import { apiFetchWrapper } from "../utils/axiosApiWrapper";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_BACK_END_URL;

const getAdminBoard = ({ currentPage, totalPages }) => {
  if (currentPage === totalPages) return;
  return apiFetchWrapper.get(
    API_URL + `compliance/requests?page=${currentPage || 1}`,
    {
      headers: authHeader(),
    }
  );
};

const getPersonaEntities = (url) => {
  const apiUrl = url ? url : API_URL + `personas/entities/`;

  return apiFetchWrapper.get(apiUrl, {
    headers: authHeader(),
  });
};
const deletePersonaEntities = (url) => {
  const apiUrl = url ? url : API_URL + `personas/entities/`;

  return apiFetchWrapper.delete(apiUrl, {
    headers: authHeader(),
  });
};
const getPersonaEntityAnswer = (uuid) => {
  const apiUrl =
    API_URL +
    `compliance/persona-compliance-details/?compliance_type=MSW-A1,A1,COC&persona=${uuid}`;

  return apiFetchWrapper.get(apiUrl, {
    headers: authHeader(),
  });
};
const addPersonaEntities = (body) => {
  return apiFetchWrapper.post(API_URL + `personas/entities/`, body, {
    headers: authHeader(),
  });
};
const updatePersonaEntities = ({ name, uuid }) => {
  return apiFetchWrapper.put(
    API_URL + `personas/entities/${uuid}/`,
    {
      name,
    },
    {
      headers: authHeader(),
    }
  );
};
const getCountryList = () => {
  return apiFetchWrapper.get(API_URL + `locations/countries/`, {
    headers: authHeader(),
  });
};
const serachAddress = (query, signal) => {
  return apiFetchWrapper.get(
    API_URL + `personas/employer-search?${query}`,
    true,
    signal
  );
};
const dashboardService = {
  getAdminBoard,
  getPersonaEntities,
  deletePersonaEntities,
  getCountryList,
  addPersonaEntities,
  updatePersonaEntities,
  serachAddress,
  getPersonaEntityAnswer,
};

export default dashboardService;
