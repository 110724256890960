import React from "react";
import styles from "../../index.module.css";

const Card = ({ data, title, color, backgroundColor }) => {
  return (
    <div className={styles.a1Card}>
      <h2>{data}</h2>
      <p>{title}</p>
      <hr />
    </div>
  );
};

export default Card;
