import validator from "validator";
import { stringToBase64 } from "./fileConversions";

const getTenantsInfo = () => {
  const tenantsInfo = localStorage.getItem("tenantsInfo") || "";
  return JSON.parse(tenantsInfo);
};

export const getRedirectUrl = () => {
  try {
    const tenantsInfo = getTenantsInfo();

    if (tenantsInfo) {
      const { login_url, client_id } = tenantsInfo;
      return `${login_url}/login?client_id=${client_id}&response_type=code&scope=email+openid+phone&redirect_uri=${encodeURIComponent(
        `${window.location.origin}`
      )}`;
    }
  } catch (error) {
    console.error(error);
  }
};

const redirectToLogin = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("AUTH0_TOKEN_RESPONSE");
  localStorage.removeItem("user");
  localStorage.removeItem("tenantsInfo");
  window.location.href = "/login";
};

const isValidEmail = (value) => {
  if (!value) return true;
  if (typeof value !== "string") return false;
  return validator.isEmail(value);
};

const fetchToken = async (details) => {
  const tenantsInfo = getTenantsInfo();
  if (tenantsInfo) {
    const { client_id, login_url, client_secret } = tenantsInfo;

    const formBody = Object.entries(details).map(
      ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
    );

    const autorizationString = `${client_id}:${client_secret}`;
    const base64String = stringToBase64(autorizationString);

    const body = formBody.join("&");
    const res = await fetch(`${login_url}/oauth2/token`, {
      method: "POST",
      headers: {
        Authorization: `Basic ${base64String}`,
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: body,
    }).then((r) => r.json());
    if (res?.access_token) {


      localStorage.setItem("AUTH0_TOKEN_RESPONSE", JSON.stringify(res));
      localStorage.setItem("token", res.access_token);
      localStorage.setItem("user", JSON.stringify(res));
      return res;
    }
  }
  throw new Error("Failed to fetch token");
};

const getDataFromToken = async (code) => {
  try {
    localStorage.removeItem("AUTH0_TOKEN_RESPONSE");
    localStorage.removeItem("token");

    const details = {
      code,
      redirect_uri: `${window.location.origin}`,
      client_id: getTenantsInfo().client_id,
      grant_type: "authorization_code",
    };

    return await fetchToken(details);
  } catch (error) {
    console.log("auth0 code send error", error);
  }
};

const getDataFromRefreshToken = async () => {
  try {
    const storageAccessToken =
      localStorage.getItem("AUTH0_TOKEN_RESPONSE") || "";

    const tokenData = JSON.parse(storageAccessToken);
    const refresh_token = tokenData.refresh_token.toString();

    const details = {
      refresh_token,
      client_id: getTenantsInfo().client_id,
      grant_type: "refresh_token",
    };

    const res = await fetchToken(details);
    if (res) {
      res.refresh_token = tokenData.refresh_token;
      localStorage.setItem("AUTH0_TOKEN_RESPONSE", JSON.stringify(res));
      localStorage.setItem("user", JSON.stringify(res));
      return res;
    } else {
      redirectToLogin()};
  } catch (error) {
    console.log("auth0 code send error", error);
    redirectToLogin();
  }
};
const logoutUser = async () => {
  try {
    localStorage.removeItem("AUTH0_TOKEN_RESPONSE");
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    const tenantsInfo = getTenantsInfo();
    if (tenantsInfo) {
      const { client_id, login_url } = tenantsInfo;
      const url = `${login_url}/logout?response_type=code&client_id=${client_id}&redirect_uri=${encodeURIComponent(
        `${window.location.origin}`
      )}&state=example-state-value&
        nonce=example-nonce-value&
        scope=openid+profile+aws.cognito.signin.user.admin`;
      window.open(url, "_self");
    }
  } catch (error) {
    console.log("auth0 code send error", error);
  }
};

const checkIsNumeric = (value) => {
  const isNumeric = /^[+-]?\d+(\.\d+)?$/.test(value);
  if (isNumeric) {
    return true;
  } else {
    return false;
  }
};

export {
  checkIsNumeric,
  isValidEmail,
  getDataFromToken,
  getDataFromRefreshToken,
  logoutUser,
  redirectToLogin,
};
