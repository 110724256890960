import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import questionnairService from "../services/questionnaire.service";
import { setMessage } from "./message";
import { flattenData } from "../common/helper/flattedData";
import {
  extractEmployerQuestions,
  filterEMployerQuestionsHandler,
} from "../common/helper/commonFunctions";

export const getFormFieldData = createAsyncThunk(
  "getFormField",
  async (payload, thunkAPI) => {
    try {
      const payloadData = {
        homeCountry: payload?.homeCountry,
        type: payload?.type,
        compliance_type: payload.entity.compliance_type,
      };
      const data = await questionnairService.getFormField(payloadData);
      const employerData = await questionnairService.getFormPersonaDetails({
        country_code: payload?.homeCountry,
      });

      let personaA1Detail = {};
      let personaMSWDetail = {};
      let personaCOCDetail = {};

      if (employerData?.data && !employerData.error) {
        personaA1Detail = employerData?.data.find(
          (_item) => _item.compliance_type === "A1"
        )?.fields;
        personaMSWDetail = employerData?.data.find(
          (_item) => _item.compliance_type === "MSW-A1"
        )?.fields;
        personaCOCDetail = employerData?.data.find(
          (_item) => _item.compliance_type === "COC"
        )?.fields;
      }

      let questionnaireA1Fields = [];
      let questionnaireMSWFields = [];
      let questionnaireCOCFields = [];

      // get A1 fields
      const allA1Fields = data?.data?.find(
        (_item) => _item.compliance_type === "A1"
      );
      const allMSWFields = data?.data?.find(
        (_item) => _item.compliance_type === "MSW-A1"
      );

      const allCOCFields = data?.data?.find(
        (_item) => _item.compliance_type === "COC"
      );

      allA1Fields?.fields.filter((item) => {
        if (item.persona === "Employer" || item.persona === "Employer only") {
          if (item.conditional_fields?.length) {
            const resultingData = extractEmployerQuestions(item);
            if (resultingData.conditional_fields?.length) {
              questionnaireA1Fields.push(item);
            }
          } else {
            questionnaireA1Fields.push(item);
          }
        }
        if (item.persona === "Assumption" && item.conditional_fields?.length) {
          const resultingData = extractEmployerQuestions(item);
          if (resultingData.conditional_fields?.length) {
            questionnaireA1Fields.push(item);
          }
        }
      });

      const filterdEmployerA1Questionaire = filterEMployerQuestionsHandler(
        questionnaireA1Fields,
        personaA1Detail
      );

      allMSWFields?.fields.filter((item) => {
        if (item.persona === "Employer" || item.persona === "Employer only") {
          if (item.conditional_fields?.length) {
            const resultingData = extractEmployerQuestions(item);
            if (resultingData.conditional_fields?.length) {
              questionnaireMSWFields.push(item);
            }
          } else {
            questionnaireMSWFields.push(item);
          }
        }
        if (item.persona === "Assumption" && item.conditional_fields?.length) {
          const resultingData = extractEmployerQuestions(item);
          if (resultingData.conditional_fields?.length) {
            questionnaireMSWFields.push(item);
          }
        }
      });

      const filterdEmployerMSWQuestionaire = filterEMployerQuestionsHandler(
        questionnaireMSWFields,
        personaMSWDetail
      );

      allCOCFields?.fields.filter((item) => {
        if (item.persona === "Employer" || item.persona === "Employer only") {
          if (item.conditional_fields?.length) {
            const resultingData = extractEmployerQuestions(item);
            if (resultingData.conditional_fields?.length) {
              questionnaireCOCFields.push(item);
            }
          } else {
            questionnaireCOCFields.push(item);
          }
        }
        if (item.persona === "Assumption" && item.conditional_fields?.length) {
          const resultingData = extractEmployerQuestions(item);
          if (resultingData.conditional_fields?.length) {
            questionnaireCOCFields.push(item);
          }
        }
      });

      const filterdEmployerCOCQuestionaire = filterEMployerQuestionsHandler(
        questionnaireCOCFields,
        personaCOCDetail
      );

      // remove employer common questions
      let uniqueNames = new Map();
      let filterdCommonQuestions = [...filterdEmployerA1Questionaire];

      filterdEmployerA1Questionaire.forEach((a1question) => {
        const { employer_common_name } = a1question;
        if (!uniqueNames.has(employer_common_name)) {
          uniqueNames.set(employer_common_name, true);
        }
      });

      filterdEmployerMSWQuestionaire.forEach((obj) => {
        const { employer_common_name } = obj;
        if (employer_common_name) {
          if (!uniqueNames.has(employer_common_name)) {
            filterdCommonQuestions.push(obj);
          }
        } else {
          filterdCommonQuestions.push(obj);
        }
      });

      filterdEmployerCOCQuestionaire.forEach((obj) => {
        const { employer_common_name } = obj;
        if (employer_common_name) {
          if (!uniqueNames.has(employer_common_name)) {
            filterdCommonQuestions.push(obj);
          }
        } else {
          filterdCommonQuestions.push(obj);
        }
      });

      const flattenFields = flattenData(filterdCommonQuestions);
      const flatenFilterdEmployerA1Questionaire = flattenData(
        filterdEmployerA1Questionaire
      );
      const flatenFilterdEmployerMSWQuestionaire = flattenData(
        filterdEmployerMSWQuestionaire
      );
      const flatenFilterdEmployerCOCQuestionaire = flattenData(
        filterdEmployerCOCQuestionaire
      );

      if (data?.data)
        return {
          fields: filterdCommonQuestions,
          flatedFields: flattenFields,
          itemData: payload.entity,
          personaDetail: { ...personaA1Detail, ...personaMSWDetail },
          filterdEmployerA1Questionaire,
          filterdEmployerMSWQuestionaire,
          flatenFilterdEmployerA1Questionaire,
          flatenFilterdEmployerMSWQuestionaire,
          flatenFilterdEmployerCOCQuestionaire,
        };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const submitFormFieldData = createAsyncThunk(
  "submitFormField",
  async (payload, thunkAPI) => {
    try {
      const data = await questionnairService.submitFormFields(payload);
      if (data?.data)
        return {
          data: data?.data,
        };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const getEntityComplianceDetails = createAsyncThunk(
  "getEntityComplianceDetails",
  async (payload, thunkAPI) => {
    try {
      const data = await questionnairService.getEntityComplianceDetails(
        payload
      );
      if (data?.data)
        return {
          data: data?.data,
        };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

const initialState = {
  loading: false,
  complianceLoading: false,
  questionnaire: [],
  flatedFields: [],
  selectedCountries: {},
  step: 1,
  termsChecked: false,
  homeCountry: "",
  hostCountry: "",
  typeA1: "MSW-A1",
  employer: undefined,
  allEmployers: [],
  employers: [],
  homeCountryOptions: [],
  employerDetail: {},
  storedValues: {},
  reviewModal: false,
  termsModal: false,
  showError: false,
  currentSelectedGroup: 1,
  currentSelectedGroupName: "",
  complianceUUID: "",
  completionTime: "",
  researchEmail: "",
  confirmedChecked: false,
  cancelModal: false,
  companyHouseDetail: {},
  nextStepDisable: true,
  countriesInstruction: [],
  signatures: {},
  isAttornaeyChecked: false,
  editableQuestionaireState: "",
  filterdEmployerA1Questionaire: [],
  filterdEmployerMSWQuestionaire: [],
  flatenFilterdEmployerCOCQuestionaire: [],
};
const questionnaireSlice = createSlice({
  name: "questionnaire",
  initialState,
  extraReducers: {
    [submitFormFieldData.pending]: (state, action) => {
      state.loading = true;
    },
    [submitFormFieldData.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [submitFormFieldData.rejected]: (state, action) => {
      state.loading = false;
    },
    [getFormFieldData.pending]: (state, action) => {
      state.loading = true;
      state.questionnaire = [];
      state.flatedFields = [];
      state.selectedCountries = {};
      state.step = 1;
      state.homeCountry = "";
      state.hostCountry = "";
      state.employerDetail = {};
      state.currentSelectedGroup = 1;
      state.currentSelectedGroupName = "";
      state.signatures = {};
      state.filterdEmployerA1Questionaire = [];
      state.filterdEmployerMSWQuestionaire = [];
    },
    [getFormFieldData.fulfilled]: (state, action) => {
      state.questionnaire = action.payload.fields;
      state.flatedFields = action.payload.flatedFields;
      state.employerDetail = action.payload.personaDetail;
      state.filterdEmployerA1Questionaire =
        action.payload.filterdEmployerA1Questionaire;
      state.filterdEmployerMSWQuestionaire =
        action.payload.filterdEmployerMSWQuestionaire;
      state.flatenFilterdEmployerA1Questionaire =
        action.payload.flatenFilterdEmployerA1Questionaire;
      state.flatenFilterdEmployerMSWQuestionaire =
        action.payload.flatenFilterdEmployerMSWQuestionaire;
      state.flatenFilterdEmployerCOCQuestionaire =
        action.payload.flatenFilterdEmployerCOCQuestionaire;
      state.loading = false;
    },
    [getFormFieldData.rejected]: (state, action) => {
      state.loading = false;
    },
    [getEntityComplianceDetails.pending]: (state, action) => {
      state.complianceLoading = true;
    },
    [getEntityComplianceDetails.fulfilled]: (state, action) => {
      state.complianceLoading = false;
    },
    [getEntityComplianceDetails.rejected]: (state, action) => {
      state.complianceLoading = false;
    },
  },
  reducers: {
    setCurrentPage: (state, action) => {
      return { ...state, currentPage: action.payload.currentPage };
    },
    setModalHandler: (state, action) => {
      return { ...state, isEntityModal: action.payload };
    },
    updateSelectedGroup: (state, { payload }) => {
      state.currentSelectedGroup = payload.group;
    },
    updateShowError: (state, { payload }) => {
      state.showError = payload.flag;
    },
    updateValues: (state, { payload }) => {
      state.storedValues = payload.values;
    },
    updateStep: (state, { payload }) => {
      state.step = payload.step;
    },
    changeDisableNextStepStatus: (state, action) => {
      state.nextStepDisable = action.payload;
    },
    clearData: () => {
      return { data: [] };
    },
    updateSignatures: (state, { payload }) => {
      const allSignatures = state.signatures;
      const key = Object.keys(payload);
      const value = Object.values(payload);
      allSignatures[key[0].toString()] = value[0].toString();
      state.signatures = allSignatures;
    },
    startQuestionareLoading: (state, { payload }) => {
      state.loading = true;
      state.storedValues = {};
    },
  },
});

export const {
  updateSelectedGroup,
  updateStep,
  updateShowError,
  updateValues,
  changeDisableNextStepStatus,
  updateSignatures,
  startQuestionareLoading,
} = questionnaireSlice.actions;
const { reducer } = questionnaireSlice;

export default reducer;
