import React, { useEffect, useMemo, useState } from "react";
import filterLogo from "../../../assets/images/filter.svg";
import { useDispatch, useSelector } from "react-redux";
import { FormDate, SelectBox } from "../../common";
import useCountryList from "../../../assets/shared/hooks/useCountryList";
import {
  getApplicationsStats,
  updateFilter,
} from "../../../slices/filledApplications";
import {
  getCountriesList,
  getPersonaEntities,
} from "../../../slices/dashboard";
import { apiFetchWrapper } from "../../../utils/axiosApiWrapper";
import getQueryParamsFromObj from "../../../utils/getParamsFromObj";
import { useMapFilter } from "./filterContext";

export const MapFilter = () => {
  const themeColor = process.env.REACT_APP_LIGHT_PRIMARY;
  const { getCountryCode } = useCountryList();
  const dispatch = useDispatch();
  const { filterData, setFilterData } = useMapFilter();

  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  const API_URL = process.env.REACT_APP_BACK_END_URL;
  const { countriesList } = useSelector((state) => state.dashboard);
  const [allCompliaceType, setAllCompliaceType] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const [arrangedCountryList, setArrangedCountryList] = useState([]);
  const [personaEntities, setPersonaEntities] = useState([]);

  const allObj = useMemo(() => {
    return [{ label: "All", value: "" }];
  }, []);

  const getComplainceTypes = async () => {
    const url = API_URL + `compliance/types`;
    const res = await apiFetchWrapper.get(url, true);
    if (res.error) setAllCompliaceType([]);
    const newArray = res?.data?.results?.map((item) => ({
      label: item.description,
      value: item.type,
    }));
    setAllCompliaceType([...allObj, ...newArray]);
  };
  const getPersonaEntitiesHandler = (url) => {
    dispatch(getPersonaEntities(url))
      .unwrap()
      .then(() => {})
      .catch(() => {});
  };
  const getStatusList = async () => {
    const url = API_URL + `compliance/compliance-requests/status-options/`;
    const res = await apiFetchWrapper.get(url, true);
    if (res.error) setAllCompliaceType([]);
    const newArray = res?.data?.map((item) => ({
      label: item.display_name,
      value: item.value,
    }));
    setStatusList([...allObj, ...newArray]);
  };
  const getPersonaList = async () => {
    const url = API_URL + `personas/entities/`;
    const res = await apiFetchWrapper.get(url, true);
    if (res.error) setAllCompliaceType([]);
    const newArray = res?.data?.results?.map((item) => ({
      label: item.name,
      value: item.uuid,
    }));
    setPersonaEntities([...allObj, ...newArray]);
  };
  const getCountryListHandler = () => {
    dispatch(getCountriesList())
      .unwrap()
      .then(() => {})
      .catch(() => {});
  };
  useEffect(() => {
    getComplainceTypes();
    getPersonaEntitiesHandler();
    getStatusList();
    getCountryListHandler();
    getPersonaList();
  }, []);

  const getApplicationsStaticsticsHandler = React.useCallback(
    (query) => {
      dispatch(
        updateFilter({
          reset: true,
        })
      );
      dispatch(getApplicationsStats(query))
        .unwrap()
        .then(() => {
          setIsVisible(false);
        })
        .catch(() => {
          setIsVisible(false);
        });
    },
    [dispatch]
  );

  useEffect(() => {
    if (countriesList && countriesList.length) {
      const formattedList = countriesList.map((_item) => ({
        label: _item.name,
        // value: _item.uuid,
        value: getCountryCode(_item.name),
      }));

      const newList = [...allObj, ...formattedList];
      setArrangedCountryList(newList);
    }
  }, [countriesList, allObj, getCountryCode]);

  const searchFilterHandler = () => {
    getApplicationsStaticsticsHandler(getQueryParamsFromObj(filterData));
  };

  const resetFilterHandler = () => {
    setFilterData({});
    getApplicationsStaticsticsHandler();
    setIsVisible(false);
  };

  useEffect(() => {
    if (countriesList && countriesList.length) {
      const formattedList = countriesList.map((_item) => ({
        label: _item.name,
        value: getCountryCode(_item.name),
      }));

      const newList = [...allObj, ...formattedList];
      setArrangedCountryList(newList);
    }
  }, [countriesList, getCountryCode, allObj]);

  return (
    <main
      className={`bg-white rounded shadow pt-2 px-2 position-absolute filter_main_div ${
        isVisible ? "pb-5 bg-dark " : "pb-1"
      }`}
      style={{
        maxHeight: "47rem",
        zIndex: "99",
      }}
    >
      <div
        style={{
          backgroundColor: themeColor,
          color: "white",
          borderRadius: "12px",
          cursor: "pointer",
        }}
        className="d-flex justify-content-between w-100 p-2 px-3"
        onClick={() => toggleVisibility()}
      >
        <span className=" d-md-inline">Advanced filter</span>{" "}
        <img src={filterLogo} alt="fb" />
      </div>
      <div
        style={{ height: "400px", overflow: "auto" }}
        className={`filter_content ${
          isVisible ? "filter_expanded" : "filter_collapsed"
        }`}
      >
        <div className="mt-3 px-2">
          <span style={{ fontWeight: "500" }}> By compliance type</span>
          <SelectBox
            label={""}
            isRequired={false}
            showIcons={false}
            value={filterData?.compliance_type}
            onChange={(compliance_type) => {
              setFilterData((prev) => ({
                ...prev,
                compliance_type: compliance_type?.value,
              }));
            }}
            options={allCompliaceType}
          />
        </div>
        <div className="mt-3 px-2">
          <span style={{ fontWeight: "500" }}> By employing entity</span>
          <SelectBox
            label={""}
            isRequired={false}
            showIcons={false}
            value={filterData?.persona}
            onChange={(entity) => {
              setFilterData((prev) => ({
                ...prev,
                persona: entity?.value,
              }));
            }}
            options={personaEntities}
          />
        </div>
        <div className="mt-3 px-2">
          <span style={{ fontWeight: "500" }}>By status</span>
          <SelectBox
            label={""}
            isRequired={false}
            showIcons={false}
            value={filterData?.status}
            onChange={(entity) => {
              setFilterData((prev) => ({
                ...prev,
                status: entity?.value,
              }));
            }}
            options={statusList}
          />
        </div>
        <div className="mt-3 px-2">
          <span style={{ fontWeight: "500" }}>By home country</span>
          <SelectBox
            label={""}
            isRequired={false}
            showIcons={false}
            value={filterData?.home_country}
            onChange={(entity) => {
              setFilterData((prev) => ({
                ...prev,
                home_country: entity?.value,
              }));
            }}
            options={arrangedCountryList}
          />
        </div>
        <div className="mt-3 px-2">
          <span style={{ fontWeight: "500" }}>By destination country</span>
          <SelectBox
            label={""}
            isRequired={false}
            showIcons={false}
            value={filterData?.host_country}
            onChange={(entity) => {
              setFilterData((prev) => ({
                ...prev,
                host_country: entity?.value,
              }));
            }}
            options={arrangedCountryList}
          />
        </div>
        <div className="mt-3 px-2">
          <span style={{ fontWeight: "500" }}>Date from</span>
          <FormDate
            value={filterData?.created_after}
            description=""
            isRequired={false}
            onChange={(name, value) => {
              setFilterData((prev) => ({
                ...prev,
                created_after: value,
              }));
            }}
          />
        </div>
        <div className="mt-3 px-2">
          <span style={{ fontWeight: "500" }}>Date to</span>
          <FormDate
            value={filterData?.created_before}
            description=""
            isRequired={false}
            onChange={(name, value) => {
              setFilterData((prev) => ({
                ...prev,
                created_before: value,
              }));
            }}
          />
        </div>
        <div
          style={{ gap: "10px" }}
          className="d-flex justify-content-center mt-3"
        >
          <button
            style={{
              color: themeColor,
              borderColor: themeColor,
              backgroundColor: "white",
              width: "100%",
            }}
            type="button"
            className="btn  px-4  my-2"
            onClick={() => {
              resetFilterHandler();
            }}
          >
            Reset
          </button>
          <button
            style={{
              backgroundColor: themeColor,
              borderColor: themeColor,
              width: "100%",
            }}
            type="button"
            className="btn btn-danger  px-4  my-2"
            onClick={() => {
              searchFilterHandler();
            }}
          >
            Search
          </button>
        </div>
      </div>
    </main>
  );
};
