// import filter from 'lodash/filter'

import { useMemo } from "react";
import { FormFieldWrapper } from "./FormFieldGenerator";
import { map } from "lodash";

export const FormFieldGenerator = (props) => {
  const {
    fields,
    selectedGroupName,
    values,
    errors,
    countryObject,
    countryObjectValues,
    setFieldValue,
    updateFieldVisibleRef,
    employerDetail,
    scrollRefs,
    prefilledNames,
    manualFilledFields,
  } = props;

  const filteredFields = useMemo(() => {
    const filtered = [];

    fields?.forEach((field) => {
      if (field?.group?.trim() === selectedGroupName?.trim()) {
        filtered.push(field);
      }
    });
    return filtered;
  }, [fields, selectedGroupName]);

  return (
    <>
      {map(filteredFields, (field, index) => (
        <FormFieldWrapper
          scrollRefs={scrollRefs}
          key={`field-${index}-${field.name}`}
          index={index}
          field={field}
          values={values}
          errors={errors}
          countryObject={countryObject}
          countryObjectValues={countryObjectValues}
          employerDetail={employerDetail}
          setFieldValue={setFieldValue}
          selectedGroupName={selectedGroupName}
          updateFieldVisibleRef={updateFieldVisibleRef}
          prefilledNames={prefilledNames}
          manualFilledFields={manualFilledFields}
        />
      ))}
    </>
  );
};
