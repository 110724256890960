import React, { useEffect, useState } from "react";
import ReactMapGL, { Marker, Popup } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { getCoordinates } from "./geocoder"; // Import the utility function
import { useDispatch, useSelector } from "react-redux";
import useCountryList from "../../../../assets/shared/hooks/useCountryList";
import { updateFilter } from "../../../../slices/filledApplications";
import { TableLaoding } from "../../../common/Loading/TableLoading";
import {
  EUCountriesLocations,
  EUCountriesLocations2,
} from "../../../../common/constants";
import Tooltip from "@mui/material/Tooltip";

// Mapbox access token
const MAPBOX_TOKEN =
  "pk.eyJ1IjoiZWdnenljdXRlMDA3IiwiYSI6ImNseW42czEyYTAyeDIybHFydGc1d3IybHQifQ.QbUqwab8xVnATPgIfNgM5A";

const MapComponent = ({ homeCountrySelected, hostCountrySelected }) => {
  const { homeCountryList, hostCountryList, mostCommonRoutes } = useSelector(
    (state) => state.filledApplications
  );
  const { getCountryName, getCountryCode } = useCountryList();

  const [selectedMarker, setSelectedMarker] = useState(null);
  const [loading, setLoading] = useState(false);
  const [markers, setMarkers] = useState([]);
  const [mousePosition, setMousePosition] = useState(null);
  const [tooltipContent, setTooltipContent] = useState("");
  const dispatch = useDispatch();

  const fetchCoordinates = async (countriesData) => {
    try {
      const fetchedLocations = await Promise.all(
        countriesData.map(async (data) => {
          const countryName = data.country;
          const countryCode = getCountryCode(countryName);

          const countEntry = mostCommonRoutes.find(
            (entry) => entry.country === countryCode
          );
          const count = countEntry ? countEntry.count : 0;
          let locationStorage = {};
          if (data.homeCountry) locationStorage = EUCountriesLocations;
          else locationStorage = EUCountriesLocations2;
          if (locationStorage[countryName]) {
            return {
              latitude: Number(locationStorage[countryName].latitude),
              longitude: Number(locationStorage[countryName].longitude),
              color: data.color,
              homeCountry: data.homeCountry,
              hostCountry: data.hostCountry,
              country: countryName,
              count,
            };
          } else {
            const coordinates = await getCoordinates(countryCode);
            return (
              coordinates && {
                latitude: Number(coordinates.latitude),
                longitude: Number(coordinates.longitude),
                color: data.color,
                homeCountry: data.homeCountry,
                hostCountry: data.hostCountry,
                country: countryName,
                count,
              }
            );
          }
        })
      );
      const result = fetchedLocations.filter((loc) => loc !== null);
      setMarkers(result);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleMouseMove = (event, count) => {
    setTooltipContent(`A1s filed: ${count.toLocaleString()}`);
    setMousePosition({ x: event.clientX, y: event.clientY });
  };

  const handleMouseLeave = () => {
    setTooltipContent("");
    setMousePosition(null);
  };
  const calculateMarkerSize = (count, minCount, maxCount) => {
    const minSize = 10;
    const maxSize = 31;

    if (maxCount === minCount) {
      return minSize;
    }

    const normalizedCount = (count - minCount) / (maxCount - minCount);
    return minSize + normalizedCount * (maxSize - minSize);
  };

  const minCount = Math.min(...markers.map((marker) => marker.count));
  const maxCount = Math.max(...markers.map((marker) => marker.count));

  useEffect(() => {
    setLoading(true);
    const homeCountryDictionary = {};
    const hostCountryDictionary = {};

    homeCountryList.forEach((route, index) => {
      if (!homeCountryDictionary[route]) {
        homeCountryDictionary[route] = {
          country: getCountryName(route) || route,
          color: process.env.REACT_APP_LIGHT_PRIMARY,
          homeCountry: true,
        };
      }
    });

    hostCountryList.forEach((route, index) => {
      if (!hostCountryDictionary[route]) {
        hostCountryDictionary[route] = {
          country: getCountryName(route) || route,
          color: "#1F3543",
          hostCountry: true,
        };
      }
    });
    let countriesList = [];
    if (
      (homeCountrySelected && hostCountrySelected) ||
      (!homeCountrySelected && !hostCountrySelected)
    )
      countriesList = [
        ...Object.values(hostCountryDictionary),
        ...Object.values(homeCountryDictionary),
      ];
    else if (homeCountrySelected)
      countriesList = [...Object.values(homeCountryDictionary)];
    else if (hostCountrySelected)
      countriesList = [...Object.values(hostCountryDictionary)];

    fetchCoordinates([...countriesList]);
  }, [
    getCountryName,
    homeCountryList,
    hostCountryList,
    homeCountrySelected,
    hostCountrySelected,
  ]);

  const [viewport, setViewport] = useState({
    latitude: 52.215933,
    longitude: 19.134422,
    width: "100%",
    height: "100%",
  });

  const filterHandler = (selectedMarker) => {
    dispatch(
      updateFilter(
        selectedMarker.homeCountry
          ? {
              home_country: selectedMarker.country,
              value: getCountryCode(selectedMarker.country),
            }
          : {
              host_country: selectedMarker.country,
              value: getCountryCode(selectedMarker.country),
            }
      )
    );
  };

  if (!MAPBOX_TOKEN) {
    return <div>Please provide a valid Mapbox token</div>;
  }

  return (
    <ReactMapGL
      {...viewport}
      minZoom={1.4}
      width={"100%"}
      style={{ maxHeight: "24rem", minHeight: "24rem" }}
      height={"100%"}
      mapboxApiAccessToken={MAPBOX_TOKEN}
      onViewportChange={(nextViewport) => setViewport(nextViewport)}
      mapStyle="mapbox://styles/mapbox/light-v10"
    >
      {loading ? (
        <div className=" top-0 start-0 w-100 h-100 d-flex justify-content-center align-items-center bg-dark bg-opacity-50">
          <TableLaoding />
        </div>
      ) : null}

      {markers.map((marker, index) => (
        <Marker
          key={index}
          latitude={marker.latitude}
          longitude={marker.longitude}
        >
          <Tooltip
            title={tooltipContent}
            placement="top"
            arrow
            open={Boolean(mousePosition && tooltipContent)}
            PopperProps={{
              anchorEl: {
                getBoundingClientRect: () => {
                  const { x = 0, y = 0 } = mousePosition || {};
                  return {
                    top: y - 12,
                    left: x,
                    right: x,
                    bottom: y,
                    width: 0,
                    height: 0,
                    toJSON: () => {},
                  };
                },
              },
            }}
          >
            <div
              onMouseMove={(event) => handleMouseMove(event, marker.count)}
              onMouseLeave={handleMouseLeave}
              style={{
                width: `${calculateMarkerSize(
                  marker.count,
                  minCount,
                  maxCount
                )}px`,
                height: `${calculateMarkerSize(
                  marker.count,
                  minCount,
                  maxCount
                )}px`,
                backgroundColor: marker.color,
                borderRadius: "50%",
                cursor: "pointer",
                transition: "all .3s",
              }}
              onClick={() => filterHandler(marker)}
            />
          </Tooltip>
        </Marker>
      ))}
      {selectedMarker && (
        <Popup
          latitude={selectedMarker.latitude}
          longitude={selectedMarker.longitude}
          onClose={() => setSelectedMarker(null)}
          closeOnClick={false}
          anchor="top"
        >
          <div>{selectedMarker.info}</div>
        </Popup>
      )}
    </ReactMapGL>
  );
};

export default MapComponent;
