import React from "react";

export const Sterik = ({ restStyle = "" }) => {
  const Style = {
    fontSize:"20px",
    marginLeft: "3px",
    marginRight: "3px",
    marginTop: "-5px",
    color: process.env.REACT_APP_LIGHT_PRIMARY,
  };
  return (
    <span className={`${restStyle}`} style={Style}>
      *
    </span>
  );
};
