import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Steps from "./Steps/steps";
import { useDispatch, useSelector } from "react-redux";
import { FieldType, companyHouseFieldsMap } from "../../common/constants";
import useCountryList from "../../assets/shared/hooks/useCountryList";
import { useFormik } from "formik";
import { generateA1sValidationSchema } from "../../common/helper/generateA1sValidationSchema";
import { getDateDisplayFormat } from "../../common/helper/formateDate";
import { GroupContainer } from "./GroupContainer/index.js";
import { NavigationWrapper } from "./NavigateBtn/index.js";
import { useLocation } from "react-router-dom";
import {
  getFormFieldData,
  submitFormFieldData,
  updateValues,
} from "../../slices/questionnaire.js";
import dashboardService from "../../services/dashboard.service.js";
import { Loader } from "../../components/common/Loading/loading.js";

import { TitleText } from "../TitleText/index.js";

const Questionnaire = () => {
  const {
    questionnaire,
    flatedFields,
    employerDetail,
    storedValues,
    researchEmail,
    homeCountry,
    typeA1,
    hostCountry,
    currentSelectedGroup,
    loading,
    complianceLoading,
    filterdEmployerMSWQuestionaire,
    filterdEmployerA1Questionaire,
    flatenFilterdEmployerCOCQuestionaire,
  } = useSelector((state) => state.questionnaire);
  const { countryObject } = useCountryList();
  const [maxVisitedGroup, setMaxVisitedGroup] = useState(1);

  const [currentPageFieldNames, setCurrentPageFieldNames] = useState([]);
  const [fieldVisible, setFieldVisible] = useState();
  const [errorPerPage, setErrorPerPage] = useState({});
  const [apiA1FieldValues, setApiA1FieldValues] = useState([]);
  const [apiMSWFieldValues, setApiMSWFieldValues] = useState([]);
  const [apiFieldAnswers, setApiFieldAnswers] = useState([]);
  const [apiCOCFieldValues, setApiCOCFieldValues] = useState([]);

  const fieldResetFlagRef = useRef(true);

  const myCompanyKey = useMemo(() => {
    const myCountryKey = `${homeCountry}${typeA1}`;
    if (companyHouseFieldsMap?.[myCountryKey]) {
      const myKeys = Object.keys(companyHouseFieldsMap[myCountryKey]);
      return myKeys;
    }
    return [""];
  }, [homeCountry, typeA1]);
  const { personaEntities, loading: personaEntitiesLoading } = useSelector(
    (state) => state.dashboard
  );
  const dispatch = useDispatch();
  const location = useLocation();

  const [item, setItem] = useState({});
  const [address, setAddress] = useState({});
  const [isAddressUpdated, setIsAddressUpdated] = useState(false);
  const [prefilledNames, setPrefilledNames] = useState({});
  const [manualFilledFields, setManualFilledFields] = useState({});
  const itemEntity = location.state?.item;

  // useEffect(() => {
  //   dispatch(getPersonaEntities());
  // }, [dispatch]);

  const updatePersona = useCallback(async () => {
    const fromModal = location.state?.fromModal;
    if (!fromModal) {
      setItem(location.state?.item);
    }
    const savedAddress = location.state?.address;
    setAddress(savedAddress || {});
    if (fromModal) {
      const item = location.state?.item;

      const persona = personaEntities?.find((entity) => {
        return (
          entity.persona === item.uuid && entity.compliance_type === "MSW-A1"
        );
      });
      if (!persona) {
        return;
      }

      setItem(persona);
    }
  }, [location, personaEntities]);

  useEffect(() => {
    updatePersona();
  }, [updatePersona]);

  const [leftBarList, setLeftBarList] = useState({});
  const validationSchema = useMemo(
    () => generateA1sValidationSchema(flatedFields),
    [flatedFields]
  );
  const initialValues = {};

  for (const field of flatedFields) {
    if (field.type === FieldType.DATE_DISABLED) {
      initialValues[field.name] = storedValues[field.name]
        ? storedValues[field.name]
        : getDateDisplayFormat(new Date());
    } else if (field.type === FieldType.INTEGER) {
      initialValues[field.name] = storedValues.hasOwnProperty(field.name)
        ? storedValues[field.name]
        : field.extra_validations?.regex
        ? ""
        : "";
    } else if (field.type === FieldType.BOOLEAN) {
      initialValues[field.name] = storedValues.hasOwnProperty(field.name)
        ? storedValues[field.name]
        : "";
    } else if (field.type === FieldType.USER_EMAIL) {
      initialValues[field.name] = storedValues[field.name]
        ? storedValues[field.name]
        : researchEmail
        ? researchEmail
        : "";
    } else if (field.type === FieldType.NATIONALITY) {
      initialValues[field.name] = storedValues[field.name]
        ? storedValues[field.name]
        : countryObject[homeCountry] ?? homeCountry;
    } else if (
      field.type === FieldType.HOST_COUNTRY ||
      field.type === FieldType.COUNTRY
    ) {
      const countryType = homeCountry;

      initialValues[field.name] = countryObject[countryType]
        ? countryObject[countryType]
        : storedValues[field.name]
        ? storedValues[field.name]
        : countryType
        ? countryType
        : "";
    } else if (myCompanyKey.includes(field.name)) {
      initialValues[field.name] = storedValues[field.name]
        ? storedValues[field.name]
        : "";
    } else if (field.type === FieldType?.HOST_COUNTRY_CODE) {
      initialValues[field.name] = storedValues[field.name]
        ? storedValues[field.name]
        : hostCountry
        ? hostCountry
        : "";
    } else if (field?.type === FieldType.ADD_DESTINATION) {
      initialValues[field.name] = storedValues[field.name]
        ? storedValues[field.name]
        : false;
    } else {
      initialValues[field.name] = storedValues[field.name]
        ? storedValues[field.name]
        : "";
    }
    if (
      field.persona === FieldType.ASSUMPTION &&
      employerDetail.hasOwnProperty(field.name)
    ) {
      initialValues[field.name] = employerDetail[field.name];
    }
  }

  const formikData = useFormik({
    initialValues,
    onSubmit: () => {},
    validationSchema,
    enableReinitialize: true,
  });

  const { values, errors, setFieldValue, setErrors, setFieldError } =
    formikData;

  const getComplianceDetails = useCallback(async () => {
    const personaAnswer = await dashboardService.getPersonaEntityAnswer(
      item.uuid
    );
    if (!personaAnswer || personaAnswer?.error) return;
    let personaFields = [];

    personaAnswer.data.results?.forEach((_item) => {
      const isValueArray = Array.isArray(_item.fields);
      if (isValueArray) {
        personaFields = [...personaFields, ..._item.fields];
      }

      if (_item.compliance_type === "A1")
        setApiA1FieldValues(isValueArray ? _item.fields : []);
      if (_item.compliance_type === "MSW-A1")
        setApiMSWFieldValues(isValueArray ? _item.fields : []);
      if (_item.compliance_type === "COC")
        setApiCOCFieldValues(isValueArray ? _item.fields : []);
    });
    setApiFieldAnswers([...personaAnswer.data.results]);
    const names = {};
    const manullFieldsNames = {};
    if (personaFields.length) {
      const storingData = {};
      personaFields?.forEach((_field) => {
        const { key, value, auto_populated } = _field;
        setFieldValue(key, value);
        storingData[key] = value;
        if (auto_populated) names[key] = key;
        else if (value?.toString()) {
          manullFieldsNames[key] = key;
        }
      });
      if (Object.keys(storingData)?.length) {
        dispatch(updateValues({ values: storingData }));
      }
      setPrefilledNames(names);
      setManualFilledFields(manullFieldsNames);
    }
  }, [item, setFieldValue, dispatch]);

  const getFormData = useCallback(async () => {
    if (!item?.country_code) return;
    await getComplianceDetails();
    dispatch(
      getFormFieldData({
        homeCountry: item?.country_code,
        type: item?.compliance_type_name,
        entity: item,
      })
    ).then(() => {
      setTimeout(() => {
        setErrors({});
      }, 1000);
    });
  }, [dispatch, item, getComplianceDetails, setErrors]);

  useEffect(() => {
    getFormData();
  }, [getFormData]);
  useEffect(() => {
    if (flatedFields && questionnaire) {
      const groupsArray = questionnaire
        .filter((field) => field.type !== undefined)
        .map((field) => field.group?.trim());

      const uniqueGroupsArray = groupsArray.filter(
        (group, index, self) => self.indexOf(group) === index
      );

      const groupObject = {};
      uniqueGroupsArray.forEach((group, index) => {
        groupObject[index + 1] = group?.trim();
      });
      setLeftBarList(groupObject);
      // Reinitialize Formik and the others
      for (const field of flatedFields) {
        if (field.type === FieldType.DATE_DISABLED) {
          setFieldValue(
            field.name,
            storedValues[field.name]
              ? storedValues[field.name]
              : getDateDisplayFormat(new Date())
          );
        } else if (field.type === FieldType.INTEGER) {
          setFieldValue(
            field.name,
            storedValues.hasOwnProperty(field.name)
              ? storedValues[field.name]
              : field.extra_validations?.regex
              ? ""
              : ""
          );
        } else if (field.type === FieldType.BOOLEAN) {
          setFieldValue(
            field.name,
            storedValues.hasOwnProperty(field.name)
              ? storedValues[field.name]
              : ""
          );
        } else if (field.type === FieldType.USER_EMAIL) {
          setFieldValue(
            field.name,
            storedValues[field.name]
              ? storedValues[field.name]
              : researchEmail
              ? researchEmail
              : ""
          );
        } else if (field.type === FieldType.NATIONALITY) {
          setFieldValue(
            field.name,
            storedValues[field.name]
              ? storedValues[field.name]
              : countryObject[homeCountry] ?? homeCountry
          );
        } else if (
          field.type === FieldType.HOST_COUNTRY ||
          field.type === FieldType.COUNTRY
        ) {
          const countryType = homeCountry;

          setFieldValue(
            field.name,
            countryObject[countryType]
              ? countryObject[countryType]
              : storedValues[field.name]
              ? storedValues[field.name]
              : countryType
              ? countryType
              : ""
          );
        } else if (myCompanyKey.includes(field.name)) {
          setFieldValue(
            field.name,
            storedValues[field.name] ? storedValues[field.name] : ""
          );
        } else if (field.type === FieldType?.HOST_COUNTRY_CODE) {
          setFieldValue(
            field.name,
            storedValues[field.name]
              ? storedValues[field.name]
              : hostCountry
              ? hostCountry
              : ""
          );
        } else if (field?.type === FieldType.ADD_DESTINATION) {
          setFieldValue(
            field.name,
            storedValues[field.name] ? storedValues[field.name] : false
          );
        } else {
          setFieldValue(
            field.name,
            storedValues[field.name] ? storedValues[field.name] : ""
          );
        }
        if (
          field.persona === FieldType.ASSUMPTION &&
          employerDetail.hasOwnProperty(field.name)
        ) {
          setFieldValue(field.name, employerDetail[field.name]);
        }
      }
      setErrorPerPage({});
      setMaxVisitedGroup(1);
    }
  }, [
    questionnaire,
    flatedFields,
    setFieldValue,
    employerDetail,
    storedValues,
    researchEmail,
    hostCountry,
    countryObject,
    homeCountry,
    myCompanyKey,
  ]);
  useEffect(() => {
    if (flatedFields && questionnaire) {
      const groupsArray = questionnaire
        ?.filter((field) => field.type !== undefined)
        .map((field) => field.group.trim());

      const uniqueGroupsArray = groupsArray.filter(
        (group, index, self) => self.indexOf(group) === index
      );

      const groupObject = {};
      uniqueGroupsArray.forEach((group, index) => {
        groupObject[index + 1] = group;
      });
      setLeftBarList(groupObject);
      // Reinitialize Formik and the others
      for (const field of flatedFields) {
        if (field.type === FieldType.DATE_DISABLED) {
          setFieldValue(
            field.name,
            storedValues[field.name]
              ? storedValues[field.name]
              : getDateDisplayFormat(new Date())
          );
        } else if (field.type === FieldType.INTEGER) {
          setFieldValue(
            field.name,
            storedValues.hasOwnProperty(field.name)
              ? storedValues[field.name]
              : field.extra_validations?.regex
              ? ""
              : ""
          );
        } else if (field.type === FieldType.BOOLEAN) {
          setFieldValue(
            field.name,
            storedValues.hasOwnProperty(field.name)
              ? storedValues[field.name]
              : ""
          );
        } else if (field.type === FieldType.USER_EMAIL) {
          setFieldValue(
            field.name,
            storedValues[field.name]
              ? storedValues[field.name]
              : researchEmail
              ? researchEmail
              : ""
          );
        } else if (field.type === FieldType.NATIONALITY) {
          setFieldValue(
            field.name,
            storedValues[field.name]
              ? storedValues[field.name]
              : countryObject[homeCountry] ?? homeCountry
          );
        } else if (
          field.type === FieldType.HOST_COUNTRY ||
          field.type === FieldType.COUNTRY
        ) {
          const countryType = homeCountry;

          setFieldValue(
            field.name,
            countryObject[countryType]
              ? countryObject[countryType]
              : storedValues[field.name]
              ? storedValues[field.name]
              : countryType
              ? countryType
              : ""
          );
        } else if (myCompanyKey.includes(field.name)) {
          setFieldValue(
            field.name,
            storedValues[field.name] ? storedValues[field.name] : ""
          );
        } else if (field.type === FieldType?.HOST_COUNTRY_CODE) {
          setFieldValue(
            field.name,
            storedValues[field.name]
              ? storedValues[field.name]
              : hostCountry
              ? hostCountry
              : ""
          );
        } else if (field?.type === FieldType.ADD_DESTINATION) {
          setFieldValue(
            field.name,
            storedValues[field.name] ? storedValues[field.name] : false
          );
        } else {
          setFieldValue(
            field.name,
            storedValues[field.name] ? storedValues[field.name] : ""
          );
        }
        if (
          field.persona === FieldType.ASSUMPTION &&
          employerDetail.hasOwnProperty(field.name)
        ) {
          setFieldValue(field.name, employerDetail[field.name]);
        }
      }
      setErrorPerPage({});
      setMaxVisitedGroup(1);
    }
  }, [
    flatedFields,
    setFieldValue,
    employerDetail,
    storedValues,
    researchEmail,
    hostCountry,
    countryObject,
    homeCountry,
    myCompanyKey,
    questionnaire,
  ]);
  const updateFieldVisibleRef = useCallback((name, flag) => {
    setFieldVisible((prev) => ({ ...prev, [name]: flag }));
  }, []);
  const fillFieldsValue = useCallback(
    (fieldsParam) => {
      fieldsParam?.conditional_fields?.forEach((i) => {
        if (i?.type !== FieldType.ADD_DESTINATION) {
          const lastString = i?.name.substring(i?.name.lastIndexOf("_") + 1);
          const preViousNumber = parseInt(lastString) - 1;
          const replaceLastWithDecrementInt = i?.name?.replace(
            lastString,
            String(preViousNumber)
          );

          setFieldValue(replaceLastWithDecrementInt, values[i?.name]);
          if (i?.conditional_fields?.length) {
            fillFieldsValue(i);
          }
        }
      });
    },
    [setFieldValue, values]
  );

  const updateFieldValue = useCallback(
    async (name, value) => {
      if (fieldResetFlagRef.current) {
        setFieldValue(name, value);
        const updatedField = flatedFields.find((field) => field.name === name);
        if (updatedField && updatedField.employer_common_name) {
          const allCommonFields = flatedFields.filter(
            (field) =>
              field.name !== name &&
              field.employer_common_name === updatedField.employer_common_name
          );
          if (allCommonFields.length) {
            let shouldUpdatePolandDates = true;

            allCommonFields.forEach((commonField) => {
              if (values[commonField?.name]?.length) {
                shouldUpdatePolandDates = false;
              }
            });

            if (shouldUpdatePolandDates) {
              allCommonFields.forEach((commonField) => {
                if (!values[commonField?.name]?.length) {
                  setFieldValue(commonField.name, value);
                }
              });
            }
          }
        }
      }
    },
    [flatedFields, setFieldValue, values]
  );
  useEffect(() => {
    const selectedGroupName = leftBarList[currentSelectedGroup];
    setCurrentPageFieldNames(
      flatedFields
        .filter((field) => field.group === selectedGroupName)
        .map((field) => field.name)
    );
    if (maxVisitedGroup < currentSelectedGroup) {
      setMaxVisitedGroup(currentSelectedGroup);
    }
  }, [currentSelectedGroup, flatedFields, leftBarList, maxVisitedGroup]);

  useEffect(() => {
    const errorList = currentPageFieldNames?.map((name) => {
      if (name && errors && fieldVisible) {
        return errors[name] && fieldVisible[name];
      }
    });
    let isExistError = false;
    for (let i = 0; i < errorList.length; i++) {
      if (errorList[i]) {
        isExistError = true;
        break;
      }
    }
    setErrorPerPage((prev) => ({
      ...prev,
      [currentSelectedGroup]: isExistError,
    }));
  }, [currentPageFieldNames, currentSelectedGroup, errors, fieldVisible]);
  useEffect(() => {
    fieldResetFlagRef.current = true;
  }, [currentSelectedGroup]);

  const updatePersonaAnswers = useCallback(async () => {
    if (!item?.uuid && flatedFields) {
      return;
    }
    if (
      !apiFieldAnswers?.length ||
      !filterdEmployerMSWQuestionaire?.length ||
      !filterdEmployerA1Questionaire?.length
    ) {
      return;
    }
    if (isAddressUpdated) return;
    if (Object.keys(address)?.length) {
      let a1Payload = apiFieldAnswers.find(
        (_item) => _item.compliance_type === "A1"
      ) || {
        uuid: item.uuid,
        fields: {},
        is_complete: false,
      };
      let mswPayload = apiFieldAnswers.find(
        (_item) => _item.compliance_type === "MSW-A1"
      ) || {
        uuid: item.uuid,
        fields: {},
        is_complete: false,
      };
      let cocPayload = apiFieldAnswers.find(
        (_item) => _item.compliance_type === "COC"
      ) || {
        uuid: item.uuid,
        fields: {},
        is_complete: false,
      };

      const a1Fields = [];
      const mswFields = [];
      const cocFields = [];

      filterdEmployerMSWQuestionaire.forEach((_item) => {
        if (address[_item.type]) {
          mswFields.push({
            key: _item.name,
            value: address[_item.type],
            auto_populated: true,
          });
        }
      });
      filterdEmployerA1Questionaire.forEach((_item) => {
        if (address[_item.type]) {
          a1Fields.push({
            key: _item.name,
            value: address[_item.type],
            auto_populated: true,
          });
        }
      });

      flatenFilterdEmployerCOCQuestionaire.forEach((_item) => {
        if (address[_item.type]) {
          cocFields.push({
            key: _item.name,
            value: address[_item.type],
            auto_populated: true,
          });
        }
      });
      console.log(
        "flatenFilterdEmployerCOCQuestionaire",
        flatenFilterdEmployerCOCQuestionaire
      );
      console.log(
        "filterdEmployerA1Questionaire",
        filterdEmployerA1Questionaire
      );
      console.log(
        "filterdEmployerMSWQuestionaire",
        filterdEmployerMSWQuestionaire
      );
      console.log("questionnaire", questionnaire);
      mswPayload = {
        uuid: mswPayload.uuid,
        is_complete: false,
        fields: mswFields,
      };
      a1Payload = {
        uuid: a1Payload.uuid,
        is_complete: false,
        fields: a1Fields,
      };
      cocPayload = {
        uuid: cocPayload.uuid,
        is_complete: false,
        fields: cocFields,
      };

      dispatch(submitFormFieldData([mswPayload, a1Payload, cocPayload])).then(
        () => {
          getComplianceDetails();
        }
      );
      setIsAddressUpdated(true);
    }
  }, [
    dispatch,
    item,
    flatedFields,
    address,
    getComplianceDetails,
    apiFieldAnswers,
    filterdEmployerA1Questionaire,
    filterdEmployerMSWQuestionaire,
    isAddressUpdated,
    flatenFilterdEmployerCOCQuestionaire,
  ]);

  useEffect(() => {
    if (flatedFields?.length) updatePersonaAnswers();
  }, [updatePersonaAnswers, flatedFields]);

  const requiredFields = useMemo(() => {
    const allFields = [];
    questionnaire.forEach((field) => {
      if (field.required) allFields.push(field);
    });
    return allFields;
  }, [questionnaire]);

  useEffect(() => {
    const errorList = Object.keys(errors);
    errorList?.forEach((errorKey) => {
      if (!values[errorKey]?.toString() && errors[errorKey]) {
        setFieldError(errorKey, "");
      }
    });
  }, [errors, setFieldError, values]);
  if (loading && personaEntitiesLoading && complianceLoading) return <Loader />;
  if (loading || personaEntitiesLoading || complianceLoading) return <Loader />;

  if (!questionnaire?.length)
    return (
      <div className="d-flex justify-content-center py-5">
        <TitleText restStyle="fs-5">No Employer question to show.</TitleText>
      </div>
    );

  return (
    <>
      {Object.keys(leftBarList)?.length > 1 ? (
        <Steps
          currentSelectedGroup={currentSelectedGroup}
          leftBarList={leftBarList}
        />
      ) : null}
      <GroupContainer
        countryName={itemEntity?.country_name || item?.country_name}
        personaName={itemEntity?.name || item?.name}
        fieldVisible={fieldVisible}
        fields={questionnaire}
        selectedGroupName={leftBarList[currentSelectedGroup]}
        values={values}
        errors={errors}
        setFieldValue={updateFieldValue}
        updateFieldVisibleRef={updateFieldVisibleRef}
        employerDetail={employerDetail}
        prefilledNames={prefilledNames}
        manualFilledFields={manualFilledFields}
      />
      <NavigationWrapper
        values={values}
        leftBarList={leftBarList}
        errorPerPage={errorPerPage}
        fieldResetFlagRef={fieldResetFlagRef}
        currentSelectedGroup={currentSelectedGroup}
        fieldVisible={fieldVisible}
        item={item}
        prefilledNames={prefilledNames}
        apiMSWFieldValues={apiMSWFieldValues}
        apiA1FieldValues={apiA1FieldValues}
        apiCOCFieldValues={apiCOCFieldValues}
        apiFieldAnswers={apiFieldAnswers}
        errors={errors}
        requiredFields={requiredFields}
      />
    </>
  );
};

export default Questionnaire;
