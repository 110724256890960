import { isEmpty, isEqual, isUndefined, keys, map, size } from "lodash";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fileToBase64 } from "../../../common/helper/fileConversions";
import { NavigationBar } from "./NavigationBar";
import {
  changeDisableNextStepStatus,
  submitFormFieldData,
  updateSelectedGroup,
  updateShowError,
  updateStep,
  updateValues,
} from "../../../slices/questionnaire";
import { useNavigate } from "react-router-dom";
import { closeAll, onExpandAndClose } from "../../../slices/layout";
import { updatePersonaEntities } from "../../../slices/dashboard";

export const NavigationWrapper = (props) => {
  const {
    values,
    leftBarList,
    errorPerPage,
    fieldResetFlagRef,
    currentSelectedGroup,
    item,
    prefilledNames,
    apiFieldAnswers,
    apiMSWFieldValues,
    apiA1FieldValues,
    errors,
    requiredFields,
    apiCOCFieldValues,
  } = props;
  const {
    flatenFilterdEmployerA1Questionaire,
    flatenFilterdEmployerMSWQuestionaire,
    flatenFilterdEmployerCOCQuestionaire,
  } = useSelector((state) => state.questionnaire);
  const { routes } = useSelector((state) => state.layout);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isLastGroup = isEqual(currentSelectedGroup, size(leftBarList));
  const isFirstGroup = isEqual(currentSelectedGroup, 1);

  const moveToNextGroup = () => {
    if (!isLastGroup) {
      dispatch(updateSelectedGroup({ group: currentSelectedGroup + 1 }));
      dispatch(updateShowError({ flag: false }));
      fieldResetFlagRef.current = false;
    }
  };

  const moveToBeforeGroup = () => {
    if (!isFirstGroup) {
      dispatch(updateSelectedGroup({ group: currentSelectedGroup - 1 }));
      dispatch(updateShowError({ flag: false }));
      fieldResetFlagRef.current = false;
    }
  };

  const handleStep = async (s) => {
    map(keys(values), async (value) => {
      if (
        !isUndefined(values[value]) &&
        !isEmpty(values[value]) &&
        values[value] instanceof File
      ) {
        const selectedFile = values[value];
        if (selectedFile instanceof File) {
          const base64String = await fileToBase64(selectedFile);
          if (typeof base64String === "string") {
            values[value] = {
              name: selectedFile.name,
              fileValue: base64String,
            };
          }
        }
      }
    });

    dispatch(updateValues({ values }));
    dispatch(updateShowError({ flag: false }));
    dispatch(updateStep({ step: s }));
  };

  const updateSidebar = (pathname) => {
    dispatch(closeAll({}));
    const route =
      routes.find((i) => i.link === pathname) ||
      routes.find((i) => i.children.find((j) => j.link === pathname));
    if (route) {
      dispatch(onExpandAndClose({ id: route.id }));
    }
  };
  const onBack = () => {
    updateSidebar("/complete-questionnaire");
    navigate("/complete-questionnaire");
  };

  const determinedBackFunc = () => {
    isFirstGroup ? onBack() : moveToBeforeGroup();
  };

  const determinedNextFunc = () => {
    let errorExsist = false;
    let completeSubmition = true;
    let updateCompanyName = "";
    requiredFields.forEach((field) => {
      if (
        errors[field.name] &&
        errors[field.name] !== "This field is required"
      ) {
        errorExsist = true;
        completeSubmition = false;
      }
      const value = values[field.name].toString();
      if (!value) {
        completeSubmition = false;
      }
      if (value && field.type === "employer_name") updateCompanyName = value;
    });

    if (errorExsist) return;

    // if (errorPerPage[currentSelectedGroup]) {
    //   dispatch(updateShowError({ flag: true }));
    //   return;
    // }

    dispatch(updateValues({ values }));
    if (isLastGroup) {
      let a1Payload = apiFieldAnswers.find(
        (_item) => _item.compliance_type === "A1"
      );
      let mswPayload = apiFieldAnswers.find(
        (_item) => _item.compliance_type === "MSW-A1"
      );
      let cocPayload = apiFieldAnswers.find(
        (_item) => _item.compliance_type === "COC"
      );

      // const fieldsData = Object.keys(values)?.map((_item) => {
      //   let auto_populated = false;
      //   if (prefilledNames[_item]) {
      //     const field = apiFieldValues.find((_itm) => _itm.key === _item);
      //     if (field && field.value === values[_item]) {
      //       auto_populated = true;
      //     }
      //   }
      //   return {
      //     key: _item,
      //     value: values[_item],
      //     auto_populated,
      //   };
      // });
      const a1Fields = [];
      const mswFields = [];
      const cocFields = [];
      // const isValues = Object.values(values).some(
      //   (value) => value.toString() !== ""
      // );
      // const isMissingValue = Object.values(values).some(
      //   (value) => value.toString() === ""
      // );

      const isCompleted = completeSubmition;

      Object.keys(values)?.forEach((_item) => {
        let auto_populatedA1 = false;
        if (prefilledNames[_item]) {
          const field = apiA1FieldValues.find((_itm) => _itm.key === _item);
          if (field && field.value === values[_item]) {
            auto_populatedA1 = true;
          }
        }

        const exsistInA1 = flatenFilterdEmployerA1Questionaire.find(
          (__item) => __item.name === _item
        );

        if (exsistInA1) {
          a1Fields.push({
            key: _item,
            value: values[_item],
            auto_populated: auto_populatedA1,
          });
          if (exsistInA1.employer_common_name) {
            const commonNameExsistInMSW =
              flatenFilterdEmployerMSWQuestionaire.find(
                (__item) =>
                  __item.employer_common_name ===
                  exsistInA1.employer_common_name
              );
            if (commonNameExsistInMSW) {
              mswFields.push({
                key: commonNameExsistInMSW.name,
                value: values[_item],
                auto_populated: auto_populatedA1,
              });
            }
            const commonNameExsistInCOC =
              flatenFilterdEmployerCOCQuestionaire.find(
                (__item) =>
                  __item.employer_common_name ===
                  exsistInA1.employer_common_name
              );
            if (commonNameExsistInCOC) {
              cocFields.push({
                key: commonNameExsistInCOC.name,
                value: values[_item],
                auto_populated: auto_populatedA1,
              });
            }
          }
        }

        let auto_populatedMSW = false;
        if (prefilledNames[_item]) {
          const field = apiMSWFieldValues.find((_itm) => _itm.key === _item);
          if (field && field.value === values[_item]) {
            auto_populatedA1 = true;
          }
        }
        const exsistInMSW = flatenFilterdEmployerMSWQuestionaire.find(
          (__item) => __item.name === _item
        );
        if (exsistInMSW) {
          const alreadyExsist = mswFields.find((field) => {
            return field.key === _item;
          });
          if (!alreadyExsist)
            mswFields.push({
              key: _item,
              value: values[_item],
              auto_populated: auto_populatedMSW,
            });

          if (exsistInMSW.employer_common_name) {
            const commonNameExsistInCOC =
              flatenFilterdEmployerCOCQuestionaire.find(
                (__item) =>
                  __item.employer_common_name ===
                  exsistInMSW.employer_common_name
              );
            if (commonNameExsistInCOC) {
              cocFields.push({
                key: commonNameExsistInCOC.name,
                value: values[_item],
                auto_populated: auto_populatedMSW,
              });
            }
          }
        }

        let auto_populatedCOC = false;
        if (prefilledNames[_item]) {
          const field = apiCOCFieldValues.find((_itm) => _itm.key === _item);
          if (field && field.value === values[_item]) {
            auto_populatedCOC = true;
          }
        }
        const exsistInCOC = flatenFilterdEmployerCOCQuestionaire.find(
          (__item) => __item.name === _item
        );
        if (exsistInCOC) {
          const alreadyExsist = cocFields.find((field) => {
            return field.key === _item;
          });
          if (!alreadyExsist)
            cocFields.push({
              key: _item,
              value: values[_item],
              auto_populated: auto_populatedCOC,
            });
        }
      });
      if (mswPayload)
        mswPayload = {
          uuid: mswPayload.uuid,
          is_complete: isCompleted,
          fields: mswFields,
        };
      if (a1Payload)
        a1Payload = {
          uuid: a1Payload.uuid,
          is_complete: isCompleted,
          fields: a1Fields,
        };
      if (cocPayload)
        cocPayload = {
          uuid: cocPayload.uuid,
          is_complete: isCompleted,
          fields: cocFields,
        };
      const payload = [];
      if (mswPayload) payload.push(mswPayload);
      if (a1Payload) payload.push(a1Payload);
      if (cocPayload) payload.push(cocPayload);

      dispatch(submitFormFieldData(payload)).then(
        () => {
          if (updateCompanyName) {
            dispatch(
              updatePersonaEntities({
                name: updateCompanyName,
                uuid: item.uuid,
              })
            )
              .unwrap()
              .then(() => {
                navigate("/complete-questionnaire");
              })
              .catch(() => {
                navigate("/complete-questionnaire");
              });
          } else navigate("/complete-questionnaire");
        }
      );
    } else moveToNextGroup();
  };
  useEffect(() => {
    dispatch(changeDisableNextStepStatus(errorPerPage[currentSelectedGroup]));
    return () => {
      dispatch(changeDisableNextStepStatus(true));
    };
  }, [currentSelectedGroup, dispatch, errorPerPage]);

  return (
    <NavigationBar
      nextDoubleWidth={true}
      backFunc={determinedBackFunc}
      nextFunc={determinedNextFunc}
      cancelFunc={onBack}
      cancelVisible={true}
      // nextDisable={nextStepDisable}
      nextText={isLastGroup ? "Save" : "Next"}
    />
  );
};
