import React, { useCallback, useEffect, useMemo, useState } from "react";
import { debounce, uniqueId } from "lodash";
import sortIcon from "../../../assets/images/sort-icon.svg";
import {
  TextInput,
  NumberFormate,
  HelpText,
} from "../../../components/common/index.jsx";
import { useDispatch, useSelector } from "react-redux";
import {
  downloadFilledApplications,
  getApplicationsStats,
  getFilledApplications,
} from "../../../slices/filledApplications.js";
import useCountryList from "../../../assets/shared/hooks/useCountryList.js";
import { TableLaoding } from "../../../components/common/Loading/TableLoading.js";
import { handleDownload } from "../../../utils/handleDownload.js";
import { Filter } from "../../../components/Filter/index.jsx";
import * as FileSaver from "file-saver";
import { AddComment } from "../../../components/common/Modal/addCommentModal.jsx";
import { jwtDecode } from "jwt-decode";
import { CommentList } from "../../../components/common/Modal/commentList.jsx";
import styles from "./styles.module.css";

const API_URL = process.env.REACT_APP_BACK_END_URL;

export const FileApplication = () => {
  const themeColor = process.env.REACT_APP_LIGHT_PRIMARY;

  const dispatch = useDispatch();
  const { getCountryName } = useCountryList();
  const {
    filledApplications,
    loading,
    nextPageUrl,
    previousPageUrl,
    count,
    page_size,
    applicationCompletedCount,
    applicationCountriesCoverdCount,
    a1Count,
  } = useSelector((state) => state.filledApplications);

  const storageAccessToken = localStorage.getItem("AUTH0_TOKEN_RESPONSE") || "";
  const parsedData = storageAccessToken ? JSON.parse(storageAccessToken) : "";
  const userData = parsedData
    ? jwtDecode(parsedData.id_token.toString())
    : null;

  const [searchText, setSearchText] = React.useState("");
  const [searchTimeout, setSearchTimeout] = React.useState(null);
  const [isDowndablePDF, setIsDowndablePDF] = useState(false);

  const [sortCondition, setSortCondition] = React.useState({});
  const [pageNUmber, setPageNumber] = useState(1);
  const [downLoadBtnLoading, setDownLoadBtnLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [filterCollaps, setFilterCollaps] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [isCozmUser, setIsCozmUser] = useState(false);
  const [allStatusList, setAllStatusList] = useState([]);

  const baseStyle = {
    height: "45px",
    color: themeColor,
    borderColor: themeColor,
    backgroundColor: "white",
  };

  const disabledStyle = {
    cursor: "not-allowed",
    pointerEvents: "all",
  };

  const combinedStyle = !isDowndablePDF
    ? { ...baseStyle, ...disabledStyle }
    : baseStyle;

  const handleSorting = (field) => {
    const sortConditions = sortCondition;
    let order = "";
    if (sortConditions.field === field) {
      order = sortConditions.order === "" ? "-" : "";
    }
    setSortCondition({
      field,
      order,
    });

    let url =
      API_URL +
      `compliance/compliance-requests/?ordering=${order}${field}&page=${pageNUmber}`;
    if (searchQuery) url += "&" + searchQuery;
    getFilledApplicationsHandler(url);
  };

  function isCozmMail(email) {
    const domain = email?.substring(email.lastIndexOf("@") + 1);
    return domain?.toLowerCase() === "thecozm.com";
  }
  useState(() => {
    if (userData?.email && isCozmMail(userData?.email)) {
      setIsCozmUser(true);
    }
  }, [userData]);

  useEffect(() => {
    dispatch(getApplicationsStats());
  }, [dispatch]);

  const filterContent = (search) => {};

  const getFilledApplicationsHandler = React.useCallback(
    (url) => {
      dispatch(getFilledApplications(url))
        .unwrap()
        .then(() => {
          filterContent();
        })
        .catch(() => {});
    },
    [dispatch]
  );

  React.useEffect(() => {
    getFilledApplicationsHandler();
  }, [getFilledApplicationsHandler]);

  const fetchNextPage = () => {
    if (nextPageUrl) {
      const nextPage = Number(pageNUmber) + 1;
      setPageNumber(nextPage);
      gotoSpecificPage(nextPage);
    }
  };

  const fetchPreviousPage = () => {
    if (previousPageUrl) {
      const previousPage = Number(pageNUmber) - 1;
      setPageNumber(previousPage);
      gotoSpecificPage(previousPage);
    }
  };

  const handleSearch = (value) => {
    setSearchText(value);

    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    const timeout = setTimeout(() => {
      if (!value) {
        getFilledApplicationsHandler();
      }

      if (value.length) {
        const url = API_URL + `compliance/compliance-requests/?search=${value}`;
        getFilledApplicationsHandler(url);
      }
    }, 500); // Adjust the debounce time according to your needs

    setSearchTimeout(timeout);
  };

  const getFormattedDate = (date) => {
    const dateObj = new Date(date);
    const timeOfDay = `${dateObj.getHours()}:${dateObj.getMinutes()}:${dateObj.getSeconds()}`;
    const formattedDate = `${dateObj.getDate()}/${
      dateObj.getMonth() + 1
    }/${dateObj.getFullYear()}`;
    const formattedDateTime = `${formattedDate} ${timeOfDay}`;

    return formattedDateTime;
  };

  const totalPages = Math.ceil(count / page_size) || 0;

  const gotoSpecificPage = (page) => {
    const { field, order } = sortCondition;

    let url = API_URL + `compliance/compliance-requests/?page=${page}`;
    if (searchQuery) url += "&" + searchQuery;

    if (field) {
      url += `&ordering=${order}${field}`;
    }

    const urlObj = new URL(url);

    urlObj.searchParams.set("page", page);
    const pageUrl = urlObj.toString();
    getFilledApplicationsHandler(pageUrl);
  };

  const debouncedGotoSpecificPage = useCallback(
    debounce((page) => gotoSpecificPage(page), 500),
    []
  );

  useEffect(() => {
    let isDownloadStatus = false;
    filledApplications?.forEach((i) => {
      const downloadFilesUrls = i?.reference_details?.files;
      if (downloadFilesUrls?.length) isDownloadStatus = true;
    });
    setIsDowndablePDF(isDownloadStatus);
  }, [filledApplications]);

  const csvDownloadHandler = () => {
    setDownLoadBtnLoading(true);
    const url = API_URL + `compliance/compliance-requests/export/`;

    dispatch(
      downloadFilledApplications(
        `${url}${searchQuery ? "?" + searchQuery : ""}`
      )
    )
      .unwrap()
      .then((response) => {
        setDownLoadBtnLoading(false);
        // Create a blob from the response data
        const blob = new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        // Use the FileSaver library to save the file
        FileSaver.saveAs(blob, `Filed_applications_${uniqueId()}`);
      })
      .catch((err) => {
        setDownLoadBtnLoading(false);
        console.log(err);
      });
  };

  const statusHelpText = useMemo(() => {
    let text = "";
    allStatusList?.forEach((status) => {
      const detail = `${status.display_name}: ${status.description}`;
      text += `${text ? "\n\n" : ""} ${detail}`;
    });

    return text;
  }, [allStatusList]);

  return (
    <main>
      {loading ? (
        <div
          className="position-fixed top-0 start-0 w-100 h-100 d-flex justify-content-center align-items-center bg-dark bg-opacity-50"
          style={{
            zIndex: "9999",
          }}
        >
          <TableLaoding />
        </div>
      ) : null}

      <section className="listing-section">
        <div className="container-fluid">
          <div className="col-md-12">
            <div className="d-flex gap-3">
              <h2>Filed application</h2>
            </div>
            <p>
              Below is a list of A1 social security applications which have been
              filled. We have also included the relevant filing information
              which were issued along with a copy of the A1 application. If you
              have any questions, please contact us at{" "}
              <a
                style={{ color: themeColor }}
                href="mailto:assure@cibtvisas.com"
              >
                assure@cibtvisas.com
              </a>
              .
            </p>

            <div className={`${styles.searchNcards_Container} mb-5 mb-lg-2`}>
              <div style={{ width: "250px" }}>
                <TextInput
                  label="Entity"
                  value={searchText}
                  isRequired={false}
                  placeHolder="Search here"
                  onChange={(name, value) => {
                    handleSearch(value);
                  }}
                  Icon={
                    <div className="pb-1">
                      <i className="h-100 bi bi-search" />
                    </div>
                  }
                />
              </div>

              <div className={`${styles.rightSideFlex}`}>
                {/* CARDS */}
                <div className={styles.a1Card_Parent}>
                  <div className={styles.a1Card}>
                    <h2>{a1Count.toLocaleString()}</h2>
                    <p>A1s</p>
                    <hr />
                  </div>

                  <div className={styles.a1Card}>
                    <h2>{applicationCountriesCoverdCount.toLocaleString()}</h2>
                    <p>Home countries covered</p>
                    <hr />
                  </div>
                </div>

                <div
                  style={{ marginTop: "-9px" }}
                  className="d-flex justify-content-end"
                >
                  <Filter
                    setSearchQuery={setSearchQuery}
                    setFilterCollaps={setFilterCollaps}
                    setPageNumber={setPageNumber}
                    setSortCondition={setSortCondition}
                    setAllStatusList={setAllStatusList}
                  />
                </div>
              </div>
            </div>

            <div style={{ marginTop: "55px" }} className="row table-filter-div">
              <div className="col-12">
                <div>
                  <div className="table-responsive">
                    <div
                      className="overflow-auto"
                      style={
                        filterCollaps
                          ? { height: "770px" }
                          : { maxHeight: "770px", minHeight: "fit-content" }
                      }
                    >
                      <table
                        className="w-100"
                        style={{
                          borderCollapse: "collapse",
                        }}
                      >
                        <thead>
                          <tr>
                            <th className="position-sticky top-0">
                              <div className="d-flex align-items-center gap-2">
                                <div>No.</div>
                              </div>
                            </th>
                            <th className="position-sticky top-0">
                              <div className="d-flex align-items-center gap-2">
                                <img
                                  onClick={() => {
                                    handleSorting("first_name");
                                  }}
                                  src={sortIcon}
                                  alt="sort-icon"
                                  style={{ cursor: "pointer" }}
                                />
                                <div style={{ minWidth: "85px" }}>
                                  First name
                                </div>
                              </div>
                            </th>
                            <th className="position-sticky top-0">
                              <div className="d-flex align-items-center gap-2">
                                <img
                                  onClick={() => {
                                    handleSorting("last_name");
                                  }}
                                  src={sortIcon}
                                  alt="sort-icon"
                                  style={{ cursor: "pointer" }}
                                />
                                <div style={{ minWidth: "85px" }}>
                                  Last name
                                </div>
                              </div>
                            </th>
                            <th className="position-sticky top-0">
                              <div className="d-flex align-items-center gap-2">
                                <img
                                  onClick={() => {
                                    handleSorting("home_country_name");
                                  }}
                                  src={sortIcon}
                                  alt="sort-icon"
                                  style={{ cursor: "pointer" }}
                                />
                                <div style={{ minWidth: "110px" }}>
                                  Home country
                                </div>
                              </div>
                            </th>
                            <th className="position-sticky top-0">
                              <div className="d-flex align-items-center gap-2">
                                <img
                                  onClick={() => {
                                    handleSorting("host_country_name");
                                  }}
                                  src={sortIcon}
                                  alt="sort-icon"
                                  style={{ cursor: "pointer" }}
                                />
                                <div style={{ minWidth: "110px" }}>
                                  Host country
                                </div>
                              </div>
                            </th>
                            <th className="position-sticky top-0">
                              <div className="d-flex align-items-center gap-2">
                                <div style={{ minWidth: "130px" }}>
                                  Application type
                                </div>
                                <div></div>
                              </div>
                            </th>
                            <th className="position-sticky top-0">
                              <div className="d-flex align-items-center gap-2">
                                <img
                                  onClick={() => {
                                    handleSorting("email");
                                  }}
                                  src={sortIcon}
                                  alt="sort-icon"
                                  style={{ cursor: "pointer" }}
                                />
                                <div>Email</div>
                                <div></div>
                              </div>
                            </th>
                            <th className="position-sticky top-0">
                              <div className="d-flex align-items-center gap-2">
                                <img
                                  onClick={() => {
                                    handleSorting("persona_name");
                                  }}
                                  src={sortIcon}
                                  alt="sort-icon"
                                  style={{ cursor: "pointer" }}
                                />
                                <div>Entity</div>
                                <div></div>
                              </div>
                            </th>
                            <th className="position-sticky top-0">
                              <div className="d-flex align-items-center gap-2">
                                <div style={{ minWidth: "135px" }}>
                                  Filing information
                                </div>
                                <div></div>
                              </div>
                            </th>
                            <th className="position-sticky top-0">
                              <div className="d-flex align-items-center gap-2">
                                <img
                                  onClick={() => {
                                    handleSorting("status");
                                  }}
                                  src={sortIcon}
                                  alt="sort-icon"
                                  style={{ cursor: "pointer" }}
                                />
                                <div>
                                  <HelpText
                                    contentDivClassNames="d-flex"
                                    noPadding={true}
                                    helpText={statusHelpText}
                                    // isIconeHide={true}
                                    content={
                                      <div
                                        style={{ paddingRight: "5px" }}
                                        className="d-flex align-items-center cursor-pointer"
                                      >
                                        Status
                                      </div>
                                    }
                                  />
                                </div>
                                <div></div>
                              </div>
                            </th>
                            <th className="position-sticky top-0">
                              <div className="d-flex align-items-center gap-2">
                                <div style={{ minWidth: "110px" }}>
                                  <img
                                    onClick={() => {
                                      handleSorting("created_at");
                                    }}
                                    src={sortIcon}
                                    alt="sort-icon"
                                    style={{ cursor: "pointer" }}
                                  />
                                  Date
                                </div>
                                <div></div>
                              </div>
                            </th>
                            {isCozmUser ? (
                              <th className="position-sticky top-0">
                                <div className="d-flex align-items-center gap-2">
                                  <div style={{ minWidth: "110px" }}>
                                    <img
                                      onClick={() => {
                                        handleSorting("comments");
                                      }}
                                      src={sortIcon}
                                      alt="sort-icon"
                                      style={{ cursor: "pointer" }}
                                    />
                                    Comment
                                  </div>
                                  <div></div>
                                </div>
                              </th>
                            ) : null}
                          </tr>
                        </thead>
                        <tbody>
                          {filledApplications?.length
                            ? filledApplications.map((i, index) => {
                                const downloadFilesUrls =
                                  i.reference_details?.files;

                                const comment = i.comment?.replace(
                                  /<at>([^<]+)<\/at>/g,
                                  "@$1"
                                );

                                return (
                                  <tr key={index}>
                                    <td>
                                      <div
                                        className="d-inline-block"
                                        style={{ whiteSpace: "nowrap" }}
                                      >
                                        {pageNUmber * page_size -
                                          (page_size - index) +
                                          1}
                                      </div>
                                    </td>
                                    <td>
                                      <div
                                        className="d-inline-block"
                                        style={{ whiteSpace: "nowrap" }}
                                      >
                                        {i.first_name}
                                      </div>
                                    </td>
                                    <td>
                                      <div
                                        className="d-inline-block"
                                        style={{ whiteSpace: "nowrap" }}
                                      >
                                        {i.last_name}
                                      </div>
                                    </td>
                                    <td>
                                      <div
                                        className="d-inline-block"
                                        style={{ whiteSpace: "nowrap" }}
                                      >
                                        {getCountryName(i.home_country)}
                                      </div>
                                    </td>
                                    <td>
                                      <div
                                        className="d-inline-block"
                                        style={{ whiteSpace: "nowrap" }}
                                      >
                                        {i.host_country
                                          ? getCountryName(i.host_country)
                                          : ""}
                                      </div>
                                    </td>
                                    <td>
                                      <div
                                        className="d-inline-block"
                                        style={{ whiteSpace: "nowrap" }}
                                      >
                                        {i.compliance_type_description}
                                      </div>
                                    </td>
                                    <td>
                                      <div
                                        className="d-inline-block"
                                        style={{ whiteSpace: "nowrap" }}
                                      >
                                        {i.email}
                                      </div>
                                    </td>
                                    <td>
                                      <div
                                        className="d-inline-block"
                                        style={{ whiteSpace: "nowrap" }}
                                      >
                                        {i.persona_name}
                                      </div>
                                    </td>
                                    <td>
                                      <div
                                        className="d-inline-block"
                                        style={{ whiteSpace: "nowrap" }}
                                      >
                                        {downloadFilesUrls?.map(
                                          (url, index) => {
                                            return (
                                              <div
                                                onClick={() => {
                                                  handleDownload(
                                                    url,
                                                    i.uuid +
                                                      "_file" +
                                                      (index + 1)
                                                  );
                                                }}
                                                style={{ color: themeColor }}
                                                className="cursor-pointer px-3"
                                                key={index}
                                              >
                                                Download file {index + 1}
                                              </div>
                                            );
                                          }
                                        )}
                                      </div>
                                    </td>
                                    <td>
                                      <div
                                        className="d-inline-block"
                                        style={{ whiteSpace: "nowrap" }}
                                      >
                                        <HelpText
                                          contentDivClassNames="d-flex"
                                          noPadding={true}
                                          helpText={
                                            i.status_description ||
                                            allStatusList?.find(
                                              (status) =>
                                                status.display_name === i.status
                                            )?.description
                                          }
                                          isIconeHide={true}
                                          content={
                                            <div className="cursor-pointer">
                                              {i.status}
                                            </div>
                                          }
                                        />
                                      </div>
                                    </td>
                                    <td>
                                      <div
                                        className="d-inline-block"
                                        style={{ whiteSpace: "nowrap" }}
                                      >
                                        {getFormattedDate(i.created_at)}
                                      </div>
                                    </td>
                                    {isCozmUser ? (
                                      <td>
                                        <div
                                          className="d-inline-block"
                                          style={{ whiteSpace: "nowrap" }}
                                        >
                                          {!i.comment ? (
                                            <div
                                              onClick={() => setSelectedItem(i)}
                                              style={{ color: themeColor }}
                                              className="cursor-pointer px-3"
                                            >
                                              Add comment
                                            </div>
                                          ) : (
                                            <HelpText
                                              noPadding={true}
                                              helpText={comment}
                                              isIconeHide={true}
                                              content={
                                                <div
                                                  style={{ width: "150px" }}
                                                  className="cursor-pointer px-3 text-truncate"
                                                  onClick={() =>
                                                    setSelectedItem(i)
                                                  }
                                                >
                                                  {comment}
                                                </div>
                                              }
                                            />
                                          )}
                                        </div>
                                      </td>
                                    ) : null}
                                  </tr>
                                );
                              })
                            : null}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                {!filledApplications?.length ? (
                  <h5 className="w-full d-flex align-items-center justify-content-center py-5">
                    No data
                  </h5>
                ) : null}
              </div>
            </div>

            <div
              style={{ padding: "20px 10px", flexWrap: "wrap", gap: "5px" }}
              className="d-flex justify-content-between align-items-end"
            >
              <div>
                {previousPageUrl ? (
                  <button
                    style={{
                      backgroundColor: themeColor,
                      borderColor: themeColor,
                    }}
                    type="button"
                    className="btn btn-danger"
                    onClick={fetchPreviousPage}
                    disabled={!previousPageUrl}
                  >
                    Previous page
                  </button>
                ) : null}
              </div>

              {!!count && (
                <div style={{ marginBottom: "7px" }}>
                  <div>
                    {" "}
                    <span style={{ fontWeight: "700" }}>
                      {" "}
                      Total filed applications:
                    </span>{" "}
                    <NumberFormate>{Number(count)}</NumberFormate>
                  </div>
                </div>
              )}

              {totalPages ? (
                <div style={{ marginBottom: "7px" }}>
                  <span style={{ fontWeight: "700" }}> Page </span>{" "}
                  <input
                    style={{
                      width: "80px",
                      height: "30px",
                      borderRadius: "20px",
                      paddingLeft: "10px",
                      paddingRight: "10px",
                    }}
                    type="number"
                    value={pageNUmber}
                    onChange={(e) => {
                      const value = Math.min(
                        totalPages,
                        Math.max(0, Number(e.target.value))
                      );
                      if (value < 1) return;
                      setPageNumber(value);
                      debouncedGotoSpecificPage(value);
                    }}
                    max={totalPages}
                    min={1}
                  />{" "}
                  of {totalPages}
                </div>
              ) : null}
              {totalPages ? (
                <button
                  style={combinedStyle}
                  type="button"
                  className="btn  px-8 "
                  disabled={downLoadBtnLoading}
                  onClick={(e) => {
                    csvDownloadHandler();
                  }}
                >
                  {downLoadBtnLoading ? (
                    <span className="spinner-border spinner-border-sm me-1"></span>
                  ) : (
                    "Export to Excel"
                  )}
                </button>
              ) : null}

              <div>
                {nextPageUrl ? (
                  <button
                    style={{
                      backgroundColor: themeColor,
                      borderColor: themeColor,
                    }}
                    type="button"
                    className="btn btn-danger"
                    onClick={fetchNextPage}
                    disabled={!nextPageUrl}
                  >
                    Next page
                  </button>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </section>
      {selectedItem?.uuid ? (
        <CommentList
          userData={userData}
          setSelectedItem={setSelectedItem}
          selectedItem={selectedItem}
        />
      ) : null}
    </main>
  );
};
