import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useCountryList from "../../../assets/shared/hooks/useCountryList";
import { getFilledApplications } from "../../../slices/filledApplications";
import sortIcon from "../../../assets/images/sort-icon.svg";
import { handleDownload } from "../../../utils/handleDownload";

const FilledApplicationTable = ({ pageNUmber }) => {
  const { getCountryName } = useCountryList();
  const [sortCondition, setSortCondition] = useState({});
  const themeColor = process.env.REACT_APP_LIGHT_PRIMARY;
  const API_URL = process.env.REACT_APP_BACK_END_URL;
  const dispatch = useDispatch();
  const { filledApplications, page_size } = useSelector(
    (state) => state.filledApplications
  );

  const getFilledApplicationsHandler = useCallback(
    (url) => {
      dispatch(getFilledApplications(url))
        .unwrap()
        .then(() => {})
        .catch(() => {});
    },
    [dispatch]
  );

  const getFormattedDate = (date) => {
    const dateObj = new Date(date);
    const timeOfDay = `${dateObj.getHours()}:${dateObj.getMinutes()}:${dateObj.getSeconds()}`;
    const formattedDate = `${dateObj.getDate()}/${
      dateObj.getMonth() + 1
    }/${dateObj.getFullYear()}`;
    const formattedDateTime = `${formattedDate} ${timeOfDay}`;

    return formattedDateTime;
  };
  const handleSorting = (field) => {
    setSortCondition((prev) => {
      let order = "";

      if (prev.field === field) {
        order = prev.order === "" ? "-" : "";
      }

      return {
        field,
        order,
      };
    });
  };
  useEffect(() => {
    const { field, order } = sortCondition;

    if (field) {
      const url =
        API_URL + `compliance/compliance-requests/?ordering=${order}${field}`;
      getFilledApplicationsHandler(url);
    }
  }, [sortCondition]);

  return (
    <div className="row table-filter-div">
      <div className="col-12">
        <div>
          <div className="table-responsive">
            <div className="overflow-auto" style={{ maxHeight: "770px" }}>
              <table
                className="w-100"
                style={{
                  borderCollapse: "collapse",
                }}
              >
                <thead>
                  <tr>
                    <th className="position-sticky top-0">
                      <div className="d-flex align-items-center gap-2">
                        <div>No.</div>
                      </div>
                    </th>
                    <th className="position-sticky top-0">
                      <div className="d-flex align-items-center gap-2">
                        <img
                          onClick={() => {
                            handleSorting("first_name");
                          }}
                          src={sortIcon}
                          alt="sort-icon"
                          style={{ cursor: "pointer" }}
                        />
                        <div style={{ minWidth: "85px" }}>First name</div>
                      </div>
                    </th>
                    <th className="position-sticky top-0">
                      <div className="d-flex align-items-center gap-2">
                        <img
                          onClick={() => {
                            handleSorting("last_name");
                          }}
                          src={sortIcon}
                          alt="sort-icon"
                          style={{ cursor: "pointer" }}
                        />
                        <div style={{ minWidth: "85px" }}>Last name</div>
                      </div>
                    </th>
                    <th className="position-sticky top-0">
                      <div className="d-flex align-items-center gap-2">
                        <img
                          onClick={() => {
                            handleSorting("home_country_name");
                          }}
                          src={sortIcon}
                          alt="sort-icon"
                          style={{ cursor: "pointer" }}
                        />
                        <div style={{ minWidth: "110px" }}>Home country</div>
                      </div>
                    </th>
                    <th className="position-sticky top-0">
                      <div className="d-flex align-items-center gap-2">
                        <img
                          onClick={() => {
                            handleSorting("host_country_name");
                          }}
                          src={sortIcon}
                          alt="sort-icon"
                          style={{ cursor: "pointer" }}
                        />
                        <div style={{ minWidth: "110px" }}>Host country</div>
                      </div>
                    </th>
                    <th className="position-sticky top-0">
                      <div className="d-flex align-items-center gap-2">
                        <div style={{ minWidth: "130px" }}>
                          Application type
                        </div>
                        <div></div>
                      </div>
                    </th>
                    <th className="position-sticky top-0">
                      <div className="d-flex align-items-center gap-2">
                        <img
                          onClick={() => {
                            handleSorting("email");
                          }}
                          src={sortIcon}
                          alt="sort-icon"
                          style={{ cursor: "pointer" }}
                        />
                        <div>Email</div>
                        <div></div>
                      </div>
                    </th>
                    <th className="position-sticky top-0">
                      <div className="d-flex align-items-center gap-2">
                        <img
                          onClick={() => {
                            handleSorting("persona_name");
                          }}
                          src={sortIcon}
                          alt="sort-icon"
                          style={{ cursor: "pointer" }}
                        />
                        <div>Entity</div>
                        <div></div>
                      </div>
                    </th>
                    <th className="position-sticky top-0">
                      <div className="d-flex align-items-center gap-2">
                        <div style={{ minWidth: "135px" }}>
                          Filing information
                        </div>
                        <div></div>
                      </div>
                    </th>
                    <th className="position-sticky top-0">
                      <div className="d-flex align-items-center gap-2">
                        <img
                          onClick={() => {
                            handleSorting("status");
                          }}
                          src={sortIcon}
                          alt="sort-icon"
                          style={{ cursor: "pointer" }}
                        />
                        <div>Status</div>
                        <div></div>
                      </div>
                    </th>
                    <th className="position-sticky top-0">
                      <div className="d-flex align-items-center gap-2">
                        <div style={{ minWidth: "110px" }}>
                          <img
                            onClick={() => {
                              handleSorting("created_at");
                            }}
                            src={sortIcon}
                            alt="sort-icon"
                            style={{ cursor: "pointer" }}
                          />
                          Date
                        </div>
                        <div></div>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {filledApplications?.length
                    ? filledApplications.map((i, index) => {
                        const downloadFilesUrls = i.reference_details?.files;

                        return (
                          <tr key={index}>
                            <td>
                              <div
                                className="d-inline-block"
                                style={{ whiteSpace: "nowrap" }}
                              >
                                {pageNUmber * page_size -
                                  (page_size - index) +
                                  1}
                              </div>
                            </td>
                            <td>
                              <div
                                className="d-inline-block"
                                style={{ whiteSpace: "nowrap" }}
                              >
                                {i.first_name}
                              </div>
                            </td>
                            <td>
                              <div
                                className="d-inline-block"
                                style={{ whiteSpace: "nowrap" }}
                              >
                                {i.last_name}
                              </div>
                            </td>
                            <td>
                              <div
                                className="d-inline-block"
                                style={{ whiteSpace: "nowrap" }}
                              >
                                {getCountryName(i.home_country)}
                              </div>
                            </td>
                            <td>
                              <div
                                className="d-inline-block"
                                style={{ whiteSpace: "nowrap" }}
                              >
                                {i.host_country
                                  ? getCountryName(i.host_country)
                                  : ""}
                              </div>
                            </td>
                            <td>
                              <div
                                className="d-inline-block"
                                style={{ whiteSpace: "nowrap" }}
                              >
                                {i.compliance_type_description}
                              </div>
                            </td>
                            <td>
                              <div
                                className="d-inline-block"
                                style={{ whiteSpace: "nowrap" }}
                              >
                                {i.email}
                              </div>
                            </td>
                            <td>
                              <div
                                className="d-inline-block"
                                style={{ whiteSpace: "nowrap" }}
                              >
                                {i.persona_name}
                              </div>
                            </td>
                            <td>
                              <div
                                className="d-inline-block"
                                style={{ whiteSpace: "nowrap" }}
                              >
                                {downloadFilesUrls?.map((url, index) => {
                                  return (
                                    <div
                                      key={index}
                                      onClick={() => {
                                        handleDownload(
                                          url,
                                          i.uuid + "_file" + (index + 1)
                                        );
                                      }}
                                      style={{ color: themeColor }}
                                      className="cursor-pointer px-3"
                                    >
                                      Download file {index + 1}
                                    </div>
                                  );
                                })}
                              </div>
                            </td>
                            <td>
                              <div
                                className="d-inline-block"
                                style={{ whiteSpace: "nowrap" }}
                              >
                                {i.status}
                              </div>
                            </td>
                            <td>
                              <div
                                className="d-inline-block"
                                style={{ whiteSpace: "nowrap" }}
                              >
                                {getFormattedDate(i.created_at)}
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    : null}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {!filledApplications?.length ? (
          <h5 className="w-full d-flex align-items-center justify-content-center py-5">
            No data
          </h5>
        ) : null}
      </div>
    </div>
  );
};

export default FilledApplicationTable;
