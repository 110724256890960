import validator from "validator";
import ICON_NAMES from "../components/Icone/iconeNames.js";

export const countryList = {
  AF: "Afghanistan",
  AL: "Albania",
  DZ: "Algeria",
  AS: "American Samoa",
  AD: "Andorra",
  AO: "Angola",
  AI: "Anguilla",
  AQ: "Antarctica",
  AG: "Antigua and Barbuda",
  AR: "Argentina",
  AM: "Armenia",
  AW: "Aruba",
  AU: "Australia",
  AT: "Austria",
  AZ: "Azerbaijan",
  BS: "Bahamas ",
  BH: "Bahrain",
  BD: "Bangladesh",
  BB: "Barbados",
  BY: "Belarus",
  BE: "Belgium",
  BZ: "Belize",
  BJ: "Benin",
  BM: "Bermuda",
  BT: "Bhutan",
  BO: "Bolivia (Plurinational State of)",
  BQ: "Bonaire, Sint Eustatius and Saba",
  BA: "Bosnia and Herzegovina",
  BW: "Botswana",
  BV: "Bouvet Island",
  BR: "Brazil",
  IO: "British Indian Ocean Territory ",
  BN: "Brunei Darussalam",
  BG: "Bulgaria",
  BF: "Burkina Faso",
  BI: "Burundi",
  CV: "Cabo Verde",
  KH: "Cambodia",
  CM: "Cameroon",
  CA: "Canada",
  KY: "Cayman Islands ",
  CF: "Central African Republic ",
  TD: "Chad",
  CL: "Chile",
  CN: "China",
  CX: "Christmas Island",
  CC: "Cocos (Keeling) Islands ",
  CO: "Colombia",
  KM: "Comoros ",
  CD: "Congo (the Democratic Republic of the)",
  CG: "Congo ",
  CK: "Cook Islands ",
  CR: "Costa Rica",
  HR: "Croatia",
  CU: "Cuba",
  CW: "Curaçao",
  CY: "Cyprus",
  CZ: "Czechia",
  CI: "Côte d'Ivoire",
  DK: "Denmark",
  DJ: "Djibouti",
  DM: "Dominica",
  DO: "Dominican Republic ",
  EC: "Ecuador",
  EG: "Egypt",
  SV: "El Salvador",
  GQ: "Equatorial Guinea",
  ER: "Eritrea",
  EE: "Estonia",
  SZ: "Eswatini",
  ET: "Ethiopia",
  FK: "Falkland Islands  [Malvinas]",
  FO: "Faroe Islands ",
  FJ: "Fiji",
  FI: "Finland",
  FR: "France",
  GF: "French Guiana",
  PF: "French Polynesia",
  TF: "French Southern Territories ",
  GA: "Gabon",
  GM: "Gambia ",
  GE: "Georgia",
  DE: "Germany",
  GH: "Ghana",
  GI: "Gibraltar",
  GR: "Greece",
  GL: "Greenland",
  GD: "Grenada",
  GP: "Guadeloupe",
  GU: "Guam",
  GT: "Guatemala",
  GG: "Guernsey",
  GN: "Guinea",
  GW: "Guinea-Bissau",
  GY: "Guyana",
  HT: "Haiti",
  HM: "Heard Island and McDonald Islands",
  VA: "Holy See ",
  HN: "Honduras",
  HK: "Hong Kong",
  HU: "Hungary",
  IS: "Iceland",
  IN: "India",
  ID: "Indonesia",
  IR: "Iran (Islamic Republic of)",
  IQ: "Iraq",
  IE: "Ireland",
  IM: "Isle of Man",
  IL: "Israel",
  IT: "Italy",
  JM: "Jamaica",
  JP: "Japan",
  JE: "Jersey",
  JO: "Jordan",
  KZ: "Kazakhstan",
  KE: "Kenya",
  KI: "Kiribati",
  KP: "Korea (the Democratic People's Republic of)",
  KR: "Korea (the Republic of)",
  KW: "Kuwait",
  KG: "Kyrgyzstan",
  LA: "Lao People's Democratic Republic ",
  LV: "Latvia",
  LB: "Lebanon",
  LS: "Lesotho",
  LR: "Liberia",
  LY: "Libya",
  LI: "Liechtenstein",
  LT: "Lithuania",
  LU: "Luxembourg",
  MO: "Macao",
  MG: "Madagascar",
  MW: "Malawi",
  MY: "Malaysia",
  MV: "Maldives",
  ML: "Mali",
  MT: "Malta",
  MH: "Marshall Islands ",
  MQ: "Martinique",
  MR: "Mauritania",
  MU: "Mauritius",
  YT: "Mayotte",
  MX: "Mexico",
  FM: "Micronesia (Federated States of)",
  MD: "Moldova (the Republic of)",
  MC: "Monaco",
  MN: "Mongolia",
  ME: "Montenegro",
  MS: "Montserrat",
  MA: "Morocco",
  MZ: "Mozambique",
  MM: "Myanmar",
  NA: "Namibia",
  NR: "Nauru",
  NP: "Nepal",
  NL: "Netherlands ",
  NC: "New Caledonia",
  NZ: "New Zealand",
  NI: "Nicaragua",
  NE: "Niger ",
  NG: "Nigeria",
  NU: "Niue",
  NF: "Norfolk Island",
  MP: "Northern Mariana Islands ",
  NO: "Norway",
  OM: "Oman",
  PK: "Pakistan",
  PW: "Palau",
  PS: "Palestine, State of",
  PA: "Panama",
  PG: "Papua New Guinea",
  PY: "Paraguay",
  PE: "Peru",
  PH: "Philippines ",
  PN: "Pitcairn",
  PL: "Poland",
  PT: "Portugal",
  PR: "Puerto Rico",
  QA: "Qatar",
  MK: "Republic of North Macedonia",
  RO: "Romania",
  RU: "Russian Federation ",
  RW: "Rwanda",
  RE: "Réunion",
  BL: "Saint Barthélemy",
  SH: "Saint Helena, Ascension and Tristan da Cunha",
  KN: "Saint Kitts and Nevis",
  LC: "Saint Lucia",
  MF: "Saint Martin (French part)",
  PM: "Saint Pierre and Miquelon",
  VC: "Saint Vincent and the Grenadines",
  WS: "Samoa",
  SM: "San Marino",
  ST: "Sao Tome and Principe",
  SA: "Saudi Arabia",
  SN: "Senegal",
  RS: "Serbia",
  SC: "Seychelles",
  SL: "Sierra Leone",
  SG: "Singapore",
  SX: "Sint Maarten (Dutch part)",
  SK: "Slovakia",
  SI: "Slovenia",
  SB: "Solomon Islands",
  SO: "Somalia",
  ZA: "South Africa",
  GS: "South Georgia and the South Sandwich Islands",
  SS: "South Sudan",
  ES: "Spain",
  LK: "Sri Lanka",
  SD: "Sudan ",
  SR: "Suriname",
  SJ: "Svalbard and Jan Mayen",
  SE: "Sweden",
  CH: "Switzerland",
  SY: "Syrian Arab Republic",
  TW: "Taiwan",
  TJ: "Tajikistan",
  TZ: "Tanzania, United Republic of",
  TH: "Thailand",
  TL: "Timor-Leste",
  TG: "Togo",
  TK: "Tokelau",
  TO: "Tonga",
  TT: "Trinidad and Tobago",
  TN: "Tunisia",
  TR: "Turkey",
  TM: "Turkmenistan",
  TC: "Turks and Caicos Islands ",
  TV: "Tuvalu",
  UG: "Uganda",
  UA: "Ukraine",
  UK: "United kingdom",
  AE: "United Arab Emirates ",
  GB: "United Kingdom of Great Britain and Northern Ireland ",
  UM: "United States Minor Outlying Islands ",
  US: "United States of America ",
  UY: "Uruguay",
  UZ: "Uzbekistan",
  VU: "Vanuatu",
  VE: "Venezuela (Bolivarian Republic of)",
  VN: "Viet Nam",
  VG: "Virgin Islands (British)",
  VI: "Virgin Islands (U.S.)",
  WF: "Wallis and Futuna",
  EH: "Western Sahara",
  YE: "Yemen",
  ZM: "Zambia",
  ZW: "Zimbabwe",
  AX: "Åland Islands",
};
export const signatureUpdatedTextForHomeCountries = [
  "FR",
  "IT",
  "PL",
  "CH",
  "UK",
];
export const isValidEmail = (value) => {
  if (!value) return true;
  if (typeof value !== "string") return false;
  return validator.isEmail(value);
};
export const companyHouseFieldsMap = {
  UKA1: {
    employer_name: "",
    employer_uk_address: "",
    employer_uk_address_postcode: "",
    employer_paye_reference: "",
  },
  "UKMSW-A1": {
    employer_1_name: "",
    employer_1_address: "",
    employer_1_address_postcode: "",
    employer_1_paye_reference: "",
  },
};

export const countriesForCountryAndHostCountryType = [
  "Austria",
  "Belgium",
  "Bulgaria",
  "Croatia",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Estonia",
  "Finland",
  "France",
  "Germany",
  "Greece",
  "Hungary",
  "Iceland",
  "Ireland",
  "Italy",
  "Latvia",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Malta",
  "Netherlands",
  "Norway",
  "Poland",
  "Portugal",
  "Romania",
  "Slovakia",
  "Slovenia",
  "Spain",
  "Sweden",
  "Switzerland",
  "United Kingdom",
];
export const FieldType = {
  DATE: "date",
  DATE_DISABLED: "date_disabled",
  DATE_PAST: "date_past",
  DATE_FUTURE: "date_future",
  DATE_OF_BIRTH: "date_of_birth",
  NATIONALITY: "nationality",
  COUNTRY: "country",
  COUNTRY_CODE: "country_code",
  HOST_COUNTRY: "host_country",
  HOST_COUNTRY_CODE: "host_country_code",
  BOOLEAN: "boolean",
  STRING: "string",
  EMAIL: "email",
  USER_EMAIL: "user_email",
  INTEGER: "integer",
  NUMBER: "number",
  ATTACHMENT: "attachment",
  SIGNATURE: "signature",
  OPEN_CHOICE: "open_choice",
  ADD_DESTINATION: "add_destination",
  WORK_PERCENTAGE: "work_percentage",
  PERCENTAGE: "percentage",
  BREAK: "break",
  ASSUMPTION: "Assumption",
};
export const sidebarMenu = [
  {
    id: 1,
    name: "Introduction",
    key: "/",
    icon: ICON_NAMES.INTROLIGHT,
    selectedIcon: ICON_NAMES.INTROSELECTED,
    link: "/",
    nextRoute: "/complete-questionnaire",
    backRoute: "/",
    expand: false,
    children: [],
  },
  {
    id: 2,
    name: "Action required",
    key: "/action-required/action-required",
    icon: ICON_NAMES.ACTIONREQUIREDLIGHT,
    selectedIcon: ICON_NAMES.ACTIONSELECTED,
    link: "/action-required/action-required",
    nextRoute: "",
    backRoute: "",
    expand: false,
    children: [
      {
        id: 21,
        name: "Questionnaire",
        key: "/complete-questionnaire",
        link: "/complete-questionnaire",
        nextRoute: "/sign-power-of-attorney",
        backRoute: "/",
        expand: false,
        children: [],
        onClickClose: true,
      },
      {
        id: 22,
        name: "Power of Attorney",
        key: "/sign-power-of-attorney",
        link: "/sign-power-of-attorney",
        nextRoute: "/file-applications",
        backRoute: "/complete-questionnaire",
        expand: false,
        children: [],
        onClickClose: true,
      },
      // {
      //   id: 23,
      //   name: "File Applications",
      //   key: "/file-applications",
      //   link: "/file-applications",
      //   nextRoute: "/list-view",
      //   backRoute: "/complete-questionnaire",
      //   expand: false,
      // },
    ],
  },
  // {
  // 	id: 3,
  // 	name: 'Completed Applications',
  // 	key: '/action-required/completed-applications',
  // 	// icon: completedLight,
  // 	// selectedIcon: completedSelected,
  // 	icon: map,
  // 	selectedIcon: mapSelected,
  // 	link: '/action-required/completed-applications',
  // 	nextRoute: '',
  // 	backRoute: '',
  // 	expand: false,
  // 	children: [],
  // },
  {
    id: 4,
    name: "Filed applications",
    key: "/applications",
    icon: ICON_NAMES.MAP,
    selectedIcon: ICON_NAMES.MAPSELECTED,
    link: "",
    nextRoute: "",
    backRoute: "",
    expand: false,
    children: [
      {
        id: 41,
        name: "List view",
        key: "/file-applications",
        link: "/file-applications",
        nextRoute: "/map-view",
        backRoute: "/file-applications",
        expand: false,
        onClickClose: true,
      },
      {
        id: 42,
        name: "Analytics view",
        key: "/map-view",
        link: "/map-view",
        nextRoute: "/notifications",
        backRoute: "/list-view",
        expand: false,
      },
    ],
  },
  // {
  //   id: 5,
  //   name: "Settings",
  //   key: "/settings",
  //   icon: settingLight,
  //   selectedIcon: settingSelected,
  //   link: "/settings",
  //   nextRoute: "/",
  //   backRoute: "/map-view",
  //   expand: false,
  //   children: [
  //     {
  //       id: 51,
  //       name: "Notifications",
  //       key: "/notifications",
  //       link: "/notifications",
  //       nextRoute: "/workflow",
  //       backRoute: "/map-view",
  //       expand: false,
  //     },
  //     {
  //       id: 52,
  //       name: "Workflow",
  //       key: "/workflow",
  //       link: "/workflow",
  //       nextRoute: "/",
  //       backRoute: "/notifications",
  //       expand: false,
  //     },
  //   ],
  // },
];
export const Status = {
  COMPLETE: "Complete",
  IN_COMPLETE: "Incomplete",
  PENDING: "Pending",
  EXPIRE: "Expired",
};

export const constData = {
  completeQuestionnaire: [
    {
      country: "The UK",
      entity: "Adidas UK ltd",
      status: Status.COMPLETE,
      contact: "Jane Smith",
      action: "",
    },
    {
      country: "The Czech Republic",
      entity: "Adidas Wholesale lt",
      status: Status.COMPLETE,
      contact: "John Doe",
      action: "",
    },
    {
      country: "The Netherlands",
      entity: "Adidas bv",
      status: Status.IN_COMPLETE,
      contact: "Jonas Berg",
      action: "",
    },
    {
      country: "The Netherlands",
      entity: "Adidas Shoes bv",
      status: Status.COMPLETE,
      contact: "Lisa Alsom",
      action: "",
    },
    {
      country: "Spain",
      entity: "Adidas Espana Sau",
      status: Status.IN_COMPLETE,
      contact: "Maria Figo",
      action: "",
    },
  ],
  fileApplication: [
    {
      firstName: "Benjamin",
      lastName: "Oghene",
      homeCountry: "Spain",
      dateSubmitted: "24/01/2024",
      applicationType: "Standard A1",
      fillingInformation: "Information",
    },
    {
      firstName: "Jane",
      lastName: "Smith",
      homeCountry: "Netherlands",
      dateSubmitted: "21/01/2024",
      applicationType: "Multi-state A1",
      fillingInformation: "Information",
    },
    {
      firstName: "Nadir",
      lastName: "Ali",
      homeCountry: "Spain",
      dateSubmitted: "18/01/2024",
      applicationType: "Standard A1",
      fillingInformation: "Information",
    },
    {
      firstName: "Sabir",
      lastName: "Khan",
      homeCountry: "Netherlands",
      dateSubmitted: "14/01/2024",
      applicationType: "Multi-state A1",
      fillingInformation: "Information",
    },
  ],
};

export const socialLinks = {
  facebookLink: "https://www.facebook.com/cibtcorp/",
  twitterLink: "https://twitter.com/cibtglobal",
  instagramLink: "https://www.instagram.com/cibtcorp/",
  linkedinLink: "https://www.linkedin.com/company/cibt/",
};
export const fileContentType = {
  pdf: "application/pdf",
  xls: "application/vnd.ms-excel",
  xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  doc: "application/msword",
  docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  jpg: "image/jpeg",
  jpeg: "image/jpeg",
  png: "image/png",
  gif: "image/gif",
  txt: "text/plain",
};
export const faqsForA11 = [
  {
    header: "What is an A1 certificate?",
    type: "questtion11",
    answer:
      "An A1 Certificate shows which country's social security rules apply to you. It's used in the European Union (EU) to decide where you should pay your social security contributions.",
  },
  {
    header: "Why do I need an A1 Certificate in the EU?",
    type: "questtion12",
    answer:
      "When you work in an EU country other than your home country, the A1 Certificate makes sure you and your employer only pay social security in one place. This prevents double payments. It also clarifies your rights to things like healthcare and pensions.",
  },
  {
    header: "How does a standard A1 differ from an A1 for multi-state workers?",
    type: "questtion13",
    answer:
      "<ul class='list-disc ml-4'><li key='1'><b><span className='text-black'>Standard A1:</span> </b> For employees sent by their employer to work temporarily in another EU country, e.g., 2 years. It keeps you under your home country's social security during this time.</li><li key='2'><b> <span className='text-black'>A1 for multi-state workers: </span></b> For those who will regularly work across two or more EU countries, e.g., someone with a regional job.</li></ul>",
  },
  {
    header: "What do I need to apply for an A1?",
    type: "questtion14",
    answer:
      "To apply for an A1, you typically need: <br /><ul class='list-disc ml-6 mt-2'><li key='1'>Your personal details (name, birth date, nationality).</li><li key='2'>Job details (employer's name and address, your role).</li><li key='3'>How long and where you'll be working abroad.</li><li key='4'>Any past A1s or applications, if you have them.</li></ul><br />The exact details can change based on your home country.",
  },
  {
    header:
      "How will I complete the payment for the A1 application and can I be reimbursed?",
    type: "questtion15",
    answer:
      "After you have completed the online questionnaire, you will receive a separate email instructing you on how to proceed with the payment using your corporate credit card. This expense is reimbursable via the company. Please retain a copy of the payment receipt to claim it as an expense through your expenses system.",
  },
];
export const faqsForA12 = [
  {
    header: "Do EU countries have different A1 rules? ",
    type: "questtion22",
    answer:
      "Yes, each EU country might have its own rules and steps for the A1 Certificate. It is crucial to know the rules of the country you're going to. Cozm Travel can guide you based on each country's specifics.",
  },
  {
    header: "Where do I get an A1 certificate?",
    type: "questtion23",
    answer:
      "Your home country's social security office gives out A1 certificates. If you fill out our questionnaire, we'll reach out to them for you.",
  },
  {
    header: "How long does an A1 Certificate last?",
    type: "questtion24",
    answer:
      "The A1's length depends on your job type and where it is from. For temporary jobs, it usually lasts as long as the job does, up to a certain limit. For multi-state workers, it varies.",
  },
  {
    header: "What if I don't apply for an A1 certificate?",
    type: "questtion25",
    answer:
      "Without an A1, both you and your employer might end up paying social security in two places. This could mean you're over-insured and might lose out on certain benefits. Some EU countries might even fine you or ban you if you can't show your home country's A1.",
  },
];
export const faqData = {
  header: "Standard Social Security A1",
  type: "A1",
  // faqItem: faqsForA1,
};

export const videodata = [
  {
    link: "https://public-cibt.s3.eu-west-2.amazonaws.com/assets/employer_questionnaire.qt",
    title: `Questionnaire Guide`,
    image:
      "https://public-cibt.s3.eu-west-2.amazonaws.com/assets/complainceImage.png",
    page: "/complete-questionnaire",
  },
  {
    link: "https://public-cibt.s3.eu-west-2.amazonaws.com/assets/employer_poa.qt",
    title: `Power of Attorney Guide`,
    image:
      "https://public-cibt.s3.eu-west-2.amazonaws.com/assets/introductionaryImage.png",
    page: "/sign-power-of-attorney",
  },
  {
    link: "https://public-cibt.s3.eu-west-2.amazonaws.com/assets/employer_portal_intro.mp4",
    title: `Employer Portal Overview`,
    image:
      "https://public-cibt.s3.eu-west-2.amazonaws.com/assets/employer_portal_intro_bg.jpg",
    page: "/",
  },
];
export const CountryColors = {
  COUNTRY1: process.env.REACT_APP_MAP_THEME_COLOR_1 || "#A51E22",
  COUNTRY2: process.env.REACT_APP_MAP_THEME_COLOR_2 || "#1F3543",
  COUNTRY3: process.env.REACT_APP_MAP_THEME_COLOR_3 || "#131313",
  COUNTRY4: process.env.REACT_APP_MAP_THEME_COLOR_4 || "#676867",
  COUNTRY5: process.env.REACT_APP_MAP_THEME_COLOR_5 || "#CCCCCC",
};
export const CardBackgroundColors = {
  CARD1: process.env.REACT_APP_MAP_CARD_COLOR_1 || "",
  CARD2: process.env.REACT_APP_MAP_CARD_COLOR_2 || "",
  CARD3: process.env.REACT_APP_MAP_CARD_COLOR_3 || "",
  CARD4: process.env.REACT_APP_MAP_CARD_COLOR_4 || "",
};
export const EUCountriesLocations = {
  Austria: { latitude: 47.59397, longitude: 14.12456 },
  Belgium: { latitude: 50.6402809, longitude: 4.6667145 },
  Bulgaria: { latitude: 42.6073975, longitude: 25.4856617 },
  Croatia: { latitude: 45.3658443, longitude: 15.6575209 },
  Cyprus: { latitude: 34.9823018, longitude: 33.1451285 },
  "Czech Republic": { latitude: 49.7439047, longitude: 15.3381061 },
  Denmark: { latitude: 55.670249, longitude: 10.3333283 },
  Estonia: { latitude: 58.7523778, longitude: 25.3319078 },
  Finland: { latitude: 63.2467777, longitude: 25.9209164 },
  France: { latitude: 46.603354, longitude: 1.8883335 },
  Germany: { latitude: 51.1638175, longitude: 10.4478313 },
  Greece: { latitude: 38.9953683, longitude: 21.9877132 },
  Hungary: { latitude: 47.1817585, longitude: 19.5060937 },
  Ireland: { latitude: 52.865196, longitude: -7.9794599 },
  Italy: { latitude: 42.6384261, longitude: 12.674297 },
  Latvia: { latitude: 56.8406494, longitude: 24.7537645 },
  Lithuania: { latitude: 55.3500003, longitude: 23.7499997 },
  Luxembourg: { latitude: 49.8158683, longitude: 6.1296751 },
  Malta: { latitude: 35.8885993, longitude: 14.4476911 },
  Netherlands: { latitude: 52.2434979, longitude: 5.6343227 },
  Poland: { latitude: 52.215933, longitude: 19.134422 },
  Portugal: { latitude: 39.6621648, longitude: -8.1353519 },
  Romania: { latitude: 45.9852129, longitude: 24.6859225 },
  Slovakia: { latitude: 48.7411522, longitude: 19.4528646 },
  Slovenia: { latitude: 46.1199444, longitude: 14.8153333 },
  Spain: { latitude: 39.3260685, longitude: -4.8379791 },
  Sweden: { latitude: 59.6749712, longitude: 14.5208584 },
  Iceland: { latitude: 64.9841821, longitude: -18.1059013 },
  Liechtenstein: { latitude: 47.1416307, longitude: 9.5531527 },
  Norway: { latitude: 61.1529386, longitude: 8.7876653 },
  Switzerland: { latitude: 46.7985624, longitude: 8.2319736 },
  "United Kingdom": { latitude: 54.7023545, longitude: -3.2765753 },
};

export const EUCountriesLocations2 = {
  Austria: { latitude: 48.39497, longitude: 14.32556 },
  Belgium: { latitude: 50.9412809, longitude: 4.6677145 },
  Bulgaria: { latitude: 43.6083975, longitude: 25.4866617 },
  Croatia: { latitude: 45.7668443, longitude: 15.6585209 },
  Cyprus: { latitude: 35.9833018, longitude: 33.1461285 },
  "Czech Republic": { latitude: 50.7449047, longitude: 15.3391061 },
  Denmark: { latitude: 56.771249, longitude: 10.5343283 },
  Estonia: { latitude: 58.9533778, longitude: 26.3329078 },
  Finland: { latitude: 64.2477777, longitude: 25.9219164 },
  France: { latitude: 46.604354, longitude: 1.8893335 },
  Germany: { latitude: 52.1648175, longitude: 10.4488313 },
  Greece: { latitude: 39.9963683, longitude: 21.9887132 },
  Hungary: { latitude: 48.1827585, longitude: 19.5070937 },
  Ireland: { latitude: 53.866196, longitude: -7.9784599 },
  Italy: { latitude: 43.6394261, longitude: 12.675297 },
  Latvia: { latitude: 57.8416494, longitude: 24.7547645 },
  Lithuania: { latitude: 56.3510003, longitude: 23.7509997 },
  Luxembourg: { latitude: 49.9868683, longitude: 6.1306751 },
  Malta: { latitude: 36.8895993, longitude: 14.4486911 },
  Netherlands: { latitude: 53.2444979, longitude: 5.6353227 },
  Poland: { latitude: 53.216933, longitude: 19.135422 },
  Portugal: { latitude: 40.6631648, longitude: -8.1343519 },
  Romania: { latitude: 46.9862129, longitude: 24.6869225 },
  Slovakia: { latitude: 49.7421522, longitude: 19.9538646 },
  Slovenia: { latitude: 46.5209444, longitude: 14.8163333 },
  Spain: { latitude: 40.3270685, longitude: -4.8369791 },
  Sweden: { latitude: 60.6759712, longitude: 14.5218584 },
  Iceland: { latitude: 66.9851821, longitude: -18.1069013 },
  Liechtenstein: { latitude: 48.1426307, longitude: 9.5541527 },
  Norway: { latitude: 62.1539386, longitude: 8.7886653 },
  Switzerland: { latitude: 47.7995624, longitude: 8.2329736 },
  "United Kingdom": { latitude: 55.7033545, longitude: -3.2755753 },
};
