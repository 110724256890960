import { apiFetchWrapper } from "../utils/axiosApiWrapper";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_BACK_END_URL;

const getFilledApplications = (url) => {
  const apiUrl = url ? url : API_URL + `compliance/compliance-requests/`;

  return apiFetchWrapper.get(apiUrl, {
    headers: authHeader(),
  });
};
const downloadFilledApplications = (url) => {
  const apiUrl = url ? url : API_URL + `compliance/compliance-requests/export/`;

  return apiFetchWrapper.getFile(apiUrl, true);
};
const getApplicationsStats = (url) => {
  const apiUrl = url ? url : API_URL + `compliance/compliance-requests/stats/`;

  return apiFetchWrapper.get(apiUrl, {
    headers: authHeader(),
  });
};
const getApplicationsSComments = (payload) => {
  let apiUrl =
    API_URL + `compliance/compliance-requests/${payload.uuid}/comments/`;
  if (payload.search) apiUrl += payload.search;

  return apiFetchWrapper.get(apiUrl, {
    headers: authHeader(),
  });
};
const updateApplicationsComments = (payload) => {
  let apiUrl =
    API_URL +
    `compliance/compliance-requests/${payload.applicationUuid}/comments/${payload.commentUuid}/`;

  return apiFetchWrapper.patch(
    apiUrl,
    { comment: payload.comment },
    {
      headers: authHeader(),
    }
  );
};
const AddApplicationsComments = (payload) => {
  let apiUrl =
    API_URL +
    `compliance/compliance-requests/${payload.applicationUuid}/comments/`;

  return apiFetchWrapper.post(
    apiUrl,
    { comment: payload.comment },
    {
      headers: authHeader(),
    }
  );
};
const deleteApplicationsSComments = (payload) => {
  let apiUrl =
    API_URL +
    `compliance/compliance-requests/${payload.applicationUuid}/comments/${payload.commentUuid}/`;

  return apiFetchWrapper.delete(
    apiUrl,
    {
      headers: authHeader(),
    }
  );
};
const filledApplicationsService = {
  getFilledApplications,
  downloadFilledApplications,
  getApplicationsStats,
  getApplicationsSComments,
  updateApplicationsComments,
  deleteApplicationsSComments,
  AddApplicationsComments
};

export default filledApplicationsService;
