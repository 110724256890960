import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import dashBoardService from "../services/dashboard.service";
import { setMessage } from "./message";
import { toast } from "react-toastify";

const initialState = {
  data: [],
  personaEntities: [],
  countriesList: [],
  loading: false,
  singleLoading: false,
  error: null,
  totalPages: 1,
  currentPage: 1,
  isEntityModal: false,
  nextPageUrl: null,
  previousPageUrl: null,
};
export const dashboard = createAsyncThunk(
  "dashboard",
  async ({ status, totalPages, currentPage }, thunkAPI) => {
    try {
      const { data } = await dashBoardService.getAdminBoard({
        totalPages,
        currentPage,
      });
      return {
        dashboard: data.results,
        totalPages: Math.ceil(data.count / 20),
      };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const getPersonaEntities = createAsyncThunk(
  "dashboard/getPersonaEntities",
  async (url, thunkAPI) => {
    try {
      const data = await dashBoardService.getPersonaEntities(url);
      if (data?.data) return data.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const deletePersonaEntities = createAsyncThunk(
  "dashboard/deletePersonaEntities",
  async (url, thunkAPI) => {
    try {
      const data = await dashBoardService.deletePersonaEntities(url);
      if (data?.error) {
        toast.error(data?.message || "Error processing this request");
      }
      if (data?.data) return data.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const addPersonaEntities = createAsyncThunk(
  "dashboard/addPersonaEntities",
  async ({ name, contact, country }, thunkAPI) => {
    try {
      const data = await dashBoardService.addPersonaEntities({
        name,
        contact,
        country,
      });
      if (data?.data) return data.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const updatePersonaEntities = createAsyncThunk(
  "dashboard/updatePersonaEntities",
  async ({ name, contact, country, uuid }, thunkAPI) => {
    try {
      const data = await dashBoardService.updatePersonaEntities({
        name,
        uuid,
      });
      if (data?.data) return data.data;
      else throw new Error("message");
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const getCountriesList = createAsyncThunk(
  "dashboard/getCountriesList",
  async (data, thunkAPI) => {
    try {
      const data = await dashBoardService.getCountryList();
      if (data?.data) return data.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  extraReducers: {
    [getPersonaEntities.pending]: (state, action) => {
      state.loading = true;
    },
    [getPersonaEntities.fulfilled]: (state, action) => {
      state.personaEntities = action.payload.results;
      state.nextPageUrl = action.payload.next;
      state.previousPageUrl = action.payload.previous;
      state.loading = false;
    },
    [getPersonaEntities.rejected]: (state, action) => {
      state.loading = false;
    },
    [deletePersonaEntities.pending]: (state, action) => {
      state.loading = true;
    },
    [deletePersonaEntities.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [deletePersonaEntities.rejected]: (state, action) => {
      state.loading = false;
    },
    [getCountriesList.pending]: (state, action) => {
      state.loading = true;
    },
    [getCountriesList.fulfilled]: (state, action) => {
      state.countriesList = action.payload;
      state.loading = false;
    },
    [getCountriesList.rejected]: (state, action) => {
      state.loading = false;
    },
    [addPersonaEntities.pending]: (state, action) => {
      state.singleLoading = true;
    },
    [addPersonaEntities.fulfilled]: (state, action) => {
      const data = [...state.personaEntities];
      data.push(action.payload);
      state.personaEntities = data;
      state.singleLoading = false;
    },
    [addPersonaEntities.rejected]: (state, action) => {
      state.singleLoading = false;
    },
    [updatePersonaEntities.pending]: (state, action) => {
      state.loading = true;
    },
    [updatePersonaEntities.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [updatePersonaEntities.rejected]: (state, action) => {
      state.loading = false;
    },

    [dashboard.fulfilled]: (state, action) => {
      state.data = action.payload.dashboard;
      state.totalPages = action.payload.totalPages;
    },
    [dashboard.rejected]: (state, action) => {
      state.data = [];
    },
  },
  reducers: {
    setCurrentPage: (state, action) => {
      return { ...state, currentPage: action.payload.currentPage };
    },
    setModalHandler: (state, action) => {
      return { ...state, isEntityModal: action.payload };
    },
    clearData: () => {
      return { data: [] };
    },
  },
});

const { reducer, actions } = dashboardSlice;

export const { setCurrentPage, clearData, setModalHandler } = actions;
export default reducer;
