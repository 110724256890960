import React from "react";
import { useNavigate } from "react-router-dom";
import { NormalText } from "../../components/common";
import { useDispatch } from "react-redux";

export const Introduction = ({ isChecked, setIsChecked }) => {
  const themeColor = process.env.REACT_APP_LIGHT_PRIMARY;
  const hanldeAcceptTerms = (e) => {
    const isChecked = e.target.checked;

    if (isChecked) {
      localStorage.setItem("termsofservices", "accepted");
      setIsChecked(true);
    }

    if (!isChecked) {
      localStorage.removeItem("termsofservices");
      setIsChecked(false);
    }
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();
  return (
    <div className="container-fluid px-5 py-5 auto-scroll-y">
      <NormalText>
        <b>Complete compliance questionnaires</b>
      </NormalText>
      <p>
        <NormalText color={"grey"}>
          Initiate your compliance filings by completing the necessary{" "}
          <span
            style={{ color: themeColor }}
            onClick={() => {
              // dispatch(layout(false));
              navigate("/complete-questionnaire");
            }}
            className="text-decoration-underline cursor-pointer"
          >
            employer questionnaires
          </span>
          , including{" "}
          <span
            style={{ color: themeColor }}
            onClick={() => {
              // dispatch(layout(false));
              navigate("/sign-power-of-attorney");
            }}
            className=" text-decoration-underline cursor-pointer"
          >
            power of attorney
          </span>{" "}
          documentation.
        </NormalText>
      </p>
      <NormalText>
        <b>Check filing status</b>
      </NormalText>
      <p>
        <NormalText color={"grey"}>
          Monitor the current{" "}
          <span
            style={{ color: themeColor }}
            onClick={() => {
              // dispatch(layout(false));
              navigate("/file-applications");
            }}
            className=" text-decoration-underline cursor-pointer"
          >
            status of compliance filings.
          </span>
        </NormalText>
      </p>
      <p>
        <div className="d-flex align-items-center gap-2">
          <input
            id="termsAndService"
            type="checkbox"
            checked={isChecked}
            onChange={hanldeAcceptTerms}
            style={{
              accentColor: themeColor,
              width: "20px",
              height: "20px",
            }}
          />

          <NormalText color={"grey"}>
            <label for={"termsAndService"}>I agree to the </label>{" "}
            <NormalText
              onClick={() => navigate("/terms")}
              color={"theme"}
              textDecoration="underline"
              cursor="pointer"
            >
              Terms of Use & Service
            </NormalText>
          </NormalText>
        </div>
      </p>
    </div>
  );
};
