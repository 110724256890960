import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { sidebarMenu } from "../common/constants";

const initialState = {
  sideBarHide: true,
  globalLoading: false,
  routes: sidebarMenu,
};
export const layout = createAsyncThunk("layout", async (toggleValue) => {
  try {
    return toggleValue;
  } catch (error) {
    console.log({ error });
    return false;
  }
});
export const handleGlobalLoading = createAsyncThunk(
  "globalLoading",
  async (loadingValue) => {
    try {
      return loadingValue;
    } catch (error) {
      console.log({ error });
      return false;
    }
  }
);
const layoutSlice = createSlice({
  name: "layout",
  initialState,
  extraReducers: {
    [layout.fulfilled]: (state, action) => {
      state.sideBarHide = action.payload;
    },
    [handleGlobalLoading.fulfilled]: (state, action) => {
      state.globalLoading = action.payload;
    },
  },
  reducers: {
    onExpandAndClose: (state, action) => {
      const { id } = action.payload;
      state.routes = state.routes.map((route) => {
        if (route.id === id) {
          route.expand = !route.expand;
        } else {
          route.expand = false;
        }
        return route;
      });
    },
    closeAll: (state, action) => {
      state.routes = state.routes.map((route) => {
        route.expand = false;
        return route;
      });
    },
  },
});

const { reducer, actions } = layoutSlice;
export const { onExpandAndClose, closeAll } = actions;
export const layOutRoutes = (state) => state.layout.routes;

export default reducer;
