import JSZip from "jszip";

export const downloadAllFiles = async (filesArray, nameArray, zipFilename) => {
  const zip = new JSZip();
  const promises = filesArray.map(async (url, key) => {
    try {
      const response = await fetch(url);
      const data = await response.blob();
      zip.file(`${nameArray[key]}.pdf`, data);
    } catch (error) {
      console.error(`Failed to fetch ${url}: ${error.message}`);
    }
  });

  await Promise.all(promises)
    .then(() => {
      zip.generateAsync({ type: "blob" }).then((content) => {
        const url = URL.createObjectURL(content);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${zipFilename}.zip`;
        a.click();
        URL.revokeObjectURL(url);
      });
    })
    .catch((error) => {
      console.error("Error creating ZIP archive:", error);
    });
};

export const singleFileDownload = (fileUrl) => {
  window.open(fileUrl, "_blank");
};
