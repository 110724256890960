import React, { createContext, useState, useContext } from "react";

// Create the context
const FilterContext = createContext();

// Create a provider component
export const FilterProvider = ({ children }) => {
  // Define state that you want to share
  const [filterData, setFilterData] = useState({
    compliance_type: "",
    persona: "",
    status: "",
    home_country: "",
    host_country: "",
    created_after: "",
    created_before: "",
  });

  return (
    <FilterContext.Provider value={{ filterData, setFilterData }}>
      {children}
    </FilterContext.Provider>
  );
};

// Custom hook to use the FilterContext
export const useMapFilter = () => useContext(FilterContext);

export default FilterContext;
