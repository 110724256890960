import map from "lodash/map";
import { createRef } from "react";
import { useSelector } from "react-redux";

import { FormFieldGenerator } from ".";
import { FieldType } from "../../../../common/constants";
import { FormField } from "./FormFields";

// import { TitleText } from "../../../../../components/TitleText";
export const FormFieldWrapper = (props) => {
  const {
    index,
    field,
    values,
    errors,
    countryObject,
    countryObjectValues,
    setFieldValue,
    updateFieldVisibleRef,
    employerDetail,
    selectedGroupName,
    scrollRefs,
  } = props;

  return (
    <>
      <div
        ref={
          scrollRefs && Array.isArray(scrollRefs.current)
            ? scrollRefs.current[index]
            : createRef()
        }
        className="col-sm-4 py-4"
      >
        <FormField {...props} />
      </div>
      {field?.type === FieldType.ADD_DESTINATION && values[field.name] && (
        <div key={`field-details-${index}-${field.name}`}>
          <div restStyle="flex sm:mt-3 max-sm:mt-8">
            {employerDetail?.hasOwnProperty(field.name) ? (
              <div className="w-[100%] h-[100%] bg-red-100 border border-lightPrimary"></div>
            ) : (
              `${field?.description
                ?.charAt(0)
                ?.toUpperCase()}${field?.description?.slice(1)}`
            )}
          </div>

          {!employerDetail?.hasOwnProperty(field.name) && (
            <div>
              <FormField {...props} />
            </div>
          )}
        </div>
      )}

      {map(field.conditional_fields, (conditionalField, conditionalIndex) => {
        if (conditionalField.parent_value === values[field.name])
          return (
            <FormFieldGenerator
              key={`field-${conditionalIndex}-${conditionalField.name}`}
              fields={[conditionalField]}
              errors={errors}
              values={values}
              setFieldValue={setFieldValue}
              countryObject={countryObject}
              countryObjectValues={countryObjectValues}
              selectedGroupName={selectedGroupName}
              updateFieldVisibleRef={updateFieldVisibleRef}
              employerDetail={employerDetail}
            />
          );
      })}
    </>
  );
};
