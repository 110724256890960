import React from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { layout } from "../slices/layout";
import { logoutUser } from "../utils/handleSSO";
function Header({ currentUser, logOut, sideBarHide }) {
  const themeDisabledColor = process.env.REACT_APP_LIGHT_PRIMARY_DISABLE;
  const themeColor = process.env.REACT_APP_LIGHT_PRIMARY;

  const dispatch = useDispatch();

  let navigate = useNavigate();
  const logoutHandler = async () => {
    await logoutUser();
  };
  return (
    <header>
      <nav
        style={{ paddingBottom: "0px", paddingTop: "0px" }}
        className="navbar navbar-expand justify-content-between"
      >
        <div className="navbar-nav mr-auto align-items-center">
          {!sideBarHide ? (
            <li>
              <a
                className="btn ms-0 my-0 me-3"
                style={{
                  backgroundColor: `${themeDisabledColor}`,
                  width: "42px",
                  height: "42px",
                  borderRadius: "50%",
                  margin: "10px 20px 20px",
                  display: "flex",
                  justifyContent: "center",
                }}
                onClick={() => {
                  dispatch(layout(!sideBarHide));
                }}
                // data-bs-toggle="offcanvas"
                // href="#offcanvasExample"
                role="button"
                aria-controls="offcanvasExample"
              >
                <span
                  style={{
                    color: process.env.REACT_APP_THEME_OR_WITHOUT_THEME,
                  }}
                  className="bi-list"
                ></span>
              </a>
            </li>
          ) : null}
          <li>
            <div
              onClick={() => {
                navigate("/");
              }}
              style={{ fontSize: "25px", cursor: "pointer" }}
              className="d-flex justify-content-start align-items-center"
            >
              <img
                className="logo-dark"
                width={300}
                src={process.env.REACT_APP_PROJECT_LOGO_URL}
                alt="logo"
              />
              {/* <span
                style={{
                  color: themeColor,
                  fontSize: "1.4rem",
                  paddingTop: "5px",
                }}
              >
                Employer
              </span> */}
            </div>
          </li>
        </div>
        {currentUser ? (
          <div className="navbar-nav ml-auto">
            <li className="nav-item">
              <Link to={"/"} className="nav-link">
                {currentUser.username}
              </Link>
            </li>
            <li className="desktop-logout nav-item">
              <button
                type="button"
                className="btn  px-5"
                style={{ color: themeColor, borderColor: themeColor }}
                onClick={logoutHandler}
              >
                Logout
              </button>
            </li>
          </div>
        ) : (
          <div className="navbar-nav ml-auto">
            <li className="nav-item">
              <Link to={"/login"} className="nav-link">
                Login
              </Link>
            </li>

            <li className="nav-item">
              <Link to={"/register"} className="nav-link">
                Sign Up
              </Link>
            </li>
          </div>
        )}
      </nav>
    </header>
  );
}

export default Header;
