import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { dashboard, setCurrentPage } from "../slices/dashboard";
import { Link } from "react-router-dom";
import { countryList } from "../common/constants";
import Layout from "./Layout";
import { exportToExcel } from "../utils/exportToExcel";
import { downloadAllFiles, singleFileDownload } from "../utils/downloadZip";
import { handleGlobalLoading } from "../slices/layout";
const Home = () => {
  const STATUS = useMemo(() => {
    return {
      "completed-applications": "PROCESSED",
      "action-required": "ACTION_REQUIRED",
    };
  }, []);
  const ApplicationType = {
    A1: "Standard A1",
    "MSW-A1": "Multi-state A1",
  };
  const {
    data: content,
    currentPage,
    totalPages,
  } = useSelector((state) => state.dashboard);
  const { globalLoading } = useSelector((state) => state.layout);
  const [filterResult, setFilterResult] = useState([]);
  //fileName for each file name
  const [selectedFiles, setSelectedFiles] = useState({
    file_Name: [],
    url: [],
    dataTobeExport: [],
  });
  const dispatch = useDispatch();
  const { status } = useParams();

  const filterContent = (search) => {
    const list = Object.keys(countryList).filter((key) =>
      countryList[key].toLowerCase().includes(search.toLowerCase())
    );

    const filterResult = content.filter(
      (item) =>
        list.includes(item.home_country) && item.status === STATUS[status]
    );
    setFilterResult(filterResult);
  };
  const handleSearch = (e) => {
    filterContent(e.target.value);
  };
  useEffect(() => {
    dispatch(handleGlobalLoading(true));
    dispatch(dashboard({ status: STATUS[status], currentPage })).then(() => {
      dispatch(handleGlobalLoading(false));
    });
  }, [currentPage, STATUS, dispatch, status]);

  useEffect(() => {
    if (content) {
      const filter = content.filter((item) => item.status === STATUS[status]);
      setFilterResult(filter);
    }
  }, [status, STATUS, content]);
  const exportData = () => {
    const dataArray = selectedFiles.dataTobeExport.map((item) => {
      const date = new Date(item?.created_at);
      return {
        file_Name: item.uuid,
        First_Name: item.employee?.first_name,
        Last_Name: item.employee?.last_name,
        Home_Country: countryList[item.home_country],
        Date_Submitted: date.toLocaleDateString("en-GB"),
        Application_Type: item.compliance_type,
      };
    });
    exportToExcel(dataArray, "Completed Applications");
  };
  const PaginatedDataComponent = ({ data }) => {
    return <>{data.map((item) => renderRow(item))}</>;
  };

  const handleDownloadFile = async () => {
    if (selectedFiles?.url?.length === 1) {
      singleFileDownload(selectedFiles?.url[0]);
    } else {
      dispatch(handleGlobalLoading(true));
      await downloadAllFiles(
        selectedFiles?.url,
        selectedFiles.file_Name,
        "A1-Applications"
      );
      dispatch(handleGlobalLoading(false));
    }
  };

  const handleSelectFiles = (url, fileName, data) => {
    if (selectedFiles?.url.includes(url)) {
      handleSetFiles(
        selectedFiles.url.filter((fileUrl) => fileUrl !== url),
        selectedFiles.file_Name.filter((file_Name) => file_Name !== fileName),
        selectedFiles.dataTobeExport.filter((item) => item.uuid !== data.uuid)
      );
    } else {
      handleSetFiles(
        [...selectedFiles.url, url],
        [...selectedFiles.file_Name, fileName],
        [...selectedFiles.dataTobeExport, data]
      );
    }
  };

  const handleSelectAll = (e) => {
    if (e?.target?.checked) {
      const urlArray = filterResult.map(
        (data) => data.reference_details.file_url
      );
      const fileNameArray = filterResult.map((data) => data.uuid);

      handleSetFiles(urlArray, fileNameArray, filterResult);
    } else {
      handleSetFilesEmpty();
    }
  };

  const handleSetFilesEmpty = () => {
    setSelectedFiles({
      url: [],
      file_Name: [],
      dataTobeExport: [],
    });
  };
  const handleSetFiles = (urlArray, fileNameArray, dataArray) => {
    setSelectedFiles({
      url: urlArray,
      file_Name: fileNameArray,
      dataTobeExport: dataArray,
    });
  };
  const renderRow = (data) => {
    const date = new Date(data?.created_at);
    return (
      <tr key={data.uuid}>
        <td>{data.employee?.first_name}</td>
        <td>{data.employee?.last_name}</td>

        <td>{countryList[data.home_country]}</td>

        <td>{date.toLocaleDateString("en-GB")}</td>
        <td>
          <Link href="#" className="text-danger">
            {ApplicationType[data.compliance_type]}
          </Link>
        </td>
        <td>
          <Link href="#" className="text-danger">
            Information
          </Link>
        </td>
        <td className="text-center">
          <div className="form-check download-all d-flex justify-content-center">
            <input
              className="form-check-input"
              type="checkbox"
              checked={selectedFiles?.url.includes(
                data.reference_details.file_url
              )}
              onChange={() => {
                handleSelectFiles(
                  data.reference_details.file_url,
                  data.employee?.first_name +
                    data.employee?.last_name +
                    countryList[data.home_country],
                  data
                );
              }}
              id="flexCheckDefault"
            />
          </div>
        </td>
      </tr>
    );
  };

  return (
    <Layout>
      <main>
        <section className="listing-section">
          <div className="container-fluid">
            <div className="col-md-12">
              <h2>
                {status === "completed-applications"
                  ? "Completed applications"
                  : "Action required"}
              </h2>
              <p>
                {status !== "completed-applications" ? (
                  <>
                    Below is a list of compliance applications which are ready
                    for your review. Please download the applications and filing
                    information.
                    <br />
                    <br /> Should you have any questions, please contact us at{" "}
                  </>
                ) : (
                  <>
                    Below is a list of A1 social security applications which
                    have been filed. We have also included the relevant filing
                    information which were issued along with a copy of the A1
                    application.
                    <br />
                    If you have any questions, please contact us at{" "}
                  </>
                )}
                <Link
                  href="#"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                >
                  info@cibt.com
                </Link>
                .
              </p>
              <form className="search-filter">
                <div className="row mt-4 mb-2">
                  <div className="col-sm-6 col-md-4 col-xl-3">
                    <div className="form-group position-relative mb-3">
                      <label className="form-label">Home country</label>
                      <input
                        onChange={(e) => handleSearch(e)}
                        className="form-control"
                        placeholder="Search here"
                      />
                      <i className="bi bi-search"></i>
                    </div>
                  </div>
                  {/* <div className="col-sm-6 col-md-4 col-xl-3">
                    <div className="form-group position-relative mb-3">
                      <label className="form-label">Host Country</label>
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Search here"
                      />
                      <i className="bi bi-search"></i>
                    </div>
                  </div> */}
                  <div></div>
                </div>
              </form>
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th>First name</th>
                      <th>Last nationalityame</th>
                      <th>Home country</th>
                      <th>Date submitted</th>
                      <th>Application type</th>
                      <th>Filing information</th>
                      {filterResult?.length ? (
                        <th>
                          <div className="d-flex justify-content-center">
                            <span className="label pt-1 pe-1">Select all</span>
                            <div className="form-check download-all">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                onChange={handleSelectAll}
                                id="flexCheckDefault"
                                checked={
                                  selectedFiles?.url?.length &&
                                  selectedFiles?.url?.length ===
                                    filterResult?.length
                                }
                              />
                            </div>
                          </div>
                        </th>
                      ) : null}
                    </tr>
                  </thead>
                  <tbody>
                    {filterResult.length ? (
                      <PaginatedDataComponent
                        data={filterResult}
                        currentPage={currentPage}
                        totalPages={totalPages}
                      />
                    ) : (
                      <tr>
                        <td>
                          {status === "completed-applications"
                            ? "No completed applications"
                            : " No actions required"}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <hr className="mt-5" />
            </div>
          </div>{" "}
          {filterResult?.length ? (
            <div className="text-end position-fixed action-btn">
              {currentPage !== 1 && (
                <button
                  onClick={() => {
                    dispatch(setCurrentPage({ currentPage: currentPage - 1 }));
                    handleSetFilesEmpty();
                  }}
                  className="btn btn-outline-danger me-2"
                  disabled={currentPage === 1}
                >
                  {" "}
                  Previous{" "}
                </button>
              )}

              <button
                className="btn btn-outline-danger me-2"
                disabled={!selectedFiles.dataTobeExport?.length}
                onClick={() => exportData()}
              >
                Export to CSV
              </button>
              <button
                className="btn btn-danger me-2"
                onClick={() => handleDownloadFile()}
                disabled={!selectedFiles?.url?.length}
              >
                {globalLoading && (
                  <span className="spinner-border spinner-border-sm me-1"></span>
                )}
                Download
              </button>
              {currentPage !== totalPages && (
                <button
                  className="btn btn-danger"
                  disabled={currentPage === totalPages}
                  onClick={() => {
                    dispatch(setCurrentPage({ currentPage: currentPage + 1 }));
                    handleSetFilesEmpty();
                  }}
                >
                  Next{" "}
                </button>
              )}

              <span className="ms-2">
                {currentPage}/{totalPages}
              </span>
            </div>
          ) : null}
          <div
            className="modal fade"
            id="exampleModal"
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-lg modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-body">
                  <h3>Dear Sir/ Madam</h3>
                  <p>
                    This is a quick message to let you know there are some
                    actions required with respect to mobile employees. Please
                    click on
                    <u> this link</u> to access CIBT’s A1 app. It includes
                    details of A1 applications to be completed along with filing
                    information.
                  </p>
                  <p>
                    Should you have any questions, please contact
                    <a href="mailto:info@cibt.com"> info@cibt.com</a>.
                  </p>
                  <div className="text-end mt-250">
                    <button
                      type="button"
                      className="btn btn-danger px-5"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </Layout>
  );
};

export default Home;
