import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import showPasswordIcone from "../assets/images/show_password.svg";
import hidePasswordIcone from "../assets/images/hide_password.svg";

import * as Yup from "yup";

import { login } from "../slices/auth";
import { clearMessage } from "../slices/message";
import { apiFetchWrapper } from "../utils/axiosApiWrapper";

const Login = () => {
  let navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [isOtp, setIsOtp] = useState(false);

  const { isLoggedIn } = useSelector((state) => state.auth);
  const { message } = useSelector((state) => state.message);
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearMessage());
  }, [dispatch]);

  const initialValues = {
    username: "",
    password: "",
  };

  const validationSchema = Yup.object().shape({
    username: Yup.string().required("This field is required!"),
    password: Yup.string().required("This field is required!"),
  });

  const handleLogin = async (formValue) => {
    const { username, password, otp } = formValue;
    setLoading(true);
    dispatch(clearMessage());

    const res = await apiFetchWrapper.post("tenants/tenant-version-info/", {
      email: username,
    });
    if (res && !res.error) {
      const tenant = res.data.tenant;
      if (tenant) localStorage.setItem("tenant", tenant);
    }
    let payload = { email: username, password };
    if (otp) payload.otp_token = otp;
    dispatch(login(payload))
      .unwrap()
      .then((res) => {
        setLoading(false);
        if (res.user.sendOtp) {
          setIsOtp(true);
        } else {
          navigate("/");
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  if (isLoggedIn) {
    return <Navigate to="/" />;
  }
  const CustomInputComponent = ({ className, ...props }) => {
    return (
      <div {...props} className={className}>
        <input
          {...props}
          style={{ border: "0px", outline: "none", padding: "0px" }}
          type={`${showPassword ? "text" : "password"}`}
        />
        <div
          className="cursor-pointer"
          onClick={() => {
            setShowPassword(!showPassword);
          }}
        >
          {showPassword ? (
            <img width={25} src={showPasswordIcone} alt="showPassword" />
          ) : (
            <img width={25} src={hidePasswordIcone} alt="hidePasswordIcone" />
          )}
        </div>
      </div>
    );
  };
  return (
    <section className="login-section">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-12">
            <div className="card">
              <div className="card-body text-center">
                <img
                  src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
                  alt="profile-img"
                  className="profile-img-card"
                />
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={handleLogin}
                >
                  <Form>
                    {!isOtp ? (
                      <>
                        <div className="form-group">
                          <label htmlFor="username">Username</label>
                          <Field
                            name="username"
                            type="text"
                            className="form-control"
                          />
                          <ErrorMessage
                            name="username"
                            component="div"
                            className="alert alert-danger"
                          />
                        </div>

                        <div className="form-group">
                          <label htmlFor="password">Password</label>

                          <Field
                            name="password"
                            type={`${showPassword ? "text" : "text"}`}
                            className="form-control d-flex justify-content-between"
                            as={CustomInputComponent}
                            placeholder="password"
                          />
                          <ErrorMessage
                            name="password"
                            component="div"
                            className="alert alert-danger"
                          />
                        </div>
                      </>
                    ) : (
                      <div className="form-group">
                        <p>
                          We had send you an email with an OTP. Please confirm.
                        </p>
                        <label htmlFor="otp">OTP Code</label>
                        <Field
                          name="otp"
                          type="string"
                          className="form-control"
                        />
                        <ErrorMessage
                          name="otp"
                          component="div"
                          className="alert alert-danger"
                        />
                      </div>
                    )}

                    <div className="form-group">
                      <button
                        type="submit"
                        className="btn btn-danger btn-block mt-4"
                        disabled={loading}
                      >
                        {loading && (
                          <span className="spinner-border spinner-border-sm me-1"></span>
                        )}
                        <span>Login</span>
                      </button>
                    </div>
                  </Form>
                </Formik>
              </div>
            </div>
          </div>
        </div>
        {message && (
          <div className="form-group">
            <div className="alert alert-danger" role="alert">
              {message}
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default Login;
