import useCountryList from "../../../assets/shared/hooks/useCountryList";
import { FormFieldGenerator } from "./FormFieldGenerator";
import { TitleText } from "../../TitleText";

export const GroupContainer = (props) => {
  const {
    fields,
    errors,
    selectedGroupName,
    values,
    employerDetail,
    setFieldValue,
    updateFieldVisibleRef,
    scrollRefs,
    prefilledNames,
    personaName,
    countryName,
    manualFilledFields,
  } = props;
  const { countryObjectValues, countryObject } = useCountryList();
  return (
    <>
      {fields?.length > 0 && (
        <div className="px-4 custom-div" style={{ paddingBottom: 70 }}>
          <div className=" d-flex justify-content-between mb-3">
            <TitleText
              style={{
                letterSpacing: "0.025rem",
                color: "rgb(30 53 66)",
                fontWeight: "600",
                lineHeight: "18px",
              }}
              children={selectedGroupName}
            />
            <div>
              <TitleText
                style={{
                  letterSpacing: "0.025rem",
                  color: "rgb(30 53 66)",
                  fontWeight: "600",
                  lineHeight: "18px",
                }}
                children={"Key"}
              />
              <TitleText
                style={{
                  letterSpacing: "0.025rem",
                  color: "rgb(30 53 66)",
                  fontWeight: "600",
                  lineHeight: "18px",
                }}
                restStyle="prefilled-color"
                children={"auto-populated"}
              />
              <TitleText
                style={{
                  letterSpacing: "0.025rem",
                  color: "rgb(30 53 66)",
                  fontWeight: "600",
                  lineHeight: "18px",
                }}
                children={"manual entry"}
              />
              <TitleText
                style={{
                  letterSpacing: "0.025rem",
                  color: "#a51e22",
                  fontWeight: "600",
                  lineHeight: "18px",
                }}
                children={"missing information"}
              />
            </div>
          </div>
          <div
            className="container"
            style={{
              width: "300px",
              marginLeft: "0px",
            }}
          >
            <div className="row">
              <div className="col">
                {" "}
                <TitleText
                  style={{
                    letterSpacing: "0.025rem",
                    fontWeight: "600",
                    lineHeight: "18px",
                  }}
                >
                  Country Name:{" "}
                </TitleText>
              </div>
              <div className="col">
                {" "}
                <TitleText
                  style={{
                    letterSpacing: "0.025rem",
                    fontWeight: "600",
                    lineHeight: "18px",
                    paddingLeft: "10px",
                  }}
                >
                  {" "}
                  {countryName}
                </TitleText>
              </div>
            </div>
            <div className="row my-2">
              <div className="col">
                <TitleText
                  style={{
                    letterSpacing: "0.025rem",
                    fontWeight: "600",
                    lineHeight: "18px",
                  }}
                >
                  Entity:
                </TitleText>
              </div>
              <div className="col">
                {" "}
                <TitleText
                  style={{
                    letterSpacing: "0.025rem",
                    fontWeight: "600",
                    lineHeight: "18px",
                    paddingLeft: "10px",
                  }}
                >
                  {personaName}
                </TitleText>
              </div>
            </div>
          </div>

          <div className="row">
            <FormFieldGenerator
              scrollRefs={scrollRefs}
              fields={fields}
              errors={errors}
              values={values}
              setFieldValue={setFieldValue}
              countryObject={countryObject}
              countryObjectValues={countryObjectValues}
              selectedGroupName={selectedGroupName}
              updateFieldVisibleRef={updateFieldVisibleRef}
              employerDetail={employerDetail}
              prefilledNames={prefilledNames}
              manualFilledFields={manualFilledFields}
            />
          </div>
        </div>
      )}
    </>
  );
};
