import React from "react";
import Select from "react-select";
import dropDown from "../../../assets/images/dropdown.svg";
import close from "../../../assets/images/close.svg";
import { HelpText } from "../HelpText";
export const SelectBox = ({
  label = "Select Country",
  onChange = () => {},
  isRequired = true,
  options,
  value,
  showIcons,
}) => {
  const themeColor = process.env.REACT_APP_LIGHT_PRIMARY;

  const selectBoxoptions = options
    ? options
    : [
        {
          value: "chocolate",
          label: "Chocolate",
        },
        { value: "strawberry", label: "Strawberry" },
        { value: "vanilla", label: "Vanilla" },
      ];



  return (
    <div
      className="h-100 d-flex flex-column gap-1 justify-content-between"
      style={{
        borderBottom: "2px solid #ced4da",
      }}
    >
      <label
        style={{
          color: "#1E3542",
          fontSize: "17px",
          fontStyle: "normal",
          fontWeight: "500",
          lineHeight: "18px",
        }}
      >
        {label}
        {isRequired && <span style={{ color: themeColor }}>*</span>}
      </label>
      <Select
        isSearchable
        isClearable
        value={selectBoxoptions.find((_item) => _item.value === value)}
        defaultValue={selectBoxoptions.find((_item) => _item.value === value)}
        onChange={onChange}
        options={selectBoxoptions}
        components={{
          ClearIndicator: ({ clearValue }) => {
            return (
              showIcons !== false && (
                <img
                  onClick={() => clearValue()}
                  className="bi-close"
                  src={close}
                  alt="close"
                  height={16}
                  width={16}
                  style={{
                    marginRight: "10px",
                    marginTop: "2px",
                  }}
                />
              )
            );
          },
          IndicatorSeparator: () => null,
          DropdownIndicator: ({ innerProps }) => (
            <div className="d-flex align-items-center gap-2" {...innerProps}>
              <img src={dropDown} alt="dropdown" height={16} width={16} />
              {showIcons !== false && (
                <HelpText helpText="Select box help text here!" />
              )}
            </div>
          ),
        }}
        styles={{
          option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
              ...styles,
              backgroundColor: isSelected ? "#D2DBE3" : "white",
              borderRadius: isSelected ? "8px" : "0px",
              color: "black",
              "&:hover": {
                backgroundColor: "#DEEBFF",
              },
            };
          },
          control: (base, state) => ({
            ...base,
            border: state.isFocused ? 0 : 0,
            // This line disable the blue border
            boxShadow: state.isFocused ? 0 : 0,
            "&:hover": {
              border: state.isFocused ? 0 : 0,
            },
          }),
          valueContainer: (base, state) => ({
            ...base,
            padding: "0px",
          }),
        }}
        theme={(_theme) => ({
          ..._theme,
          colors: {
            ..._theme.colors,
            primary: `white`,
          },
        })}
      />
    </div>
  );
};
