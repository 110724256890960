import React, { Fragment, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  layOutRoutes,
  layout,
  onExpandAndClose as onExpAndCls,
  closeAll as onClsAll,
} from "../slices/layout";
import { useDispatch, useSelector } from "react-redux";

import { VideoModal } from "./common/videoModal";
import playLogo from "../assets/images/play.svg";
import { videodata } from "../common/constants";
import Icon, { ICON_NAMES } from "./Icone";
import { logoutUser } from "../utils/handleSSO";

function Sidebar(props) {
  const themeSidebarColor = process.env.REACT_APP_SIDEBAR_BACKGROUND;
  const themeColor = process.env.REACT_APP_LIGHT_PRIMARY;
  const themeSecondColor = process.env.REACT_APP_SIDEBAR_SELECTED;
  const themeDisabledColor = process.env.REACT_APP_LIGHT_PRIMARY_DISABLE;
  const [isVideoMinimize, setIsVideoMinimize] = useState(false);
  const [isVideoPlay, setIsVideoPlay] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const routes = useSelector(layOutRoutes);
  const location = useLocation();
  const pathname = location.pathname;
  const onExpandAndClose = (id) => dispatch(onExpAndCls({ id }));
  const closeAll = () => dispatch(onClsAll({}));
  React.useEffect(() => {
    dispatch(closeAll());
    const route =
      routes.find((i) => i.link === pathname) ||
      routes.find((i) => i.children.find((j) => j.link === pathname));
    if (route) {
      dispatch(onExpAndCls({ id: route.id }));
    }
  }, [dispatch]);

  const logoutHandler = async () => {
    await logoutUser();
  };

  return (
    <aside className="sidebar">
      <div
        className={`${
          props.sideBarHide ? "sidebar-show" : "sidebar-hidden"
        } offcanvas offcanvas-start`}
        tabIndex={-1}
        // id="offcanvasExample"
        aria-labelledby="offcanvasExampleLabel"
      >
        <div
          style={{ backgroundColor: themeSidebarColor }}
          className="offcanvas-body side_bar_main_div d-flex  flex-column justify-content-between"
        >
          <div>
            <button
              className="btn"
              style={{
                backgroundColor: `${themeDisabledColor}`,
                width: "42px",
                height: "42px",
                borderRadius: "50%",
                margin: "10px 20px 20px",
                display: "flex",
                justifyContent: "center",
              }}
              onClick={() => {
                dispatch(layout(!props.sideBarHide));
              }}
              // aria-controls="offcanvasExample"
            >
              <span
                style={{
                  color: process.env.REACT_APP_THEME_OR_WITHOUT_THEME,
                }}
                className="bi-list"
              ></span>
            </button>
            <ul className="sidebar-nav mr-5">
              {routes.map((route, key) => {
                const active =
                  route.key === pathname ||
                  route.children.find((i) => i.key === pathname);
                return (
                  <Fragment key={key}>
                    <li
                      className={`cursor-pointer ${
                        active ? "active position-relative" : ""
                      }`}
                      key={route.key}
                    >
                      {active && (
                        <>
                          <div
                            style={{
                              marginTop: "-11.2%",
                              zIndex: "0",
                              width: "25px",
                              height: "120px",
                              backgroundColor: "white",
                            }}
                            className="position-absolute top-0 end-0"
                          />
                          <div
                            style={{
                              marginTop: "-11.2%",
                              borderRadius: "0 0 50px 0",
                              zIndex: "1",
                              backgroundColor: themeSidebarColor,
                              width: "25px",
                              height: "49.5%",
                            }}
                            className="position-absolute top-0 end-0"
                          />
                          <div
                            style={{
                              marginBottom: "-11.2%",
                              borderRadius: "0 50px 0 0",
                              zIndex: "1",
                              backgroundColor: themeSidebarColor,
                              // backgroundColor: 'blue',
                              width: "25px",
                              height:
                                route.name.toLowerCase() === "settings"
                                  ? "49%"
                                  : "49.2%",
                            }}
                            className="position-absolute bottom-0 end-0"
                          />
                        </>
                      )}
                      <a
                        onClick={() => {
                          if (route.children.length > 0) {
                            onExpandAndClose(route.id);
                          } else {
                            closeAll();
                            navigate(route.link);
                          }
                        }}
                        // href={'#'}
                        style={{
                          color: active ? themeColor : "#ffffff",
                          backgroundColor: active ? "white" : "transparent",
                          borderRadius: "50px 0 0 50px",
                          marginRight: "5px",
                        }}
                        className={
                          "d-flex justify-content-between align-items-center"
                        }
                      >
                        <div>
                          {/* <img
                            src={active ? route.selectedIcon : route.icon}
                            alt="action"
                            width={"24px"}
                            height={"24px"}
                          /> */}
                          <span className="me-1">
                            <Icon
                              name={active ? route.selectedIcon : route.icon}
                            />
                          </span>
                          {route.name}
                        </div>

                        {route.children.length > 0 && (
                          <Icon
                            name={
                              route.expand
                                ? active
                                  ? ICON_NAMES.DROPDOWNSELECTED
                                  : ICON_NAMES.DROPDOWNSELECTEDLIGHT
                                : !active
                                ? ICON_NAMES.DROPDOWNLIGHT
                                : ICON_NAMES.LIGHTTHEMEDROPDOWN
                            }
                          />
                        )}
                      </a>
                    </li>

                    {route.expand && (
                      <div
                        className="cursor-pointer d-flex flex-column gap-3"
                        style={{
                          marginLeft: "20%",
                          marginTop: "15px",
                        }}
                      >
                        {route.children.map((i) => (
                          <div
                            key={i.id}
                            onClick={() => {
                              navigate(i.link);
                              if (i.onClickClose) {
                                // dispatch(layout(false));
                              }
                            }}
                            className={`sub-menu${
                              i.key === pathname ? "-active" : ""
                            } d-flex align-items-center ml-5`}
                            style={{
                              marginBottom: "10px",
                            }}
                          >
                            {i.name}
                          </div>
                        ))}
                      </div>
                    )}
                  </Fragment>
                );
              })}
              <li className="mobile-logout">
                <a>
                  <button
                    type="button"
                    className="btn px-5"
                    style={{ color: "#fff", borderColor: "#fff" }}
                    onClick={logoutHandler}
                  >
                    Logout
                  </button>
                </a>
              </li>
            </ul>
          </div>
          <div>
            {videodata.map(
              (data) =>
                data.page === pathname && (
                  <div
                    key={data.link}
                    className="d-flex flex-column justify-content-center align-items-center pb-3"
                  >
                    {!isVideoMinimize && (
                      <div
                        onClick={() => {
                          setIsVideoPlay(data.page);
                          setIsVideoMinimize(false);
                        }}
                        style={{ borderRadius: "1rem" }}
                        className="d-flex  flex-column align-items-center justify-content-center position-relative bg-black"
                      >
                        <Link to="#">
                          <img
                            style={{
                              borderRadius: "1rem",
                              objectFit: "cover",
                            }}
                            // className="w-100"
                            height={200}
                            width={200}
                            src={data?.image}
                            alt="image"
                          />
                        </Link>

                        <div
                          style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            backgroundColor: "rgba(0, 0, 0, 0.5)",
                            borderRadius: "1rem",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            cursor: "pointer",
                          }}
                        ></div>
                        <h5
                          className="position-absolute text-center text-white"
                          style={{
                            top: "0",
                            width: "80%",
                            paddingTop: "1.25rem",
                            paddingBottom: "1.25rem",
                            fontSize: "1rem",
                          }}
                        >
                          {data?.title}
                        </h5>
                        <div
                          className="px-2  border border-solid border-gray-200 rounded-pill position-absolute bottom-0"
                          style={{
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            cursor: "pointer",
                            backgroundColor: "rgba(246, 246, 249, 0.14)",
                          }}
                        >
                          <div className="d-flex justify-content-evenly align-items-center">
                            <img src={playLogo} alt="fb" />
                            <span className="text-white ms-1">Play</span>
                          </div>
                        </div>
                      </div>
                    )}
                    {isVideoPlay === data.page && (
                      <VideoModal
                        setIsVideoMinimize={setIsVideoMinimize}
                        isVideoMinimize={isVideoMinimize}
                        onHide={setIsVideoPlay}
                        link={data?.link}
                        videoPage={data.page}
                      />
                    )}
                  </div>
                )
            )}
          </div>
        </div>
      </div>
    </aside>
  );
}

export default Sidebar;
