import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Login from "./components/Login";
import Register from "./components/Register";
import Home from "./components/Home";
import Settings from "./components/settings";
import MapView from "./components/MapView";
import Staticstics from "./components/Layout/Staticstics";
import {
  CompleteQuestionnaire,
  FileApplication,
  Introduction,
  Notifications,
  Terms,
  Workflow,
} from "./pages";
import Layout from "./components/Layout";
import Questionnaire from "./components/Questionnaire";
import PowerOfAttorney from "./pages/ActionRequired/PowerOfAttorney";
import "react-toastify/dist/ReactToastify.css";
import "./assets/scss/style.scss";
import TermsRedirect from "./components/common/TermsRedirect";
import { Faq } from "./pages/Faq";
import SSOLogin from "./components/SSOLogin";
import { primaryColor } from "./common/env";
import { FilterProvider } from "./components/Layout/Staticstics/filterContext";

const App = () => {
  const { isLoggedIn } = useSelector((state) => state.auth);
  const { sideBarHide } = useSelector((state) => state.layout);

  const [hasAcceptedTermOfServices, setHasAcceptedTermOfServices] =
    React.useState(localStorage.getItem("termsofservices"));

  useEffect(() => {
    document.documentElement.style.setProperty("--primaryColor", primaryColor);
  }, []);
  return (
    <Router>
      <div
        id={isLoggedIn ? "wrapper" : ""}
        className={!sideBarHide ? "mystyle" : "openSideBarStyle"}
      >
        <Routes>
          <Route
            path="/"
            element={
              <Layout>
                <Introduction
                  isChecked={hasAcceptedTermOfServices}
                  setIsChecked={setHasAcceptedTermOfServices}
                />
              </Layout>
            }
          />
          <Route path="/action-required/:status" element={<Home />} />
          <Route path="/register" element={<Register />} />
          {/* <Route path="/login" element={<Login />} /> */}
          <Route path="/login" element={<SSOLogin />} />
          <Route
            path="/map-view"
            element={
              <Layout>
                <FilterProvider>
                  <Staticstics />
                </FilterProvider>
              </Layout>
            }
          />
          <Route
            path="/questionnaire"
            element={
              hasAcceptedTermOfServices ? (
                <Layout>
                  <Questionnaire />
                </Layout>
              ) : (
                <TermsRedirect />
              )
            }
          />
          <Route
            path="/complete-questionnaire"
            element={
              hasAcceptedTermOfServices ? (
                <Layout>
                  <CompleteQuestionnaire />
                </Layout>
              ) : (
                <TermsRedirect />
              )
            }
          />
          <Route
            path="/sign-power-of-attorney"
            element={
              hasAcceptedTermOfServices ? (
                <Layout>
                  <PowerOfAttorney />
                </Layout>
              ) : (
                <TermsRedirect />
              )
            }
          />
          <Route
            path="/file-applications"
            element={
              hasAcceptedTermOfServices ? (
                <Layout>
                  <FileApplication />
                </Layout>
              ) : (
                <TermsRedirect />
              )
            }
          />
          <Route
            path="/settings"
            element={
              <Layout>
                <Settings />
              </Layout>
            }
          />
          <Route
            path="/notifications"
            element={
              <Layout>
                <Notifications />
              </Layout>
            }
          />
          <Route
            path="/workflow"
            element={
              <Layout>
                <Workflow />
              </Layout>
            }
          />
          <Route
            path="/terms"
            element={
              <Layout>
                <Terms />
              </Layout>
            }
          />
          <Route
            path="/faq"
            element={
              <Layout>
                <Faq />
              </Layout>
            }
          />
        </Routes>
      </div>
      <ToastContainer />
    </Router>
  );
};

export default App;
