export function flattenData(jsonData) {
  const result = [];

  function processElement(element) {
    const newElement = {
      name: element.name,
      type: element.type,
      width: element.width,
      group: element.group,
      placeholder: element.placeholder,
      description: element.description,
      required: element.required,
      max_length: element.max_length,
      choices: element.choices,
      parent_value: element.parent_value,
      help: {
        details: element.help?.details,
        video_link: element?.help?.video_link,
      },
      extra_validations: {
        regex: element?.extra_validations?.regex,
      },
      persona: element.persona,
      employer_common_name: element.employer_common_name,
    };

    result.push(newElement);
    if (element.conditional_fields && element.conditional_fields.length) {
      element.conditional_fields.forEach(function (item) {
        processElement(item);
      });
    }
  }

  jsonData.forEach((item) => processElement(item));

  return result;
}
