import React, { useRef, useState } from "react";
// import edit from "../../../assets/images/edit.svg";
import { clsx } from "clsx";
// import { HelpText } from "../../../components/common/index.jsx";
import { useDispatch, useSelector } from "react-redux";
import { getPoaEntities, uplaodPOA } from "../../../slices/powerOfAttorney.js";
import useCountryList from "../../../assets/shared/hooks/useCountryList.js";
import { Loader } from "../../../components/common/Loading/loading.js";
import { MyModal } from "../../../components/common/index.jsx";
import uploadLogo from "../../../assets/images/upload.svg";
import { TitleText } from "../../../components/TitleText/index.js";
import Close from "../../../assets/images/close.svg";
import { fileToBase64 } from "../../../utils/fileConversions.js";
import { ShowInstructionModal } from "../../../components/common/Modal/ShowInstructionModal.jsx";
import CompletePowerOfAttorneyTable from "./CompletePowerOfAttorneyTable.jsx";
import PendingPowerOfAttorneyTable from "./PendingPowerOfAttorneyTable.jsx";
import styles from "../styles.module.css";
import { primaryColor } from "../../../common/env.js";

const API_URL = process.env.REACT_APP_BACK_END_URL;

const TabButton = ({ label, isActive, onClick, primaryColor }) => {
  return (
    <div
      onClick={onClick}
      style={{
        backgroundColor: isActive ? primaryColor : "",
        borderColor: primaryColor,
      }}
      className={clsx(styles.tabBtn, {
        [styles.tabBtn_Active]: isActive,
      })}
    >
      <p
        style={{
          color: isActive ? "#fff" : primaryColor,
        }}
      >
        {label}
      </p>
    </div>
  );
};

export default function PowerOfAttorney() {
  const themeColor = process.env.REACT_APP_LIGHT_PRIMARY;
  const dispatch = useDispatch();
  const { getCountryName } = useCountryList();
  const [uploadPOA, setUploadPOA] = useState(null);
  const [selectedFile, setSelectedFile] = useState("");
  const [selectedEntity, setSelectedEntity] = useState({});
  const { loading, completedPoaEntities, pendingPoaEntities, poaLoading } =
    useSelector((state) => state.powerOfAttorney);
  const [sortCondition, setSortCondition] = React.useState({});
  const [pendingSortCondition, setPendingSortCondition] = React.useState({});
  const [activeTab, setActiveTab] = useState("Tab1");

  const fileInputRef = useRef(null);
  const pendingPageNumber = useRef(1);
  const completedPageNumber = useRef(1);

  const filterContent = (search) => {};

  const getCompletePoaEntitiesHandler = React.useCallback(
    (url) => {
      dispatch(getPoaEntities({ url, is_complete: true }))
        .unwrap()
        .then(() => {
          filterContent();
        })
        .catch(() => {});
    },
    [dispatch]
  );
  const getPendingPoaEntitiesHandler = React.useCallback(
    (url) => {
      dispatch(getPoaEntities({ url, is_complete: false }))
        .unwrap()
        .then(() => {
          filterContent();
        })
        .catch(() => {});
    },
    [dispatch]
  );

  const getPoaEntitiesHandler = React.useCallback(
    (url, is_complete) => {
      if (is_complete === undefined || is_complete)
        getCompletePoaEntitiesHandler(url);
      if (!is_complete) getPendingPoaEntitiesHandler(url);
    },
    [getPendingPoaEntitiesHandler, getCompletePoaEntitiesHandler]
  );

  React.useEffect(() => {
    getPoaEntitiesHandler();
  }, [getPoaEntitiesHandler]);

  const fetchNextPage = (nextPageUrl, is_complete) => {
    if (nextPageUrl) {
      getPoaEntitiesHandler(nextPageUrl, is_complete);
    }
  };

  const fetchPreviousPage = (previousPageUrl, is_complete) => {
    if (previousPageUrl) {
      getPoaEntitiesHandler(previousPageUrl, is_complete);
    }
  };
  const uploadPOAHandler = async () => {
    const bas64File = await fileToBase64(selectedFile);
    const payload = {
      filename: selectedFile.name,
      file: bas64File,
      uuid: uploadPOA.uuid,
      employer_sub_entity: uploadPOA.employer_sub_entity,
    };
    dispatch(uplaodPOA(payload))
      .unwrap()
      .then((res) => {
        getPoaEntitiesHandler();
        setUploadPOA(null);
        setSelectedFile(null);
      })
      .catch(() => {});
  };

  const handleSorting = (field, is_complete, pageNumber) => {
    const sortConditions = is_complete ? sortCondition : pendingSortCondition;
    let order = "";
    if (sortConditions.field === field) {
      order = sortConditions.order === "" ? "-" : "";
    }
    if (is_complete) {
      setSortCondition({
        field,
        order,
      });
    } else {
      setPendingSortCondition({
        field,
        order,
      });
    }
    let url =
      API_URL +
      `personas/entity-poa/?is_complete=${is_complete}&page=${pageNumber}&ordering=${order}${field}`;
    getPoaEntitiesHandler(url, is_complete);
  };

  if (loading) return <Loader />;

  return (
    <main>
      <section className="listing-section">
        <div className="container-fluid">
          <div className="col-md-12">
            <div className="gap-3 d-flex justify-content-between align-items-center">
              <div>
                <div className="d-flex gap-3">
                  <h2>Sign power of attorney</h2>
                </div>
                <p>
                  Please review the Power of Attorney (POA) instructions for
                  details of how to complete each POA.
                  <br /> Where required, please upload the completed POA into
                  the portal using the 'Upload POA' link below
                </p>
              </div>
              <div>
                <TitleText
                  style={{
                    letterSpacing: "0.025rem",
                    color: "rgb(30 53 66)",
                    fontWeight: "600",
                    lineHeight: "18px",
                  }}
                  children={"Key"}
                />
                <TitleText
                  style={{
                    letterSpacing: "0.025rem",
                    color: "rgb(30 53 66)",
                    fontWeight: "600",
                    lineHeight: "18px",
                  }}
                  children={"Can be signed by anyone"}
                />
                <TitleText
                  style={{
                    letterSpacing: "0.025rem",
                    color: "rgb(30 53 66)",
                    fontWeight: "600",
                    lineHeight: "18px",
                  }}
                  restStyle="prefilled-color"
                  children={"Must be signed by a specific person"}
                />
              </div>
            </div>

            <div className={styles.tabs}>
              <TabButton
                label="Pending POAs"
                isActive={activeTab === "Tab1"}
                onClick={() => setActiveTab("Tab1")}
                primaryColor={primaryColor}
              />
              <TabButton
                label="Completed POAs"
                isActive={activeTab === "Tab2"}
                onClick={() => setActiveTab("Tab2")}
                primaryColor={primaryColor}
              />
            </div>
            <hr className={styles.tabsHr} />
            {/* PENDING POA */}
            {activeTab === "Tab1" ? (
              <div className="pt-2">
                <div>
                  <div className="d-flex gap-3">
                    <h2>Pending Power of Attorneys</h2>
                  </div>
                  <p>List of POAs which have not been completed</p>
                </div>

                {pendingPoaEntities?.length ? (
                  <PendingPowerOfAttorneyTable
                    setSelectedEntity={setSelectedEntity}
                    setUploadPOA={setUploadPOA}
                    fetchPreviousPage={fetchPreviousPage}
                    fetchNextPage={fetchNextPage}
                    handleSorting={handleSorting}
                    pageNumber={pendingPageNumber}
                    sortCondition={pendingSortCondition}
                  />
                ) : (
                  <div className="d-flex justify-content-center pt-2">
                    <TitleText restStyle="fs-5">
                      No List of POAs which have not been completed.
                    </TitleText>
                  </div>
                )}
              </div>
            ) : (
              <div className="pt-2">
                <div>
                  <div className="d-flex gap-3">
                    <h2>Completed Power of Attorneys</h2>
                  </div>
                  <p>List of POAs which have been completed</p>
                </div>

                {completedPoaEntities?.length ? (
                  <CompletePowerOfAttorneyTable
                    setSelectedEntity={setSelectedEntity}
                    setUploadPOA={setUploadPOA}
                    fetchPreviousPage={fetchPreviousPage}
                    fetchNextPage={fetchNextPage}
                    handleSorting={handleSorting}
                    pageNumber={completedPageNumber}
                    sortCondition={sortCondition}
                  />
                ) : (
                  <div className="d-flex justify-content-center pt-2">
                    <TitleText restStyle="fs-5">
                      No List of POAs which have been completed.
                    </TitleText>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </section>
      {uploadPOA ? (
        <MyModal>
          <div className="w-100">
            <h2>Upload document </h2>
            <div className="d-flex flex-column gap-2">
              <div className="flex flex-col gap-4">
                <div className="flex justify-between">
                  <div>
                    <TitleText restStyle="w-full my-3">
                      Upload a completed Power of Attorney (POA) for this
                      entity: {getCountryName(uploadPOA.country_code)} -{" "}
                      {uploadPOA.employer_sub_entity_name}
                    </TitleText>
                  </div>
                </div>
                <div
                  style={{ gap: "10px" }}
                  className="d-flex justify-content-center"
                >
                  <div
                    style={{
                      border: "1px solid",
                      borderStyle: "dashed",
                      borderColor: "rgb(218 218 218)",
                      borderWidth: "2px",
                      borderRadius: "20px",
                    }}
                    className="d-flex  w-70 p-3"
                  >
                    <div className="d-flex flex-column w-100 h-100 justify-content-center align-items-center gap-4 p-4">
                      <div className="mb-4">
                        <img src={uploadLogo} alt="upload" />
                      </div>

                      <div className="d-flex w-100 text-center">
                        <p
                          style={{ width: "200px", paddingTop: "15px" }}
                          className="text-muted text-truncate"
                        >
                          {selectedFile?.name}
                        </p>
                        {selectedFile?.name && (
                          <img
                            className="bi-close cursor-pointer ms-2"
                            src={Close}
                            alt="close"
                            width={20}
                            onClick={() => setSelectedFile("")}
                          />
                        )}
                      </div>
                      <input
                        ref={fileInputRef}
                        type="file"
                        style={{ display: "none" }}
                        onChange={async (e) => {
                          // Handle the file input change event if needed
                          if (e.target.files?.length) {
                            const selectedFile = e.target.files[0];
                            setSelectedFile(selectedFile);
                          }
                        }}
                        accept=".pdf"
                      />
                      <div>
                        <button
                          style={{
                            color: themeColor,
                            borderColor: themeColor,
                            backgroundColor: "white",
                            width: "150px",
                          }}
                          type="button"
                          className="btn"
                          onClick={() => {
                            if (fileInputRef.current) {
                              fileInputRef.current.click();
                            }
                          }}
                        >
                          Browse files
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="d-flex mt-5 gap-2 justify-content-center">
                <button
                  style={{
                    color: themeColor,
                    borderColor: themeColor,
                    backgroundColor: "white",
                    width: "150px",
                  }}
                  type="button"
                  className="btn "
                  onClick={() => setUploadPOA(null)}
                  disabled={poaLoading}
                >
                  Cancel
                </button>
                <button
                  style={{
                    backgroundColor: themeColor,
                    borderColor: themeColor,
                  }}
                  type="button"
                  className="btn btn-danger"
                  onClick={() => uploadPOAHandler()}
                >
                  {poaLoading ? (
                    <span className="spinner-border spinner-border-sm me-1"></span>
                  ) : (
                    "Upload"
                  )}
                </button>
              </div>
            </div>
          </div>
        </MyModal>
      ) : null}

      {Object.keys(selectedEntity).length ? (
        <ShowInstructionModal
          setSelectedEntity={setSelectedEntity}
          selectedEntity={selectedEntity}
        />
      ) : null}
    </main>
  );
}
