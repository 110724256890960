import React, { useCallback, useEffect, useRef, useState } from "react";
import edit from "../../../assets/images/edit.svg";
import remove from "../../../assets/images/delete.svg";
import add from "../../../assets/images/add-icon.svg";

import sortIcon from "../../../assets/images/sort-icon.svg";
import {
  // HelpText,
  MyModal,
  NormalText,
  TextInput,
} from "../../../components/common";
import circle from "../../../assets/images/circle.svg";
import filledCircle from "../../../assets/images/filled-circle.svg";
import { SelectBox } from "../../../components/common/Select";
import { useDispatch, useSelector } from "react-redux";
import {
  addPersonaEntities,
  deletePersonaEntities,
  getCountriesList,
  getPersonaEntities,
  updatePersonaEntities,
} from "../../../slices/dashboard";
import useCountryList from "../../../assets/shared/hooks/useCountryList";
import { Loader } from "../../../components/common/Loading/loading.js";
import dashboardService from "../../../services/dashboard.service.js";
import { Loading } from "../../../components/Loading.js";
import ProgressLoading from "../../../components/ProgressLoading.jsx";
import entityService from "../../../services/entity.service.js";
import { useNavigate } from "react-router-dom";
import { TableLaoding } from "../../../components/common/Loading/TableLoading.js";
import debounce from "lodash/debounce";
import Icon, { ICON_NAMES } from "../../../components/Icone/index.jsx";
import styles from "./index.module.css";
import { startQuestionareLoading } from "../../../slices/questionnaire.js";
import { toast } from "react-toastify";

const API_URL = process.env.REACT_APP_BACK_END_URL;

export const CompleteQuestionnaire = () => {
  const themeColor = process.env.REACT_APP_LIGHT_PRIMARY;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { getCountryName, getCountryCode } = useCountryList();
  const {
    personaEntities,
    loading,
    countriesList,
    singleLoading,
    nextPageUrl,
    previousPageUrl,
  } = useSelector((state) => state.dashboard);
  const [addEntryModal, setAddEntryModal] = React.useState(false);
  const [updateEntryModal, setUpdateEntryModal] = React.useState("");
  const [entityUpdateName, setEntityUpdateName] = React.useState("");
  const [uploadCSVModal, setUploadCSVModal] = React.useState(false);
  const [selectedOption, setSelectedOption] = React.useState("");
  const [searchText, setSearchText] = useState("");
  const [personaData, setPersonaData] = useState({});
  const [arrangedCountryList, setArrangedCountryList] = useState([]);
  const [adressList, setAdressList] = useState([]);
  const [adressAdded, setAdressAdded] = useState({});
  const [addedIndex, setAddedIndex] = useState([]);
  const [saveLoadin, setSaveLoading] = useState(false);
  const [sortCondition, setSortCondition] = useState({});
  const [deleteEntity, setDeleteEntity] = useState("");
  const [percentage, setPercentage] = useState(0);
  const intervalId = useRef(null); // Using ref to store the interval ID
  const duration = 60;
  const [abortController, setAbortController] = useState(null);
  const apiCallCompleted = useRef(false); // Ref to store the dynamic value

  const clearLoderHandler = () => {
    setSaveLoading(false);
    setPercentage(0);
    apiCallCompleted.current = false;
    clearInterval(intervalId.current);
    if (abortController) {
      abortController.abort();
      console.log("API call canceled by user");
      setAbortController(null);
    }
  };

  const handleSorting = (field) => {
    setSortCondition((prev) => {
      let order = "";

      if (prev.field === field) {
        order = prev.order === "" ? "-" : "";
      }

      return {
        field,
        order,
      };
    });
  };

  useEffect(() => {
    const { field, order } = sortCondition;

    if (field) {
      const url = API_URL + `personas/entities/?ordering=${order}${field}`;
      getPersonaEntitiesHandler(url);
    }
  }, [sortCondition]);

  const filterContent = (search) => {};

  const onChangeHandler = (name, value) => {
    const data = { ...personaData };

    if (name === "contact") {
      if (data[name]) {
        data[name] = {
          ...data[name],
          ...value,
        };
      } else {
        data[name] = value;
      }
    }

    if (name === "name" || name === "country") data[name] = value;
    setPersonaData(data);
  };
  const getPersonaEntitiesHandler = (url) => {
    dispatch(getPersonaEntities(url))
      .unwrap()
      .then(() => {
        filterContent();
      })
      .catch(() => {});
  };
  const deletePersonaEntitiesHandler = (url) => {
    setDeleteEntity("");
    dispatch(deletePersonaEntities(url))
      .unwrap()
      .then(() => {
        getPersonaEntitiesHandler();
      })
      .catch(() => {});
  };
  const addPersonaEntitiesHandler = (data) => {
    dispatch(addPersonaEntities(data))
      .unwrap()
      .then(() => {
        setPersonaData({});
        setAddEntryModal(false);
      })
      .catch(() => {});
  };
  const getCountryListHandler = () => {
    dispatch(getCountriesList())
      .unwrap()
      .then(() => {
        filterContent();
      })
      .catch(() => {});
  };
  const searchAddressesHandler = async (data) => {
    setSaveLoading(true);

    const totalSteps = 100; // Total percentage to animate to
    const intervalTime = (duration * 1000) / totalSteps; // Time per percentage point
    intervalId.current = setInterval(() => {
      const isCompleted = apiCallCompleted.current;
      setPercentage((prev) => {
        if (isCompleted && prev > 0 && prev < 98) {
          return 98;
        } else if (prev < totalSteps) {
          return prev + 1;
        } else {
          clearLoderHandler();
          return 0;
        }
      });
    }, intervalTime);

    try {
      let query = `company_name=${personaData.name}`;
      const countryName = countriesList.find(
        (_item) => _item.uuid === personaData.country
      );
      if (countryName?.name) {
        query += `&&country_code=${getCountryCode(countryName?.name)}`;
      }

      const controller = new AbortController();
      setAbortController(controller);

      const res = await dashboardService.serachAddress(
        query,
        controller.signal
      );
      apiCallCompleted.current = true;

      setAbortController(null);
      if (res.error) {
        toast.error(
          res.message ||
            "Could not fetch country data. Please try by adding country manually"
        );
        setAdressList([]);
        return;
      }
      setAdressList(res.data);
    } catch (error) {
      setAdressList([]);
      console.log(error);
      clearLoderHandler();
    }
  };
  useEffect(() => {
    getPersonaEntitiesHandler();
    getCountryListHandler();
  }, []);
  useEffect(() => {
    if (!singleLoading) {
      setPersonaData({});
      setAddEntryModal(false);
    }
  }, [singleLoading]);
  useEffect(() => {
    if (countriesList && countriesList.length) {
      setArrangedCountryList(
        countriesList.map((_item) => {
          return { label: _item.name, value: _item.uuid };
        })
      );
    }
  }, [countriesList]);

  const handleRemoveAdress = (adress) => {
    setAdressList(
      adressList.filter((item) => item?.house_number !== adress?.house_number)
    );
  };
  const addAddressHandler = (adress, itemIndex) => {
    setAddedIndex([itemIndex]);
    setAdressAdded(adress);
  };
  const handleAddAdress = async (adress, itemIndex) => {
    setSaveLoading(true);
    try {
      const res = await entityService.createPersona({
        name: adressAdded.employer_name,
        country: personaData.country,
      });
      if (res?.error) {
        toast.error(res?.message || "Error processing this request");
      }
      if (!res?.error) {
        const countryName = arrangedCountryList?.find(
          (_item) => _item.value === personaData.country
        )?.label;
        // getPersonaEntitiesHandler();
        handleGetForm(
          {
            ...res.data,
            country_name: countryName,
            country_code: getCountryCode(countryName),
            persona_name: res.data.name,
          },
          adressAdded,
          false
        );
        setAddEntryModal(false);
        setPersonaData({});
        setAdressList([]);
        setSortCondition({});
      }
    } catch (error) {
      console.log(error);
    }
    setSaveLoading(false);
  };

  const handleManualEntityAddition = async (name, country) => {
    try {
      const res =
        (await entityService.createPersona({
          name: name,
          country: country,
        })) || {};

      if (res.error) {
        toast.error(res.message);
        return;
      }
      const data = res.data;
      
      getPersonaEntitiesHandler();
      const selectedCountry = countriesList.find(
        (_item) => _item.uuid === country
      );
      data.country_code = getCountryCode(selectedCountry.name);
      data.country_name = selectedCountry.name;
      handleGetForm(data, {}, false);
      setAddEntryModal(false);
      setPersonaData({});
      setAdressList([]);
      setSortCondition({});
    } catch (error) {
      console.log(error);
    }
  };

  const fetchNextPage = () => {
    if (nextPageUrl) {
      getPersonaEntitiesHandler(nextPageUrl);
    }
  };

  const fetchPreviousPage = () => {
    if (previousPageUrl) {
      getPersonaEntitiesHandler(previousPageUrl);
    }
  };

  const handleModalClose = () => {
    setPersonaData({});
    setAddEntryModal(false);
    setAdressList([]);
  };
  const handleGetForm = (item, address, fromModal) => {
    dispatch(startQuestionareLoading());
    navigate("/questionnaire", {
      state: { item, address, fromModal },
    });
  };

  const debouncedGetPersonaEntitiesHandler = useCallback(
    debounce((url) => {
      getPersonaEntitiesHandler(url);
    }, 1300),
    []
  );

  const handleSearch = (value) => {
    if (!value) {
      debouncedGetPersonaEntitiesHandler();
    } else if (value.length) {
      const url = API_URL + `personas/entities/?search=${value}`;
      debouncedGetPersonaEntitiesHandler(url);
    }
    setSearchText(value);
  };

  return (
    <main>
      {loading ? (
        <div
          className="position-fixed top-0 start-0 w-100 h-100 d-flex justify-content-center align-items-center bg-dark bg-opacity-50"
          style={{
            zIndex: "9999",
          }}
        >
          <TableLaoding />
        </div>
      ) : null}
      <section className="listing-section">
        <div className="container-fluid">
          <div className="col-md-12">
            <div className="d-flex gap-3">
              <h2>Complete questionnaire</h2>
              {/* <HelpText helpText={"Help text here!"} /> */}
            </div>
            <p>
              Please complete the following questionnaires which are required
              for the filing of A1 applications in each country.
            </p>
            <div className="d-flex mb-3 flex-wrap flex-md-nowrap gap-2">
              <div className="w-100">
                <div className="w-50" style={{ minWidth: "200px" }}>
                  <TextInput
                    label="Entity"
                    value={searchText}
                    isRequired={false}
                    placeHolder="Search here"
                    onChange={(name, value) => {
                      handleSearch(value);
                    }}
                    Icon={
                      <div className="pb-1">
                        <i className="h-100 bi bi-search" />
                      </div>
                    }
                  />
                </div>
              </div>
              <div className="w-100 text-end">
                <button
                  style={{
                    backgroundColor: themeColor,
                    borderColor: themeColor,
                  }}
                  type="button"
                  className="btn btn-danger"
                  onClick={() => setAddEntryModal(true)}
                >
                  Add entity
                </button>
              </div>
            </div>

            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th>
                      <div className="d-flex align-items-center gap-2">
                        <img
                          onClick={() => {
                            handleSorting("country_name");
                          }}
                          src={sortIcon}
                          alt="sort-icon"
                          style={{ cursor: "pointer" }}
                        />
                        <div>Country questionnaire</div>
                        {/* <div>
                          <HelpText
                            helpText={"Country Questionnaire"}
                            customClass=""
                          />
                        </div> */}
                      </div>
                    </th>
                    <th>
                      <div className="d-flex align-items-center gap-2">
                        <img
                          onClick={() => {
                            handleSorting("name");
                          }}
                          src={sortIcon}
                          alt="sort-icon"
                          style={{ cursor: "pointer" }}
                        />
                        <div>Employer entity</div>
                        {/* <div>
                          <HelpText helpText={"Entity"} customClass="" />
                        </div> */}
                      </div>
                    </th>
                    <th className="position-relative">
                      <div className="d-flex align-items-center gap-2">
                        <img
                          onClick={() => {
                            handleSorting("status");
                          }}
                          src={sortIcon}
                          alt="sort-icon"
                          style={{ cursor: "pointer" }}
                        />
                        <div>Status</div>
                        {/* <div>
                          <HelpText helpText={"Status"} customClass="" />
                        </div> */}
                      </div>
                    </th>
                    <th className="position-relative">
                      <div className="d-flex align-items-center gap-2">
                        <div>Action</div>
                        {/* <div>
                          <HelpText helpText={"Action"} customClass="" />
                        </div> */}
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {personaEntities?.map((i, index) => {
                    return (
                      <tr key={index}>
                        <td>{getCountryName(i.country_code)}</td>
                        <td
                          onClick={() => {
                            setUpdateEntryModal(i);
                            setEntityUpdateName(i.name);
                          }}
                          className="cursor-pointer"
                        >
                          {i.name}
                          <Icon name={ICON_NAMES.EDIT} />
                        </td>
                        <td>
                          {i.is_complete ? (
                            "Complete"
                          ) : (
                            <div style={{ color: "#a51e22" }}>Incomplete</div>
                          )}
                        </td>
                        <td>
                          <div style={{ gap: "15px" }} className="d-flex">
                            <div
                              onClick={() => {
                                handleGetForm(i, {}, false);
                              }}
                              style={{ color: themeColor }}
                              className={`${styles.tableAction} cursor-pointer`}
                            >
                              <Icon name={ICON_NAMES.EDIT} />
                              <span>Edit</span>
                            </div>
                            <div
                              onClick={() => {
                                setDeleteEntity(i.uuid);
                              }}
                              style={{ color: themeColor }}
                              className={`${styles.tableAction} cursor-pointer`}
                            >
                              <Icon name={ICON_NAMES.DELETICONE} />
                              <span>Delete</span>
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>

              <div className="d-flex justify-content-between align-items-center">
                <div>
                  {previousPageUrl && (
                    <button
                      style={{
                        backgroundColor: themeColor,
                        borderColor: themeColor,
                      }}
                      type="button"
                      className="btn btn-danger"
                      onClick={fetchPreviousPage}
                      disabled={!previousPageUrl}
                    >
                      Previous page
                    </button>
                  )}
                </div>
                <div>
                  {nextPageUrl && (
                    <button
                      style={{
                        backgroundColor: themeColor,
                        borderColor: themeColor,
                      }}
                      type="button"
                      className="btn btn-danger"
                      onClick={fetchNextPage}
                      disabled={!nextPageUrl}
                    >
                      Next page
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        {deleteEntity && (
          <MyModal>
            {saveLoadin && <Loading />}
            <div className="w-100">
              <div className="d-flex align-items-center justify-content-center flex-wrap">
                <h2>Confirmation</h2>
              </div>

              <div className="d-flex align-items-center justify-content-center flex-wrap my-5">
                Are you sure you want to delete this item? This action is
                permanent and cannot be undone.
              </div>

              <div className="row w-100">
                <div className="col">
                  <div className="d-flex w-100 justify-content-center gap-3">
                    <button
                      style={{
                        color: themeColor,
                        borderColor: themeColor,
                        backgroundColor: "white",
                      }}
                      type="button"
                      className="btn btn-danger"
                      onClick={() => setDeleteEntity("")}
                    >
                      Cancel
                    </button>
                    <button
                      style={{
                        backgroundColor: themeColor,
                        borderColor: themeColor,
                      }}
                      type="button"
                      className="btn btn-danger"
                      onClick={() => {
                        const url =
                          API_URL + `personas/entities/${deleteEntity}/`;
                        deletePersonaEntitiesHandler(url);
                      }}
                    >
                      Delete
                    </button>
                    {/* <button
                  disabled={
                    !(
                      personaData.name &&
                      personaData.country &&
                      personaData?.contact?.name &&
                      personaData?.contact?.email
                    )
                  }
                  type="button"
                  className="btn btn-danger"
                  onClick={() => {
                    addPersonaEntitiesHandler(personaData);
                  }}
                >
                  {singleLoading ? (
                    <span className="spinner-border spinner-border-sm me-1"></span>
                  ) : (
                    "Add"
                  )}
                </button> */}
                  </div>
                </div>
              </div>
            </div>
          </MyModal>
        )}
        {addEntryModal && (
          <MyModal>
            {saveLoadin && (
              <ProgressLoading
                percentage={percentage}
                clearLoderHandler={clearLoderHandler}
              />
            )}
            <div className="w-100">
              <div
                className="d-flex align-items-center justify-content-between flex-wrap"
                style={{ gap: 7 }}
              >
                <h2 style={{ marginBottom: 0 }}>Add entity details</h2>
                <button
                  style={{
                    backgroundColor: themeColor,
                    borderColor: themeColor,
                  }}
                  disabled={!(personaData.name && personaData.country)}
                  type="button"
                  className="btn btn-danger"
                  onClick={searchAddressesHandler}
                  loading={true}
                >
                  Search
                </button>
              </div>
              <div className={`${styles.entitySelectContainer} w-100`}>
                <div style={{ width: "100%" }}>
                  <SelectBox
                    showIcons={false}
                    value={personaData.country}
                    onChange={(country) => {
                      onChangeHandler("country", country?.value);
                    }}
                    options={arrangedCountryList}
                  />
                </div>
                <div style={{ width: "100%" }}>
                  <label className="form-label">
                    Name of employer
                    <span style={{ color: themeColor }}>*</span>
                  </label>
                  <input
                    value={personaData.name}
                    onChange={(e) => onChangeHandler("name", e.target.value)}
                    className="form-control"
                    placeholder="Enter employer name"
                  />
                </div>
              </div>
              {adressList?.map((item, key) => {
                return (
                  <div className="mt-5" key={key}>
                    <div className="d-flex justify-content-between flex-wrap">
                      <h5> {item.employer_name}</h5>
                      <div className="d-flex">
                        {!addedIndex?.includes(key) && (
                          <div
                            style={{
                              color: "#A51E22",
                              fontSize: "14px",
                              fontWeight: "500",
                            }}
                            className="cursor-pointer d-flex justify-content-center align-items-center text-center pe-2 border-end"
                          >
                            <img
                              src={remove}
                              alt="remove"
                              height={"15px"}
                              className="me-1"
                            />
                            <NormalText
                              onClick={() => handleRemoveAdress(item)}
                              color="red"
                            >
                              Remove
                            </NormalText>
                          </div>
                        )}
                        <div
                          style={{
                            color: "#1B52A4",
                            fontSize: "14px",
                            fontWeight: "500",
                          }}
                          onClick={() =>
                            !addedIndex?.includes(key) &&
                            addAddressHandler(item, key)
                          }
                          className="cursor-pointer ps-2 d-flex justify-content-center align-items-center text-center border-left border-grey "
                        >
                          {!addedIndex?.includes(key) && (
                            <div className="me-1">
                              <Icon name={ICON_NAMES.ADDICONE} />
                            </div>
                          )}
                          <NormalText color="theme">
                            {addedIndex?.includes(key)
                              ? "Selected"
                              : "Click to select"}
                          </NormalText>
                        </div>
                      </div>
                    </div>
                    {/* <div className="d-flex mt-3">
                      <span> Company website:</span>
                      <div>{item.website}</div>
                    </div> */}
                    <div className="d-flex mt-3">
                      <span> Address: </span>

                      <div className="px-1">
                        {item.employer_address
                          ? item.employer_address
                          : (item.employer_city ? item.employer_city : "") +
                            " " +
                            (item.employer_street_name
                              ? item.employer_street_name
                              : "")}
                      </div>
                    </div>
                    {/* <div className="d-flex mt-3">
                      <span> Phone number:</span>
                      <div>{item.phone}</div>
                    </div> */}
                    <hr />
                  </div>
                );
              })}

              <div
                style={{ flexWrap: "wrap" }}
                className="mt-5 d-flex w-100 justify-content-center gap-3"
              >
                <button
                  style={{
                    color: themeColor,
                    borderColor: themeColor,
                    backgroundColor: "white",
                  }}
                  type="button"
                  className="btn"
                  onClick={handleModalClose}
                >
                  Cancel
                </button>
                <button
                  style={{
                    color: themeColor,
                    borderColor: themeColor,
                    backgroundColor: "white",
                  }}
                  disabled={!(personaData.name && personaData.country)}
                  type="button"
                  className="btn "
                  onClick={() => {
                    handleManualEntityAddition(
                      personaData.name,
                      personaData.country
                    );
                  }}
                >
                  Add manually
                </button>
                <button
                  style={{
                    backgroundColor: themeColor,
                    borderColor: themeColor,
                  }}
                  disabled={Object.keys(adressAdded)?.length ? false : true}
                  type="button"
                  className="btn btn-danger"
                  onClick={() => {
                    handleAddAdress();
                  }}
                >
                  Add
                </button>
                {/* <button
                            disabled={
                              !(
                                personaData.name &&
                                personaData.country &&
                                personaData?.contact?.name &&
                                personaData?.contact?.email
                              )
                            }
                            type="button"
                            className="btn btn-danger"
                            onClick={() => {
                              addPersonaEntitiesHandler(personaData);
                            }}
                          >
                            {singleLoading ? (
                              <span className="spinner-border spinner-border-sm me-1"></span>
                            ) : (
                              "Add"
                            )}
                          </button> */}
              </div>
            </div>
          </MyModal>
        )}
        {uploadCSVModal && (
          <MyModal style={{ width: "35%", marginLeft: "30%" }}>
            <div className="w-100">
              <h2>Please select an option</h2>
              <div className="d-flex flex-column gap-2">
                <SelectBox label="Select country" />
                <div
                  onClick={() => setSelectedOption("TEMP")}
                  className="mt-2"
                  style={{
                    border: `1px solid ${
                      selectedOption === "TEMP" ? "#A51E22" : "#F1F1F1"
                    }`,
                    borderRadius: "20px",
                  }}
                >
                  <div
                    className="d-flex justify-content-between"
                    style={{ padding: "35px" }}
                  >
                    <div
                      style={{
                        color:
                          selectedOption === "TEMP" ? "#A51E22" : "#758283",
                      }}
                    >
                      Download CSV template
                    </div>
                    <div>
                      <img
                        src={selectedOption === "TEMP" ? filledCircle : circle}
                        alt="circle"
                        height={24}
                        width={24}
                        style={{
                          zIndex: 1,
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div
                  onClick={() => setSelectedOption("CSV")}
                  className="mt-2"
                  style={{
                    border: `1px solid ${
                      selectedOption === "CSV" ? "#A51E22" : "#F1F1F1"
                    }`,
                    borderRadius: "20px",
                  }}
                >
                  <div
                    className="d-flex justify-content-between"
                    style={{ padding: "35px" }}
                  >
                    <div
                      style={{
                        color: selectedOption === "CSV" ? "#A51E22" : "#758283",
                      }}
                    >
                      Upload CSV
                    </div>
                    <div>
                      <img
                        src={selectedOption === "CSV" ? filledCircle : circle}
                        alt="circle"
                        height={24}
                        width={24}
                        style={{
                          zIndex: 1,
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="d-flex mt-5 gap-2 justify-content-center">
                  <button
                    type="button"
                    className="btn btn-outline-danger"
                    onClick={() => setUploadCSVModal(false)}
                  >
                    Done
                  </button>
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={() => setUploadCSVModal(false)}
                  >
                    OK
                  </button>
                </div>
              </div>
            </div>
          </MyModal>
        )}
        {updateEntryModal && (
          <MyModal onClose={() => setUpdateEntryModal("")}>
            {loading && <Loading />}
            <div className="w-100">
              <div className="d-flex justify-content-between flex-wrap">
                <h2>Update entity details</h2>
              </div>
              <div className="d-flex flex-wrap w-100 pt-5 justify-content-between">
                <div style={{ width: "100%" }}>
                  <label className="form-label">
                    Entity name
                    <span style={{ color: themeColor }}>*</span>
                  </label>
                  <input
                    value={entityUpdateName}
                    onChange={(e) => setEntityUpdateName(e.target.value)}
                    className="form-control"
                    placeholder="Enter entity  name"
                    style={{
                      paddingRight: "50px",
                    }}
                  />
                </div>
              </div>

              <div className="row w-100">
                <div className="col w-100">
                  <div className="row">
                    <div className="row mt-5">
                      <div className="col">
                        <div className="d-flex w-100 justify-content-center gap-3">
                          <button
                            style={{
                              color: themeColor,
                              borderColor: themeColor,
                              backgroundColor: "white",
                            }}
                            type="button"
                            className="btn"
                            onClick={() => {
                              setEntityUpdateName("");
                              setUpdateEntryModal("");
                            }}
                          >
                            Cancel
                          </button>
                          <button
                            disabled={!entityUpdateName}
                            style={{
                              backgroundColor: themeColor,
                              borderColor: themeColor,
                            }}
                            type="button"
                            className="btn btn-danger"
                            onClick={() => {
                              dispatch(
                                updatePersonaEntities({
                                  name: entityUpdateName,
                                  uuid: updateEntryModal.uuid,
                                })
                              )
                                .unwrap()
                                .then(() => {
                                  setEntityUpdateName("");
                                  setUpdateEntryModal("");
                                  getPersonaEntitiesHandler();
                                })
                                .catch(() => {});
                            }}
                          >
                            Update
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </MyModal>
        )}
      </section>
    </main>
  );
};
