import includes from "lodash/includes";
import { useSelector } from "react-redux";

import {
  FormDate,
  FormSelect,
  TextInput,
  Signature,
  RadioInput,
} from "../../../common";
import {
  FieldType,
  countriesForCountryAndHostCountryType,
} from "../../../../common/constants";
import { useEffect } from "react";

export const FormField = (props) => {
  const {
    tooltipPercent,
    index,
    field,
    values,
    errors,
    countryObject,
    countryObjectValues,
    setFieldValue,
    updateFieldVisibleRef,
    onKeyEnter = () => {},
    prefilledNames,
    manualFilledFields,
  } = props;

  const { showError } = useSelector((state) => state.questionnaire);

  useEffect(() => {
    if (field.default_value && !String(values[field.name]).length) {
      setFieldValue(field.name, field.default_value);
    }
  }, [field, values]);
  const fieldProps = {
    key: `field-${index}-${field.name}`,
    name: field.name,
    description: field.description,
    default_value: field.default_value || null,
    isRequired: field.required,
    placeholder: field.placeholder,
    value: values[field.name],
    error: errors[field.name],
    showError: showError,
    helpText: field.help?.details,
    initializable: true,
    updateVisible: updateFieldVisibleRef,
    onChange: setFieldValue,
    recommended: field?.recommended_values,
    maxLength: field?.max_length,
    onKeyEnter: onKeyEnter,
    tooltipPercent: tooltipPercent,
    prefilledNames: prefilledNames,
    manualFilledFields: manualFilledFields,
  };

  if (
    includes(
      [
        FieldType.DATE,
        FieldType.DATE_DISABLED,
        FieldType.DATE_PAST,
        FieldType.DATE_FUTURE,
        FieldType.DATE_OF_BIRTH,
      ],
      field.type
    ) ||
    field?.type?.startsWith("date_range_") ||
    field?.type?.startsWith("application_")
  )
    return <FormDate {...fieldProps} type={field.type} />;

  if (
    includes(
      [FieldType.NATIONALITY, FieldType.COUNTRY, FieldType.HOST_COUNTRY],
      field.type
    )
  ) {
    let choices = field.choices;
    let countryObjectData = countryObjectValues;
    return (
      <FormSelect
        {...fieldProps}
        choices={choices}
        countryObjectValues={countryObjectData}
        mappings={countryObject}
        type={field.type}
        isSearchable={true}
      />
    );
  }
  if (
    includes([FieldType.HOST_COUNTRY_CODE, FieldType.COUNTRY_CODE], field.type)
  ) {
    const mapping = {};
    countriesForCountryAndHostCountryType?.forEach((country) => {
      const foundCountry = Object.entries(countryObject).find(
        ([, value]) => value === country
      );
      if (foundCountry) {
        const key = foundCountry[0];
        mapping[key] = `${country} - ${key}`;
      }
    });

    return (
      <FormSelect
        {...fieldProps}
        choices={Object.keys(mapping)}
        mappings={mapping}
        type={field.type}
        isSearchable={true}
      />
    );
  }

  if (includes([FieldType.SIGNATURE], field.type))
    return <Signature {...fieldProps} />;

  if (field.type === FieldType.BOOLEAN)
    return <RadioInput {...fieldProps} value={values[field.name]} />;
  // if (field.type === FieldType.ATTACHMENT) {
  //     return (
  //         <FileUploader
  //             {...fieldProps}
  //             value={values[field.name]}
  //         />
  //     )
  // }

  if (
    includes(
      [
        FieldType.STRING,
        FieldType.EMAIL,
        FieldType.USER_EMAIL,
        FieldType.INTEGER,
        FieldType.NUMBER,
      ],
      field.type
    )
  ) {
    if (field.choices) {
      return (
        <FormSelect
          {...fieldProps}
          choices={field.choices}
          mappings={countryObject}
          type={field.type}
          isSearchable={true}
        />
      );
    } else {
      return (
        <TextInput
          {...fieldProps}
          description={field?.description}
          placeHolder={field?.placeholder}
          helpText={field?.help?.details}
        />
      );
    }
  }
  if (includes([FieldType.OPEN_CHOICE], field.type)) {
    let isSelectBox = false;
    if (
      String(values[field.name]) !== "" &&
      !field.choices
        ?.filter((choices) => choices.toLowerCase() !== "other")
        .includes(String(values[field.name]))
    ) {
      isSelectBox = false;
    } else if (
      field.choices
        ?.filter((choices) => choices.toLowerCase() !== "other")
        .includes(String(values[field.name])) ||
      values[field.name] === ""
    ) {
      isSelectBox = true;
    }
    return (
      <FormSelect
        {...fieldProps}
        choices={field.choices}
        isSelectBox={isSelectBox}
        isSearchable={true}
      />
    );
  }
  if (includes([FieldType.BREAK], field.type)) {
    return null;
  }
  return (
    <TextInput
      type={field.type}
      {...fieldProps}
      label={field?.description}
      placeHolder={field?.placeholder}
      helpText={field?.help?.details}
    />
  );
};
