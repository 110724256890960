import React, { useCallback, useEffect, useRef, useState } from "react";
import styles from "./index.module.css";
import Icon, { ICON_NAMES } from "../../Icone";
import { useDispatch } from "react-redux";
import {
  AddApplicationsComment,
  getFilledApplications,
  updateApplicationsComment,
} from "../../../slices/filledApplications";
import { apiFetchWrapper } from "../../../utils/axiosApiWrapper";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const isEmail = (text) => {
  const emailRegex = /\S+@\S+\.\S+/;
  return emailRegex.test(text);
};

export const AddComment = ({
  selectedItem,
  setSelectedItem,
  setAddCommentModal,
  getAllComments,
  applicationUuid,
}) => {
  const themeColor = process.env.REACT_APP_LIGHT_PRIMARY;
  const [loading, setLoading] = useState(false);
  const [comment, setComment] = useState("");
  const [cleanComment, setCleanComment] = useState("");

  const [suggestions, setSuggestions] = useState([]);
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });

  const [showSuggestions, setShowSuggestions] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [cursorPosition, setCursorPosition] = useState(null);

  const [users, setUsers] = useState([]);

  const modalRef = useRef(null);
  const textareaRef = useRef(null);
  const editorRef = useRef(null);
  const dispatch = useDispatch();

  const fetchUsers = async () => {
    try {
      const response = await apiFetchWrapper.get(`teams/users/`);
      if (response.error) return;
      const userList = response.data.map((user) => {
        return user.email;
      });
      setUsers(userList);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const updateCommentTags = () => {
    if (!textareaRef.current) return "";
    const quill = textareaRef.current?.getEditor();
    const text = quill.getText()?.replace(/\n/g, " ");

    const resultString = text.replace(/(\S+@\S+\.\S+)/g, (match) => {
      const cleanEmail = match.startsWith("@") ? match.slice(1) : match;
      return users.includes(cleanEmail) ? `<at>${cleanEmail}</at>` : match;
    });
    return resultString;
  };

  const setColorToEmails = useCallback(() => {
    const quill = textareaRef.current?.getEditor();
    const text = quill.getText()?.replace(/\n/g, " ");
    if (!quill || !text) return;

    let word = "";
    let wordStartingPoint = 0;

    for (let i = 0; i <= text.length; i++) {
      const letter = text[i];

      // Check if the current character is a space or end of string
      if (letter === " " || i === text.length) {
        const currentemail = word.trim();
        const isValidEmail = users.find((user) => `@${user}` === currentemail);

        // Set the color based on validity of email
        const color = isValidEmail ? "rgb(164, 27, 30)" : "black";

        // Format the text just once per word
        if (word.length > 0) {
          quill.formatText(wordStartingPoint, i - wordStartingPoint, {
            color,
          });
        }

        // Move the starting point for the next word
        wordStartingPoint = i + 1;
        word = "";
      } else {
        // Build the current word letter by letter
        word += letter;
      }
    }
  }, [users, textareaRef]);

  const updateCommentHandler = async () => {
    try {
      setLoading(true);
      dispatch(
        updateApplicationsComment({
          commentUuid: selectedItem.uuid,
          applicationUuid,
          comment: updateCommentTags(),
        })
      )
        .unwrap()
        .then((res) => {
          setLoading(false);
          setAddCommentModal(false);
          setSelectedItem({});
          getAllComments();
          dispatch(getFilledApplications());
        })
        .catch(() => {
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  const AddCommentHandler = async () => {
    try {
      setLoading(true);
      dispatch(
        AddApplicationsComment({
          applicationUuid,
          comment: updateCommentTags(),
        })
      )
        .unwrap()
        .then(() => {
          setLoading(false);
          setAddCommentModal(false);
          setSelectedItem({});
          getAllComments();
          dispatch(getFilledApplications());
        })
        .catch(() => {
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "ArrowDown") {
        e.preventDefault();
        setCurrentIndex((prevIndex) => (prevIndex + 1) % suggestions.length);
      } else if (e.key === "ArrowUp") {
        e.preventDefault();
        setCurrentIndex((prevIndex) =>
          prevIndex === 0 ? suggestions.length - 1 : prevIndex - 1
        );
      } else if (e.key === "Enter" && showSuggestions) {
        e.preventDefault();
        selectSuggestion(currentIndex);
      }
    };

    if (showSuggestions) {
      document.addEventListener("keydown", handleKeyDown);
    } else {
      document.removeEventListener("keydown", handleKeyDown);
    }

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [showSuggestions, suggestions, currentIndex]);

  const handleChange = (content, delta, source, editor) => {
    if (!textareaRef.current) {
      setShowSuggestions(false);
      return;
    }
    const quill = textareaRef.current.getEditor();
    const position = quill.getSelection()?.index || 0;
    const cleanText = quill.getText();
    setComment(content);
    setCleanComment(cleanText);

    setCursorPosition(position);
    getDropDownPossitionToSHow(position);

    const textBeforeCursor = cleanText ? cleanText.slice(0, position) : "";
    const lastAtPosition = textBeforeCursor
      ? textBeforeCursor.lastIndexOf("@")
      : 0;
    const wordsList = textBeforeCursor?.split(" ");
    const lastWord = wordsList ? wordsList[wordsList.length - 1] : "";

    // Remove new lines coming from text
    const remainingParts = lastWord.split("\n");
    const finalResult = remainingParts[remainingParts.length - 1];
    const hasMoreTagSigns = (finalResult.match(/@/g) || []).length > 1;

    if (
      lastAtPosition !== -1 &&
      !isEmail(textBeforeCursor.slice(lastAtPosition)) &&
      !hasMoreTagSigns &&
      cleanText?.trim()?.length
    ) {
      const query = textBeforeCursor.slice(lastAtPosition + 1);
      const filteredSuggestions = users.filter((user) =>
        user.startsWith(query)
      );
      setSuggestions(filteredSuggestions);
      setShowSuggestions(filteredSuggestions.length > 0);
      setCurrentIndex(-1);
    } else {
      setShowSuggestions(false);
    }
  };

  const getDropDownPossitionToSHow = (selectionStart) => {
    if (textareaRef.current) {
      const rect = editorRef.current.getBoundingClientRect();
      const testAreaPosition = {
        top: rect.top + window.scrollY,
        left: rect.left + window.scrollX + editorRef.offsetWidth / 2,
      };
      setDropdownPosition(testAreaPosition);
    }
  };

  const selectSuggestion = (index) => {
    if (index === -1 || suggestions.length === 0) return;

    const selectedUser = suggestions[index];
    const textBeforeCursor = cleanComment.slice(0, cursorPosition);
    const lastAtPosition = textBeforeCursor.lastIndexOf("@");
    const newText =
      `${cleanComment.slice(0, lastAtPosition)}` +
      `@${selectedUser}` +
      `${cleanComment.slice(cursorPosition)}`;

    setComment(newText);
    setCleanComment(newText);
    setShowSuggestions(false);

    const newCursorPosition = lastAtPosition + selectedUser.length + 2;
    setCursorPosition(newCursorPosition);

    setTimeout(() => {
      const quill = textareaRef.current.getEditor();
      quill.setSelection(newCursorPosition);
      textareaRef.current.focus();
    }, 0);
  };

  useEffect(() => {
    if (selectedItem.comment) {
      setComment(selectedItem.comment.replace(/<at>([^<]+)<\/at>/g, "@$1"));
      setTimeout(() => {
        setShowSuggestions(false);
      }, 1000);
    }
  }, [selectedItem]);

  useEffect(() => {
    setColorToEmails();
  }, [setColorToEmails, comment]);

  return (
    <div className={styles.modalParent}>
      <div
        ref={modalRef}
        className={`shadow bg-white ${styles.modalBody} p-2 pb-5`}
      >
        <div className="w-100">
          <div className="d-flex justify-content-end">
            <div
              onClick={() => setAddCommentModal(false)}
              className="cursor-pointer"
            >
              <Icon name={ICON_NAMES.CROSSICONE} />
            </div>
          </div>
          <div className="px-5">
            <div id="showInstructionMainDIv" className="w-100">
              <h2>{selectedItem.comment ? "Update" : "Add"} Comment</h2>
              <div className={`d-flex justify-content-center my-3`}>
                <div className="w-100" ref={editorRef}>
                  <ReactQuill
                    placeholder="Write Comment"
                    ref={textareaRef}
                    value={comment}
                    onChange={handleChange}
                  />
                </div>

                {showSuggestions && (
                  <ul
                    style={{
                      position: "absolute",
                      top: dropdownPosition.top - 150,
                      left: dropdownPosition.left + 40,
                      backgroundColor: "white",
                      border: "1px solid #ccc",
                      zIndex: 1000,
                      listStyle: "none",
                      padding: "0",
                      margin: "0",
                      height: "150px",
                      overflowY: "auto",
                    }}
                  >
                    {suggestions.map((user, index) => (
                      <li
                        key={user}
                        className={`p-2 cursor-pointer ${
                          index === currentIndex
                            ? "bg-blue-500 text-white"
                            : "hover:bg-gray-200"
                        }`}
                        onClick={() => selectSuggestion(index)}
                      >
                        {user}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
            <div className="d-flex flex-column gap-2">
              <div className="d-flex mt-5 gap-2 justify-content-center">
                <button
                  style={{
                    color: themeColor,
                    borderColor: themeColor,
                    backgroundColor: "white",
                    width: "150px",
                  }}
                  type="button"
                  className="btn"
                  onClick={() => {
                    setAddCommentModal(false);
                    setSelectedItem({});
                  }}
                >
                  Close
                </button>
                <button
                  style={{
                    backgroundColor: themeColor,
                    borderColor: themeColor,
                  }}
                  type="button"
                  className="btn btn-danger"
                  onClick={() => {
                    if (selectedItem.comment) updateCommentHandler();
                    else AddCommentHandler();
                  }}
                  disabled={loading || !comment}
                >
                  {loading && (
                    <span className="spinner-border spinner-border-sm me-1"></span>
                  )}
                  {selectedItem.comment ? "Update" : "Add"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
