import React, { useCallback, useEffect, useMemo, useState } from "react";
import { debounce } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import Sidebar from "../Sidebar";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import Header from "../Header";
import { logout, setLogin } from "../../slices/auth";
import eventBus from "../../common/EventBus";
import EventBus from "../../common/EventBus";
import Footer from "../Footer";
import { Loading } from "../Loading";
import { closeAll, layout, onExpandAndClose } from "../../slices/layout";
import {
  getDataFromRefreshToken,
  getDataFromToken,
  redirectToLogin,
} from "../../utils/handleSSO";
import styles from "./index.module.css";
import { jwtDecode } from "jwt-decode";

const Layout = ({ children }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const { user: currentUser, isLoggedIn } = useSelector((state) => state.auth);
  const { sideBarHide, globalLoading, routes } = useSelector(
    (state) => state.layout
  );
  const dispatch = useDispatch();

  const [accessToken, setAccessToken] = useState(null);

  const storageAccessToken = localStorage.getItem("AUTH0_TOKEN_RESPONSE") || "";
  const user = localStorage.getItem("user");

  const getTokenFromRefreshToken = useCallback(async () => {
    const res = await getDataFromRefreshToken();
    if (res) setAccessToken(res);
    else {
      redirectToLogin();
    }
  }, []);

  useEffect(() => {
    const code = searchParams.get("code");
    if (!storageAccessToken && !code) {
      const uuid = searchParams.get("compliance_uuid");
      if (uuid) localStorage.setItem("searchedUuid", uuid);
      redirectToLogin();
    } else {
      if (storageAccessToken) {
        const decoded = JSON.parse(storageAccessToken);
        setAccessToken(decoded);
      }
    }
  }, [searchParams, storageAccessToken]);

  useEffect(() => {
    if (accessToken) {
      const decoded = jwtDecode(accessToken.access_token.toString());

      const expirationTimeInSeconds = decoded.exp || 0;
      const currentTimeInSeconds = Math.floor(Date.now() / 1000); // Current time in seconds since the epoch

      // Calculate the remaining time in seconds
      const remainingTimeInSeconds =
        expirationTimeInSeconds - currentTimeInSeconds;

      // Convert remaining time to minutes
      const remainingMinutes = Math.floor(remainingTimeInSeconds / 60);

      const refreshThreshold = remainingMinutes - 1; // run code just before 1 minute

      if (refreshThreshold || remainingMinutes > 0) {
        const runningTime = refreshThreshold
          ? refreshThreshold * 60 * 1000
          : remainingMinutes;
        setTimeout(() => {
          if (accessToken.refresh_token) {
            getTokenFromRefreshToken();
          } else {
            redirectToLogin();
          }
        }, runningTime);
      }
    }
  }, [accessToken, getTokenFromRefreshToken]);

  const handleAuthCode = useCallback(
    async (code) => {
      if (!code) return null;
      const res = await getDataFromToken(code);
      const searchdUuid = localStorage.getItem("searchedUuid");
      if (res)
        setSearchParams((params) => {
          params.delete("code");
          params.delete("state");
          if (searchdUuid) {
            params.append("compliance_uuid", searchdUuid);
            localStorage.removeItem("searchedUuid");
          }
          return params;
        });
    },
    [setSearchParams]
  );

  useEffect(() => {
    const code = searchParams.get("code");
    if (code) handleAuthCode(code);
  }, [searchParams, handleAuthCode]);

  const logOut = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  useEffect(() => {
    eventBus.on("logout", () => {
      logOut();
    });

    return () => {
      EventBus.remove("logout");
    };
  }, [currentUser, logOut]);

  const findRoute = (menu, pathName) => {
    let foundRoute = {};
    menu.forEach((route) => {
      if (route.link === pathName) {
        foundRoute = route;
      }
      if (Object.keys(foundRoute).length === 0 && route?.children?.length > 0) {
        foundRoute = findRoute(route.children, pathName);
      }
      if (Object.keys(foundRoute).length > 0) return;
    });
    return foundRoute;
  };
  const updateSidebar = (pathname) => {
    dispatch(closeAll({}));
    const route =
      routes.find((i) => i.link === pathname) ||
      routes.find((i) => i.children.find((j) => j.link === pathname));
    if (route) {
      dispatch(onExpandAndClose({ id: route.id }));
    }
  };
  const onNext = () => {
    const currentRoute = findRoute(routes, window.location.pathname);
    updateSidebar(currentRoute.nextRoute);
    navigate(currentRoute.nextRoute);
  };
  const onBack = () => {
    const pathName = window.location.pathname;
    if (pathName === "/terms") {
      navigate(-1);
      return;
    }
    const currentRoute = findRoute(routes, pathName);
    updateSidebar(currentRoute.backRoute);
    navigate(currentRoute.backRoute);
  };
  const isFirstPage = () => window.location.pathname === "/";
  const login = useCallback(() => {
    dispatch(setLogin({ user: user ? JSON.parse(user) : null }));
  }, [dispatch, user]);

  const redirectLogin = useMemo(
    () =>
      debounce(async (accessToken) => {
        if (!accessToken) {
          return <Navigate to="/login" />;
        } else {
          login();
        }
      }, 1000),
    [login]
  );
  useEffect(() => {
    redirectLogin(accessToken);
  }, [accessToken, redirectLogin]);

  useEffect(() => {
    if (window.innerWidth <= 991) dispatch(layout(false));
  }, []);
  return (
    <div className={styles.layoutContainer}>
      <div className={styles.contentContainer}>
        <Header
          currentUser={currentUser}
          logOut={logOut}
          sideBarHide={sideBarHide}
        />

        <div style={{ height: "100%" }}>
          <Sidebar sideBarHide={sideBarHide} />
          <div
            className="d-flex flex-column justify-content-between main-content"
            style={{ height: "100%" }}
          >
            {children}
          </div>
          {globalLoading && <Loading />}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Layout;
