import { differenceInDays, differenceInMonths } from "date-fns";
import * as Yup from "yup";
import { isValidEmail } from "../constants";

export function generateA1sValidationSchema(fields) {
  const schema = {};

  fields.forEach((field) => {
    const currentYear = new Date().getFullYear();
    const minDate = new Date(currentYear - 150, 0, 1); // 150 years ago from now
    const maxDate = new Date(currentYear + 150, 11, 31); // 150 years from now

    let baseSchema;
    if (
      field?.type?.startsWith("date_range_") ||
      field?.type?.startsWith("application_")
    ) {
      const parts = field.type.split("_");
      const limit = parts[parts.length - 1];
      baseSchema = Yup.date()
        .min(minDate, `Date must be after ${minDate.toDateString()}`)
        .max(maxDate, `Date must be before ${maxDate.toDateString()}`);
      if (limit === "from" && !schema[field.name]) {
        if (field.required)
          baseSchema = baseSchema.required("This field is required");
        schema[field.name] = baseSchema;
      }
      if (limit === "to" && !schema[field.name]) {
        const relativeStartingName = field.type.replace(/_to$/, "_from");
        const referenceField = fields.find(
          (_field) => _field.type === relativeStartingName
        );
        if (referenceField) {
          baseSchema = baseSchema.test("greater-to-date", function (value) {
            const from = this.resolve(Yup.ref(referenceField.name));

            if (!from || !value) return true;
            if (!(from instanceof Date) || !(value instanceof Date))
              return false;

            const startDate = new Date(from);
            const endDate = new Date(value);

            const monthsDifference = differenceInMonths(endDate, startDate);

            const daysDifference = differenceInDays(endDate, startDate);
            if (daysDifference <= 0) {
              return this.createError({
                message: "End date must be after the start date.",
              });
            } else if (monthsDifference >= 24) {
              return this.createError({
                message: "End Date must be within 24 months from start date.",
              });
            }

            return daysDifference > 0 && monthsDifference < 24;
          });
        }
        if (field.required)
          baseSchema = baseSchema.required("This field is required");
        schema[field.name] = baseSchema;
      }
    } else {
      switch (field.type) {
        case "string": {
          if (field.choices) {
            baseSchema = Yup.string().oneOf(
              field.choices,
              "This field must be selected"
            );
            if (field.required)
              baseSchema = baseSchema.required("This field is required");
            schema[field.name] = baseSchema;
          } else {
            baseSchema = Yup.string().trim("Whitespace only is not allowed");
            if (field.required)
              baseSchema = baseSchema.required("This field is required");
            if (field.max_length)
              baseSchema = baseSchema.max(
                field.max_length,
                `This field must be at most ${field.max_length} characters`
              );
            if (field.extra_validations) {
              if (field.extra_validations.regex) {
                const regexString = field.extra_validations.regex;
                const regex = new RegExp(regexString);
                baseSchema = baseSchema.matches(regex, "Invalid Data");
              }
            }
            schema[field.name] = baseSchema;
          }
          break;
        }
        case "signature": {
          baseSchema = Yup.string();
          if (field.required)
            baseSchema = baseSchema.required("signature is required");
          schema[field.name] = baseSchema;
          break;
        }
        case "date": {
          baseSchema = Yup.date()
            .min(minDate, `Date must be after ${minDate.toDateString()}`)
            .max(maxDate, `Date must be before ${maxDate.toDateString()}`);

          if (field.required)
            baseSchema = baseSchema.required("This field is required");
          schema[field.name] = baseSchema;
          break;
        }
        case "date_past":
          baseSchema = Yup.date().min(
            minDate,
            `Date must be after ${minDate.toDateString()}`
          );

          if (field.required)
            baseSchema = baseSchema.required("This field is required");
          schema[field.name] = baseSchema;
          break;
        case "date_future":
          baseSchema = Yup.date().max(
            maxDate,
            `Date must be before ${maxDate.toDateString()}`
          );

          if (field.required)
            baseSchema = baseSchema.required("This field is required");
          schema[field.name] = baseSchema;
          break;
        case "date_disabled":
          baseSchema = Yup.date();
          if (field.required)
            baseSchema = baseSchema.required("This field is required");
          schema[field.name] = baseSchema;
          break;
        case "email":
          baseSchema = Yup.string().test(
            "email",
            "Invalid email address",
            isValidEmail
          );
          if (field.required) {
            baseSchema = baseSchema.required("This field is required");
          }
          if (field.extra_validations) {
            if (field.extra_validations.regex) {
              const regexString = field.extra_validations.regex;
              const regex = new RegExp(regexString);
              baseSchema = baseSchema.matches(regex, "Invalid Data");
            }
          }
          schema[field.name] = baseSchema;
          break;
        case "user_email":
          baseSchema = Yup.string().test(
            "user_email",
            "Invalid email address",
            isValidEmail
          );
          if (field.required) {
            baseSchema = baseSchema.required("This field is required");
          }
          if (field.extra_validations) {
            if (field.extra_validations.regex) {
              const regexString = field.extra_validations.regex;
              const regex = new RegExp(regexString);
              baseSchema = baseSchema.matches(regex, "Invalid Data");
            }
          }
          schema[field.name] = baseSchema;
          break;
        case "dummy_email":
          baseSchema = Yup.string().matches(
            /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/,
            "Invalid email address"
          );
          baseSchema = baseSchema.matches(
            /@(thecozm\.com|cibt\.com|adidas\.com|adidas-group\.com|newlandchase\.com)$/,
            "This domain is not valid, please enter your work email or contact us at info@thecozm.com."
          );
          baseSchema = baseSchema.required("This field is required");
          schema[field.name] = baseSchema;
          break;
        case "integer":
          baseSchema = Yup.string().trim("Whitespace only is not allowed");

          if (field.required) {
            baseSchema = baseSchema.required("This field is required");
          }

          baseSchema = baseSchema.matches(
            /^\d+$/,
            "Please enter numbers only."
          );
          if (field.extra_validations) {
            if (field.extra_validations.regex) {
              const regexString = field.extra_validations.regex;
              const regex = new RegExp(regexString);
              baseSchema = baseSchema.matches(
                regex,
                "Please enter numbers Greater than 0"
              );
            }
          }
          schema[field.name] = baseSchema;
          break;
        case "boolean":
          baseSchema = Yup.boolean();
          baseSchema = baseSchema.oneOf(
            [true, false],
            "This field must be selected"
          );
          if (field.required) {
            baseSchema = baseSchema.required("This field is required");
          }
          schema[field.name] = baseSchema;
          break;
        case "password":
          baseSchema = Yup.string()
            .required("Password is required")
            .matches(
              /(?=.*[a-z])/,
              "Password must have at least one lowercase character"
            )
            .matches(
              /(?=.*[A-Z])/,
              "Password must have at least one uppercase character"
            )
            .matches(/(?=.*[0-9])/, "Password must have at least one number")
            .matches(
              /(?=.*[!@#$%^&*])/,
              "Password must have at least one special character (!@#$%^&*)"
            )
            .matches(/.{10,}/, "Password must be at least 10 characters long");
          schema[field.name] = baseSchema;
          break;
        case "confirm_password":
          baseSchema = Yup.string().oneOf(
            [Yup.ref("password")],
            "Passwords must match"
          );
          schema[field.name] = baseSchema;
          break;
        case "country": {
          if (field.choices) {
            baseSchema = Yup.string().oneOf(
              field?.choices,
              "This field must be selected"
            );
          } else {
            baseSchema = Yup.string();
          }
          if (field.required)
            baseSchema = baseSchema.required("This field is required");
          schema[field.name] = baseSchema;

          break;
        }
        case "nationality": {
          if (field.choices) {
            baseSchema = Yup.string().oneOf(
              field?.choices,
              "This field must be selected"
            );
          } else {
            baseSchema = Yup.string();
          }
          if (field.required)
            baseSchema = baseSchema.required("This field is required");
          schema[field.name] = baseSchema;

          break;
        }
        case "attachment": {
          baseSchema = Yup.mixed().test(
            "fileType",
            "Invalid file format",
            (value) => true || !value
          );
          if (field.required)
            baseSchema = baseSchema.required("This field is required");
          schema[field.name] = baseSchema;

          break;
        }
        case "work_percentage":
        case "percentage": {
          const regex = /^[0-9]+%?$/;
          baseSchema = Yup.string()
            .matches(regex, "Please enter a valid percentage")
            .test(
              "max",
              "Percentage must not be greater than 100%",
              (value) => typeof value === "string" && parseInt(value) <= 100
            )
            .typeError("Please enter a valid value");
          if (field.required)
            baseSchema = baseSchema.required("This field is required");

          schema[field.name] = baseSchema;

          break;
        }
        case "ratio": {
          baseSchema = Yup.number()
            .max(1, "Maximum 1")
            .min(0.01, "Minimum 1%")
            .typeError("Please enter a number");
          if (field.required)
            baseSchema = baseSchema.required("This field is required");

          schema[field.name] = baseSchema;

          break;
        }

        case "number": {
          baseSchema = Yup.string().trim("Whitespace only is not allowed");
          if (field.required)
            baseSchema = baseSchema.required("This field is required");
          if (field.max_length)
            baseSchema = baseSchema.max(
              field.max_length,
              `This field must be at most ${field.max_length} characters`
            );
          if (field.extra_validations) {
            if (field.extra_validations.regex) {
              const regexString = field.extra_validations.regex;
              const regex = new RegExp(regexString);
              baseSchema = baseSchema.matches(regex, "Invalid Data");
            }
          }
          schema[field.name] = baseSchema;
          break;
        }
        case "date_of_birth": {
          const today = new Date();
          const eighteenYearsAgo = new Date();
          eighteenYearsAgo.setFullYear(today.getFullYear() - 18);
          baseSchema = Yup.date();
          baseSchema = Yup.date()
            .min(minDate, `Date must be after ${minDate.toDateString()}`)
            .max(maxDate, `Date must be before ${maxDate.toDateString()}`)
            .test(
              "birth-date-checking",
              "Birthdate must be before 18 years from now",
              function (value) {
                if (!value) return true;
                if (!(value instanceof Date)) return false;
                return value <= eighteenYearsAgo;
              }
            );
          if (field.required)
            baseSchema = baseSchema.required("This field is required");
          schema[field.name] = baseSchema;
          break;
        }
        case "phone":
          baseSchema = Yup.string();
          if (field.required)
            baseSchema = baseSchema.required("This field is required");
          const customRegex = /^[+\d][\s\d]*$/;
          baseSchema = baseSchema.matches(customRegex, "Invalid Data");
          if (field.extra_validations) {
            if (field.extra_validations.regex) {
              const regexString = field.extra_validations.regex;
              const regex = new RegExp(regexString);
              baseSchema = baseSchema.matches(regex, "Invalid Data");
            }
          }
          schema[field.name] = baseSchema;
          break;
        default:
          baseSchema = Yup.string();
          if (field.required) {
            baseSchema = baseSchema.required("This field is required");
            schema[field.name] = baseSchema;
          } else {
            schema[field.name] = baseSchema;
          }
      }
    }
  });

  return Yup.object().shape(schema);
}
