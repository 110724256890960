import React from 'react';

const MapView = () => {
	return (
		<iframe
			title="Report Section"
			width="100%"
			height="700.5"
			src="https://app.powerbi.com/view?r=eyJrIjoiOGU5NTE4NzUtMjAyYy00NTE0LWExZWEtZTIyNTdkNzYyZGQ1IiwidCI6ImYxMjY4ZTcwLTYxZTctNDhlZS05ZjM4LWUwNTYxMDQ2YTViNiJ9"
			frameBorder="0"
			allowFullScreen={true}
		></iframe>
	);
};

export default MapView;
