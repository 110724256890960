import React, { useCallback, useEffect, useRef, useState } from "react";
import Close from "../../../assets/images/close.svg";
import { HelpText } from "../HelpText";
import { TitleText } from "../../TitleText";
import { isNil } from "lodash";

export const TextInput = ({
  type = "string",
  description = "",
  isRequired = false,
  value = "",
  error = "",
  showError = false,
  helpText = "",
  name = "",
  initializable = false,
  maxLength,
  placeHolder,
  onChange = () => {},
  updateVisible = () => {},
  prefilledNames,
  manualFilledFields,
}) => {
  const [isTouched, setIsTouched] = useState(false);
  const [displayError, setDisplayError] = useState(false);
  const [missingField, setMissingField] = useState(false);

  const inputRef = useRef(null);
  let inputType = "text";

  switch (type) {
    case "integer": {
      inputType = "number";
      break;
    }
    case "password": {
      inputType = "password";
      break;
    }
    default:
      inputType = "text";
  }

  const handleChange = useCallback(
    (event) => {
      setIsTouched(true);
      onChange(name, event.currentTarget.value);
    },
    [name, onChange]
  );
  useEffect(() => {
    if (!!error && (isTouched || showError)) setDisplayError(true);
    else setDisplayError(false);
  }, [error, isTouched, showError]);
  useEffect(() => {
    updateVisible(name, true);
    return () => {
      if (initializable) onChange(name, "");

      updateVisible(name, false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (name && !value && isRequired) {
      setMissingField(true);
    } else {
      setMissingField(false);
    }
  }, [value, isRequired, name]);

  return (
    <>
      <div
        className="w-100 h-100 d-flex flex-column gap-1 justify-content-between"
        style={{
          borderBottom: `2px solid ${
            error && displayError ? "#a51e22" : "#CECECE"
          }`,
        }}
      >
        <div className="d-flex align-items-start mb-2">
          <label htmlFor={name} className="d-flex">
            <TitleText
              style={
                (error && displayError) || missingField
                  ? { color: "#a51e22" }
                  : {}
              }
              children={`${description}${
                !isRequired && description ? " (optional)" : ""
              }`}
            />
          </label>
          {helpText && <HelpText helpText={helpText} />}
        </div>
        <div className="d-flex w-100 align-items-center align-content-center justify-content-center gap-2">
          <input
            style={{
              lineHeight: "18px",
              fontWeight: "500",
              fontSize: "15px",
            }}
            id={name}
            name={name}
            type={inputType}
            value={value}
            maxLength={maxLength}
            ref={inputRef}
            className={`text-input placeholder-style value-style ${
              prefilledNames && prefilledNames[name]
                ? "prefilled-color"
                : "not-prefilled-color"
            }`}
            placeholder={placeHolder}
            onBlur={() => setIsTouched(true)}
            onChange={(event) => handleChange(event)}
          />

          {value !== "" && !isNil(value) ? (
            <img
              className="bi-close cursor-pointer"
              src={Close}
              alt="close"
              onClick={() => onChange(name, "")}
            />
          ) : null}
        </div>
      </div>
      {error && displayError && (
        <div style={{ color: "#a51e22", fontSize: "0.8rem" }}>{error}</div>
      )}
    </>
  );
};
