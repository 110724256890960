import { utils, writeFile } from "xlsx";

export const exportToExcel = async (dataArray, fileName) => {
  try {
    if (dataArray?.length) {
      const ws = utils.json_to_sheet(dataArray);
      const wb = utils.book_new();
      utils.book_append_sheet(wb, ws, "Data");
      writeFile(wb, `${fileName}.xlsx`);
    }
  } catch (error) {
    console.log(error);
  }
};
