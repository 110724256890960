import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import filledApplicationsService from "../services/filledApplications.service";
import { setMessage } from "./message";
const initialState = {
  filledApplications: [],
  isLoading: false,
  error: null,
  nextPageUrl: null,
  previousPageUrl: null,
  count: null,
  page_size: 0,
  applicationCompletedCount: 0,
  applicationCountriesCoverdCount: 0,
  a1Count: 0,
  pwnCount: 0,
  mostCommonRoutes: [],
  selectedRoute: {},
  filters: {},
  homeCountryList: [],
  hostCountryList: [],
  isFilterCall: null,
};

export const getFilledApplications = createAsyncThunk(
  "filledApplications/getFilledApplications",
  async (url, thunkAPI) => {
    try {
      const data = await filledApplicationsService.getFilledApplications(url);
      if (!data || data?.error) return thunkAPI.rejectWithValue();
      return data.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const downloadFilledApplications = createAsyncThunk(
  "filledApplications/downloadFilledApplications",
  async (url, thunkAPI) => {
    try {
      const data = await filledApplicationsService.downloadFilledApplications(
        url
      );
      if (!data || data?.error) return thunkAPI.rejectWithValue();
      return data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getApplicationsStats = createAsyncThunk(
  "filledApplications/getApplicationsStats",
  async (query, thunkAPI) => {
    try {
      const API_URL = process.env.REACT_APP_BACK_END_URL;

      const stateUrl =
        API_URL + `compliance/compliance-requests/stats/${query || ""}`;

      const data = await filledApplicationsService.getApplicationsStats(
        stateUrl
      );
      if (!data || data?.error) return thunkAPI.rejectWithValue();

      const url = API_URL + `compliance/compliance-requests/${query || ""}`;

      const filedData = await filledApplicationsService.getFilledApplications(
        `${url}`
      );
      const result = data.data;
      result.home_countries = Array.from(
        new Set(
          result.home_countries.filter(
            (item) => item !== null && item !== undefined
          )
        )
      );
      result.host_countries = Array.from(
        new Set(
          result.host_countries.filter(
            (item) => item !== null && item !== undefined
          )
        )
      );

      if (!filedData.error) result.tableData = filedData.data;
      return result;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getApplicationsComments = createAsyncThunk(
  "filledApplications/getApplicationsComments",
  async (payload, thunkAPI) => {
    try {
      const res = await filledApplicationsService.getApplicationsSComments(
        payload
      );
      if (!res || res?.error) return thunkAPI.rejectWithValue();

      return res.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const AddApplicationsComment = createAsyncThunk(
  "filledApplications/AddApplicationsComment",
  async (payload, thunkAPI) => {
    try {
      const res = await filledApplicationsService.AddApplicationsComments(
        payload
      );
      if (!res || res?.error) return thunkAPI.rejectWithValue();
      return res.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const updateApplicationsComment = createAsyncThunk(
  "filledApplications/updateApplicationsComment",
  async (payload, thunkAPI) => {
    try {
      const res = await filledApplicationsService.updateApplicationsComments(
        payload
      );
      if (!res || res?.error) return thunkAPI.rejectWithValue();
      return res.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const deleteApplicationsComment = createAsyncThunk(
  "filledApplications/deleteApplicationsComment",
  async (payload, thunkAPI) => {
    try {
      const res = await filledApplicationsService.deleteApplicationsSComments(
        payload
      );
      if (!res || res?.error) return thunkAPI.rejectWithValue();
      return res.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

const filledApplicationsSlice = createSlice({
  name: "filledApplications",
  initialState,
  extraReducers: {
    [getFilledApplications.pending]: (state, action) => {
      state.loading = true;
    },
    [getFilledApplications.fulfilled]: (state, action) => {
      state.filledApplications = action.payload.results;
      state.nextPageUrl = action.payload.next;
      state.previousPageUrl = action.payload.previous;
      state.count = action.payload.count;
      state.page_size = action.payload.page_size;
      state.loading = false;
    },
    [getFilledApplications.rejected]: (state, action) => {
      state.loading = false;
      state.filledApplications = [];
      state.nextPageUrl = null;
      state.previousPageUrl = null;
      state.count = null;
      state.page_size = 0;
    },
    [getApplicationsStats.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getApplicationsStats.fulfilled]: (state, action) => {
      state.a1Count = action.payload.a1_count;
      state.applicationCompletedCount =
        action.payload.applications_completed_count;
      state.applicationCountriesCoverdCount =
        action.payload.countries_covered_count;
      state.pwnCount = action.payload.pwn_count;
      if (!state.selectedRoute.country) {
        state.mostCommonRoutes = action.payload.most_common_countries || [];
      }
      state.homeCountryList = action.payload.home_countries;
      state.hostCountryList = action.payload.host_countries;
      state.filledApplications = action.payload.tableData?.results;
      state.nextPageUrl = action.payload.tableData?.next;
      state.previousPageUrl = action.payload.tableData?.previous;
      state.count = action.payload.tableData?.count;
      state.page_size = action.payload.tableData.page_size;

      state.isLoading = false;
    },
    [getApplicationsStats.rejected]: (state, action) => {
      state.isLoading = false;
    },
  },
  reducers: {
    updateSelectedRoute: (state, { payload }) => {
      if (state.selectedRoute.country === payload.country) {
        state.selectedRoute = {};
      } else {
        state.selectedRoute = payload;
      }
    },
    updateFilter: (state, { payload }) => {
      if (payload.reset) {
        state.filters = {};
        state.selectedRoute = {};
        state.isFilterCall = null;
      } else {
        const key = Object.keys(payload)?.[0];
        if (!state.filters[key]) {
          state.filters[key] = payload.value;
        } else {
          if (state.filters[key] === payload.value) {
            state.filters[key] = "";
          } else {
            state.filters[key] = payload.value;
          }
        }
        if (state.isFilterCall === null)
          state.isFilterCall = Math.floor(Math.random() * 90) + 10;
        else state.isFilterCall = Math.floor(Math.random() * 90) + 10;
      }
    },
  },
});
export const { updateSelectedRoute, updateFilter } =
  filledApplicationsSlice.actions;
const { reducer } = filledApplicationsSlice;

export default reducer;
